/* eslint-disable react-hooks/exhaustive-deps */
import Button from '../../../../components/UI/Button/Button';

import Input from '../../../../components/UI/Input/Input';
import SelectInput from '../../../../components/UI/SelectInput/SelectInput';
import EditProfileLayout from './EditProfileLayout';
import { useEffect, useState } from 'react';
// import { AiOutlineLoading } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import HoroscopeImage from './HoroscopeImage';
import { updateUserHoroscopeEditProfile } from '../../../../redux/features/userSlice';
import { manglikStatusOptions, rashiOptions } from '../../../../constants';
import { FaCheckCircle } from 'react-icons/fa';

const HoroscopeEdit = (props) => {
  const dispatch = useDispatch();
  const additionalDetails = useSelector(
    (state) => state.user.additionalDetails
  );
  // console.log('HoroscopeEdit: ', additionalDetails);

  // console.log(user);

  const { dob, gender, raashi, manglic_status, time_of_birth, place_of_birth } =
    additionalDetails || {};
  // console.log('gender: ', gender);
  // console.log('raashi: ', raashi);
  // console.log('time_of_birth: ', time_of_birth);
  // console.log('manglic_status: ', manglic_status);

  const [error, setError] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const convertTo24Hour = (time) => {
    const [timeStr, period] = time.split(' ');
    let [hours, minutes] = timeStr.split(':');
    hours = parseInt(hours, 10);

    if (period === 'PM' && hours !== 12) {
      hours += 12;
    } else if (period === 'AM' && hours === 12) {
      hours = 0;
    }

    return `${hours.toString().padStart(2, '0')}:${minutes}`;
  };

  const [formData, setFormData] = useState({
    dateOfBirth: dob || dob,
    timeOfBirth: time_of_birth ? convertTo24Hour(time_of_birth) : '',
    placeOfBirth: place_of_birth || '',
    manglicStatus: manglic_status || '',
    raashi: raashi || '',
  });

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      dateOfBirth: dob || dob,
      timeOfBirth: time_of_birth ? convertTo24Hour(time_of_birth) : '',
      placeOfBirth: place_of_birth || '',
      manglicStatus: manglic_status || '',
      raashi: raashi || '',
    }));
  }, [additionalDetails]);

  useEffect(() => {
    if (dob) {
      setFormData((prevState) => ({
        ...prevState,
        dateOfBirth: dob,
      }));
    }
  }, [dob]);

  const [formErrors, setFormErrors] = useState({
    dateOfBirth: '',
    timeOfBirth: '',
    placeOfBirth: '',
    manglicStatus: '',
    raashi: '',
  });

  const [touched, setTouched] = useState({
    dateOfBirth: false,
    timeOfBirth: false,
    placeOfBirth: false,
    manglicStatus: false,
    raashi: false,
  });

  useEffect(() => {
    if (touched.dateOfBirth) {
      const selectedDate = new Date(formData.dateOfBirth);
      const today = new Date();
      let age = today.getFullYear() - selectedDate.getFullYear();
      const monthDiff = today.getMonth() - selectedDate.getMonth();
      const dayDiff = today.getDate() - selectedDate.getDate();

      // Adjust age based on month and day differences
      if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
        age--;
      }

      if (!formData.dateOfBirth) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          dateOfBirth: 'Please select date of birth!',
        }));
      } else if (
        (gender === 'Male' && age < 21) ||
        (gender === 'Female' && age < 18)
      ) {
        // Set an error if the user is younger than 18
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          dateOfBirth: `You must be at least ${
            gender === 'Male' ? '21' : '18'
          } years old!`,
        }));
      } else {
        // Clear the error if the age is valid (18+)
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          dateOfBirth: '',
        }));
      }
    }
  }, [formData.dateOfBirth, touched.dateOfBirth]);

  // useEffect(() => {
  //   if (touched.dateOfBirth) {
  //     const selectedDate = new Date(formData.dateOfBirth); // Convert the selected date to a Date object
  //     const today = new Date(); // Get the current date
  //     let age = today.getFullYear() - selectedDate.getFullYear(); // Calculate the year difference
  //     const monthDiff = today.getMonth() - selectedDate.getMonth(); // Check if birthday has passed in the current year
  //     const dayDiff = today.getDate() - selectedDate.getDate(); // Check if the day has passed

  //     // Adjust age based on month and day differences
  //     if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
  //       age--;
  //     }

  //     if (!formData.dateOfBirth) {
  //       setFormErrors((prevErrors) => ({
  //         ...prevErrors,
  //         dateOfBirth: 'Please select date of birth!',
  //       }));
  //     } else if (age < 18) {
  //       // Set an error if the user is younger than 18
  //       setFormErrors((prevErrors) => ({
  //         ...prevErrors,
  //         dateOfBirth: 'You must be at least 18 years old!',
  //       }));
  //     } else {
  //       // Clear the error if the age is valid (18+)
  //       setFormErrors((prevErrors) => ({
  //         ...prevErrors,
  //         dateOfBirth: '',
  //       }));
  //     }
  //   }
  // }, [formData.dateOfBirth, touched.dateOfBirth]);

  // useEffect(() => {
  //   if (touched.dateOfBirth) {
  //     if (!formData.dateOfBirth) {
  //       setFormErrors((prevErrors) => ({
  //         ...prevErrors,
  //         dateOfBirth: 'Please select date of birth!',
  //       }));
  //     } else {
  //       setFormErrors((prevErrors) => ({
  //         ...prevErrors,
  //         dateOfBirth: '',
  //       }));
  //     }
  //   }
  // }, [formData.dateOfBirth, touched.dateOfBirth]);

  useEffect(() => {
    if (touched.timeOfBirth) {
      if (!formData.timeOfBirth) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          timeOfBirth: 'Please select time of birth!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          timeOfBirth: '',
        }));
      }
    }
  }, [formData.timeOfBirth, touched.timeOfBirth]);

  useEffect(() => {
    if (touched.placeOfBirth) {
      if (formData.placeOfBirth && !/[^0-9]/.test(formData.placeOfBirth)) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          placeOfBirth: 'Only characters are allowed!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          placeOfBirth: '',
        }));
      }
    }
  }, [formData.placeOfBirth, touched.placeOfBirth]);

  useEffect(() => {
    if (touched.manglicStatus) {
      if (!formData.manglicStatus) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          manglicStatus: 'Please select manglic status',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          manglicStatus: '',
        }));
      }
    }
  }, [formData.manglicStatus, touched.manglicStatus]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
    setTouched((prevTouched) => ({
      ...prevTouched,
      [id]: true,
    }));
  };

  const validateInputs = () => {
    let valid = true;
    const errors = {};

    if (!formData.dateOfBirth) {
      errors.dateOfBirth = 'Date of birth is required.';
      valid = false;
    }
    if (!formData.timeOfBirth) {
      errors.timeOfBirth = 'Time of birth is required.';
      valid = false;
    }
    if (!formData.placeOfBirth) {
      errors.placeOfBirth = 'Place of birth is required.';
      valid = false;
    } else if (/\d/.test(formData.placeOfBirth)) {
      errors.placeOfBirth = 'Place of birth cannot contain numbers.';
      valid = false;
    }

    if (!formData.manglicStatus) {
      errors.manglicStatus = 'Manglic status is required.';
      valid = false;
    }

    setFormErrors(errors);

    return valid;
  };

  const formatTimeTo12Hour = (time) => {
    const [hours, minutes] = time.split(':');
    const suffix = hours >= 12 ? 'PM' : 'AM';
    const hour12 = hours % 12 || 12;
    return `${hour12}:${minutes} ${suffix}`;
  };

  const handleOnSave = async () => {
    setIsLoading(true);
    setError(null);

    if (!validateInputs()) {
      setIsLoading(false);
      return;
    }

    try {
      const formattedTime = formatTimeTo12Hour(formData.timeOfBirth);

      console.log(formattedTime);

      const formDataToSubmit = {
        dob: formData.dateOfBirth,
        time_of_birth: formattedTime,
        place_of_birth: formData.placeOfBirth,
        manglic_status: formData.manglicStatus,
        raashi: formData.raashi,
      };

      console.log(formDataToSubmit);
      // setTimeout(() => {
      //   setIsLoading(false);
      //   console.log(formData);
      // }, 1000);
      const data = await dispatch(
        updateUserHoroscopeEditProfile(formDataToSubmit)
      );
      setSaveSuccess(true);
      console.log('data: ', data);

      // Post data to API
    } catch (err) {
      setError(err.message);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <EditProfileLayout
      onShowSignUp={props.onShowSignUp}
      editPage='horoscopeedit'
    >
      <div className='flex flex-col justify-center items-center w-full h-full'>
        <div className='flex justify-start items-center w-full font-bold text-[18px] text-[#FF4061]'>
          Horoscope
        </div>

        <div className='flex flex-col md:flex-row justify-center md:justify-between items-center md:items-start w-full '>
          <div className='flex flex-col justify-between items-center w-full md:w-[49%]'>
            <Input
              id='dateOfBirth'
              type='date'
              label='Date Of Birth:'
              value={formData.dateOfBirth || ''}
              onChange={handleChange}
              className='font-semibold'
              width='w-full'
              error={formErrors.dateOfBirth}
            />
            <Input
              type='time'
              id='timeOfBirth'
              label='Time Of Birth:'
              placeholder='HH : MM'
              value={formData.timeOfBirth}
              onChange={handleChange}
              className='font-semibold'
              width='w-full'
              error={formErrors.timeOfBirth}
            />
            <Input
              id='placeOfBirth'
              type='text'
              label='Place of Birth:'
              placeholder='Enter place of birth'
              value={formData.placeOfBirth}
              onChange={handleChange}
              className='font-semibold'
              width='w-full'
              error={formErrors.placeOfBirth}
            />
            <SelectInput
              id='manglicStatus'
              options={manglikStatusOptions}
              label='Manglic Status:'
              value={formData.manglicStatus}
              onChange={handleChange}
              className='border-2 cursor-pointer'
              width='w-full'
              error={formErrors.manglicStatus}
            />

            <SelectInput
              id='raashi'
              placeholder=' Select Your Rashi'
              label='Raashi:'
              value={formData.raashi}
              options={rashiOptions}
              onChange={handleChange}
              className='border-2 cursor-pointer'
              width='w-full'
              error={formErrors.raashi}
            />

            {/* <Input
              type='text'
              onChange={handleChange}
              className='font-semibold'
            /> */}
            <div className='flex justify-start items-center my-5 w-full'>
              <Button
                onButtonClick={handleOnSave}
                text={isLoading ? 'Loading...' : 'Save'}
                className='font-bold w-[130px] h-[50px]'
                disabled={isLoading}
              >
                {isLoading && (
                  <div className='flex justify-center items-center bg-white rounded-full ml-2'>
                    <svg
                      className='animate-spin h-6 w-6 text-pink-500'
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                    >
                      <circle
                        className='opacity-25'
                        cx='12'
                        cy='12'
                        r='10'
                        stroke='currentColor'
                        strokeWidth='4'
                      ></circle>
                      <path
                        className='opacity-75'
                        fill='currentColor'
                        d='M4 12a8 8 0 018-8v8H4z'
                      ></path>
                    </svg>
                  </div>
                )}
              </Button>
              {saveSuccess && (
                <span className='flex justify-center items-center font-semibold text-[#333]'>
                  <FaCheckCircle className='mr-2 text-[20px] text-[#4CAF50] ml-2' />
                  saved
                </span>
              )}
            </div>
          </div>
          <HoroscopeImage />
        </div>
      </div>
    </EditProfileLayout>
  );
};

export default HoroscopeEdit;

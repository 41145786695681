import { Link } from 'react-router-dom';
import SignInForm from './SignInForm';

const Signin = (props) => {
  return (
    <div className='flex flex-col justify-center items-center relative py-3 md:p-4 mb-20 w-full lg:w-full'>
      <div className='flex flex-col justify-center items-center w-full py-10'>
        <div className='flex flex-col justify-center items-center w-full'>
          <h2 className='font-extrabold text-[26px] md:text-[32px] text-[#333333] text-center'>
            Sign In
          </h2>
          <p className='font-medium text-[16px] text-[#333333] mt-2'>
            Not a member?
            <Link to='/signup'>
              <button className='text-[#66B6FF] ml-2 hover:underline'>
                Register
              </button>
            </Link>
          </p>
        </div>
        <SignInForm onShowSuccesMsgText={props.onShowSuccessMessage} />
      </div>
    </div>
  );
};

export default Signin;

import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoute = () => {
  const isAuthenticated = localStorage.getItem('auth_token');

  // console.log('isAuthenticated :', isAuthenticated);

  return isAuthenticated ? <Outlet /> : <Navigate to='/' />;
};

export default PrivateRoute;

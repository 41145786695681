/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
// import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Button from '../../components/UI/Button/Button';
import Input from '../../components/UI/Input/Input';
import SelectInput from '../../components/UI/SelectInput/SelectInput';
import { signupUser } from '../../redux/features/userSlice';
import { useDispatch, useSelector } from 'react-redux';

const SignUpStep4 = ({
  onPrev,
  allPagesFormData,
  signupFormData,
  onShowSuccesMsgText,
  country,
  states,
  city,
}) => {
  const dispatch = useDispatch();

  const { status } = useSelector((state) => state.field);

  // const { executeRecaptcha } = useGoogleReCaptcha();
  // console.log('executeRecaptcha: ', executeRecaptcha);

  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [statesArray, setStatesArray] = useState([]);
  const [cityArray, setCityArray] = useState([]);

  const [selectedState, setSelectedState] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);

  const signupStatus = useSelector((state) => state.user.status);

  useEffect(() => {
    if (signupStatus === 'sign up successful') {
      // setIsLoading(false);
      onShowSuccesMsgText('Sign Up Success.');
      // Show success popup
    } else if (signupStatus === 'sign up failed') {
      // setIsLoading(false);
      // setError('Signup failed, please try again');
    }
  }, [signupStatus]);

  let countryArray = country.map((country, idx) => {
    return { name: country.name, id: country.id ? country.id : idx };
  });

  // console.log(states);

  // console.log('allPagesFormData: ', allPagesFormData);

  const [formData, setFormData] = useState({
    city: allPagesFormData?.city || '',
    state: allPagesFormData?.state || '',
    country: allPagesFormData?.country || '',
    pincode: allPagesFormData?.pincode || '',
    address: allPagesFormData?.address || '',
    agree_on_terms_condition: '',
  });

  const [formErrors, setFormErrors] = useState({
    city: '',
    state: '',
    country: '',
    pincode: '',
    address: '',
    agree_on_terms_condition: '',
  });

  const [touched, setTouched] = useState({
    city: false,
    state: false,
    country: false,
    pincode: false,
    address: false,
    agree_on_terms_condition: false,
  });

  const getSelectedState =
    states && states.length > 0
      ? states.find((stateObj) => {
          // console.log(
          //   stateObj.stcode.toLowerCase(),
          //   formData.state?.toLowerCase()
          // );

          return (
            stateObj.stcode.toLowerCase() === formData.state?.toLowerCase()
          );
        })?.name || ''
      : '';

  useEffect(() => {
    setSelectedState(getSelectedState);
    setFormData((prevData) => ({ ...prevData, city: '' }));
  }, [formData.state]);

  const statesOptions = states.filter(
    (option) => option.stcode !== formData.state?.toLowerCase()
  );

  useEffect(() => {
    setStatesArray(statesOptions);
  }, [formData.state]);

  const getSelectedCity =
    city && city.length > 0
      ? city.find((cityObj) => cityObj.id === formData.city * 1)?.name || ''
      : '';

  useEffect(() => {
    setSelectedCity(getSelectedCity);
  }, [formData.city]);

  const cityOptions = city.filter(
    (option) => option.stcode === formData.state && option.id !== formData.city
  );

  useEffect(() => {
    setCityArray(cityOptions);
  }, [formData.city]);

  // console.log(cityOptions);

  useEffect(() => {
    if (touched.address) {
      if (!formData.address) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          address: 'Please select address!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          address: '',
        }));
      }
    }
  }, [formData.address, touched.address]);

  useEffect(() => {
    if (touched.city) {
      if (!formData.city) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          city: 'Please select city!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          city: '',
        }));
      }
    }
  }, [formData.city, touched.city]);

  useEffect(() => {
    if (touched.state) {
      if (!formData.state) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          state: 'Please select state!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          state: '',
        }));
      }
    }
  }, [formData.state, touched.state]);

  useEffect(() => {
    if (touched.country) {
      if (!formData.country) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          country: 'Please select country!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          country: '',
        }));
      }
    }
  }, [formData.country, touched.country]);

  useEffect(() => {
    if (touched.pincode) {
      if (!formData.pincode) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          pincode: 'Please enter valid pincode!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          pincode: '',
        }));
      }
    }
  }, [formData.pincode, touched.pincode]);

  useEffect(() => {
    if (touched.agree_on_terms_condition) {
      if (!formData.agree_on_terms_condition) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          agree_on_terms_condition: 'Please check agree to terms!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          agree_on_terms_condition: '',
        }));
      }
    }
  }, [formData.agree_on_terms_condition, touched.agree_on_terms_condition]);

  const handleChange = (e) => {
    let { id, value } = e.target;
    // console.log('value: ', value);

    if (id === 'country') {
      if (!value) {
        setStatesArray(null);
        setCityArray(null);
        setFormData((prevFormData) => ({
          ...prevFormData,
          state: '',
          city: '',
        }));
      }
      const selectedCountry = country.find((country) => {
        // console.log('cv', country.id, value);

        return country.id === parseInt(value);
      });
      if (selectedCountry) {
        const { country_code } = selectedCountry;
        let newStatesArraya = states.filter(
          (state) => state.country_code === country_code
        );
        // console.log(newStatesArraya);
        newStatesArraya = newStatesArraya.map((state) => {
          const newState = { value: state.stcode, ...state };
          return newState;
        });

        // console.log(newStatesArraya);
        setStatesArray(newStatesArraya);
      }
    }

    if (id === 'state') {
      if (!value) {
        setCityArray(null);
        setFormData((prevFormData) => ({
          ...prevFormData,
          city: '',
        }));
        setSelectedCity('');
      }
      // console.log(states);

      const selectedState = states.find((state) => {
        // console.log(state.stcode, value);

        return state.stcode === value;
      });
      // console.log(selectedState);

      if (selectedState) {
        const { stcode } = selectedState;
        const newCityArray = city.filter((city) => city.stcode === stcode);
        setCityArray(newCityArray);
      }
    }

    if (id === 'pincode') {
      value = value.replace(/[^0-9]/g, '');
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
    setTouched((prevTouched) => ({
      ...prevTouched,
      [id]: true,
    }));
  };

  const validateInputs = () => {
    let valid = true;
    const errors = {
      address: '',
      city: '',
      state: '',
      country: '',
      pincode: '',
      agree_on_terms_condition: '',
    };

    if (formData.address === '') {
      errors.address = 'Please select address!';
      valid = false;
    }

    if (formData.city === '') {
      errors.city = 'Please select city!';
      valid = false;
    }
    if (formData.state === '') {
      errors.state = 'Please select state!';
      valid = false;
    }
    if (formData.country === '') {
      errors.country = 'Please select country!';
      valid = false;
    }
    if (formData.pincode === '') {
      errors.pincode = 'Please enter pincode!';
      valid = false;
    }
    // console.log(formData.agree_on_terms_condition);

    if (formData.agree_on_terms_condition === '') {
      errors.agree_on_terms_condition = 'Please agree to the terms to proceed.';
      valid = false;
    }

    setFormErrors(errors);
    return valid;
  };

  const handleOnSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    setError(null);

    if (!validateInputs()) {
      setIsLoading(false);

      return;
    }
    // console.log(!validateInputs());

    // if (!executeRecaptcha) {
    //   console.log('Recaptcha not yet available');
    //   return;
    // }
    // const token = await executeRecaptcha('signup');
    // console.log('token: ', token);

    // const result = await verifyRecaptchaToken(token);

    // if (result.success) {
    //   // Proceed with form submission
    //   console.log('Recaptcha verification successful');
    // } else {
    //   console.log('Recaptcha verification failed');
    // }

    try {
      formData.country = parseInt(formData.country);
      formData.city = parseInt(formData.city);
      formData.agree_on_terms_condition =
        formData.agree_on_terms_condition === 'on' ? 1 : 0;
      const updatedFormData = { ...allPagesFormData, ...formData };
      // console.log(updatedFormData);

      // console.log('status: ', status);
      const data = await dispatch(signupUser(updatedFormData));
      // console.log(data);
      if (data) {
        const errorMessage = data.payload.message;
        console.log(data.payload.message);
        setError(errorMessage);
      }
    } catch (err) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // API call to verify the token with server-side secret key
  // const verifyRecaptchaToken = async (token) => {
  //   try {
  //     const response = await fetch('/verify-recaptcha', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({ token }),
  //     });
  //     return await response.json();
  //   } catch (error) {
  //     console.error('Error verifying recaptcha', error);
  //   }
  // };

  return (
    <form
      onSubmit={handleOnSubmit}
      method='post'
      className='flex flex-col justify-start items-start w-full md:w-[80%] lg:w-[50%] mt-6 h-[400px] md:h-auto '
    >
      <div className='flex justify-between items-start w-full'>
        <Input
          id='address'
          placeholder='Enter Address'
          label='Address:'
          className='w-full'
          value={formData.address}
          onChange={handleChange}
          error={formErrors.address}
        />
      </div>
      <div className='flex justify-between items-start w-full'>
        <div className='flex justify-center items-center mr-2 w-[50%]'>
          <SelectInput
            id='country'
            name='country'
            label='Country:'
            selectType='id'
            options={countryArray}
            className='w-full'
            value={formData.country}
            onChange={handleChange}
            error={formErrors.country}
          />
        </div>
        <div className='flex justify-center items-end ml-2 w-[50%]'>
          <SelectInput
            id='state'
            name='state'
            label='State:'
            selectType='stcode'
            options={statesArray}
            className='w-full'
            select={selectedState || ''}
            value={formData.state}
            onChange={handleChange}
            error={formErrors.state}
          />
        </div>
      </div>
      <div className='flex justify-between items-start w-full'>
        <div className='flex justify-between items-end mr-2 w-[50%]'>
          <SelectInput
            id='city'
            name='city'
            label='City:'
            selectType='id'
            options={cityArray}
            className='w-full'
            value={formData.city}
            select={selectedCity || ''}
            onChange={handleChange}
            error={formErrors.city}
          />
        </div>
        <div className='flex justify-center items-center ml-2 w-[50%]'>
          <Input
            id='pincode'
            placeholder='Enter pincode'
            label='Pin-Code:'
            className='w-full'
            value={formData.pincode}
            onChange={handleChange}
            error={formErrors.pincode}
          />
        </div>
      </div>
      <div className='flex justify-between items-start w-full'>
        <div className='flex justify-between items-end mr-2 mt-3 w-full'>
          <label className='terms-label text-[14px]'>
            <input
              id='agree_on_terms_condition'
              type='checkbox'
              name='agree_on_terms_condition'
              checked={formData.agree_on_terms_condition}
              onChange={handleChange}
              className='mr-[10px] text-[#ff6b81] terms-checkbox cursor-pointer'
            />
            <span>
              I agree to the
              <span className='text-[#ff6b81] terms-link ml-1'>
                Terms & Conditions
              </span>
            </span>
          </label>
        </div>
      </div>
      <div className='flex justify-center items-start w-full pt-5'>
        {formErrors.agree_on_terms_condition && (
          <div
            className='bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4'
            role='alert'
          >
            <strong className='font-bold'>Error!</strong>
            <span className='block sm:inline ml-1'>
              {formErrors.agree_on_terms_condition}
            </span>
          </div>
        )}
      </div>

      <div className='w-full text-center text-[14px] text-[#FF4061] font-medium mt-5'>
        {error}
      </div>
      <div className='flex flex-col justify-center items-start w-full mt-5 mb-2'>
        <div className='flex justify-between items-center w-full'>
          <Button
            text='Prev'
            onButtonClick={() => onPrev(formData)}
            className='w-[48%] h-[50px] font-bold text-[24px]'
            bgBtn='bg-[#333333]'
          />
          <Button
            type='submit'
            text='SignUp'
            className='w-[48%] h-[50px] font-bold text-[24px]'
            disabled={isLoading}
          >
            {isLoading && (
              <div className='flex justify-center items-center bg-white rounded-full ml-2'>
                <svg
                  className='animate-spin h-6 w-6 text-pink-500'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                >
                  <circle
                    className='opacity-25'
                    cx='12'
                    cy='12'
                    r='10'
                    stroke='currentColor'
                    strokeWidth='4'
                  ></circle>
                  <path
                    className='opacity-75'
                    fill='currentColor'
                    d='M4 12a8 8 0 018-8v8H4z'
                  ></path>
                </svg>
              </div>
            )}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default SignUpStep4;

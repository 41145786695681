import { useSelector } from 'react-redux';
const Loading = () => {
  const isLoadingUserSlice = useSelector((state) => state.user.loading);
  const isLoadingFieldSlice = useSelector((state) => state.field.loading);

  return (
    isLoadingUserSlice ||
    (isLoadingFieldSlice && (
      <div className='fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-[50000]'>
        <div className='bg-white p-6 rounded-lg shadow-lg flex flex-col items-center space-y-4'>
          <div className='flex items-center space-x-2'>
            <svg
              className='animate-spin h-8 w-8 text-pink-500'
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
            >
              <circle
                className='opacity-25'
                cx='12'
                cy='12'
                r='10'
                stroke='currentColor'
                strokeWidth='4'
              ></circle>
              <path
                className='opacity-75'
                fill='currentColor'
                d='M4 12a8 8 0 018-8v8H4z'
              ></path>
            </svg>
            <span className='text-lg text-gray-700 font-semibold'>
              Loading...
            </span>
          </div>
          <div>
            <span className='text-lg text-gray-700 font-semibold'>
              Please Wait, We Are Preparing Something For You.
            </span>
          </div>
          {/* <p className="text-gray-500">
      Please wait while we complete your signup.
    </p> */}
        </div>
      </div>
    ))
  );
};

export default Loading;

import mobileTrubleshoot1 from './mobile-truble-shoot-image/mobiletrubleshoot-1.png';
import mobileTrubleshoot2 from './mobile-truble-shoot-image/mobiletrubleshoot-2.JPEG';

const MobileUserTroubleshootGuide = () => {
  return (
    <section className='bg-gray-50 w-full mb-20'>
      <div className='py-12 bg-[#FF8C9C]'>
        <h1 className='text-white font-bold text-2xl sm:text-3xl lg:text-4xl px-20'>
          Mobile Troubleshoot Guide
        </h1>
      </div>
      <div className='max-w-6xl mx-auto py-10 px-5 sm:px-10 lg:px-20'>
        <div className='bg-white p-6 sm:p-10 rounded-lg shadow-md w-full'>
          <img src={mobileTrubleshoot1} alt='mobile-1' className='w-full' />
        </div>
        <div className='bg-white p-6 sm:p-10 rounded-lg shadow-md w-full'>
          <img src={mobileTrubleshoot2} alt='mobile-2' className='w-full' />
        </div>
        {/* <div className='bg-white p-6 sm:p-10 rounded-lg shadow-md'>
          <h2 className='text-xl sm:text-2xl font-bold text-gray-800 mb-4'>
            Katiya Welfare Society:
          </h2>
          <p className='text-gray-700 text-base sm:text-lg leading-6 mb-6'>
            At Katiya Welfare Society, we strive to preserve and promote the
            cultural values of the Katiya community while fostering strong and
            lasting matrimonial relationships. Our website,{' '}
            <span className='font-semibold text-[#FF8C9C]'>
              KatiyaSamaj.com
            </span>
            , is dedicated to offering a secure and trustworthy platform for
            individuals within the Katiya community to connect and find
            compatible life partners.
          </p>

        </div> */}
      </div>
    </section>
  );
};

export default MobileUserTroubleshootGuide;

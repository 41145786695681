// import { FaCheckCircle } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import Button from '../../components/UI/Button/Button';
import Input from '../../components/UI/Input/Input';
import { useEffect, useState } from 'react';
import { AiOutlineLoading } from 'react-icons/ai';
import { resetPassword } from '../../redux/features/userSlice';
import { useDispatch } from 'react-redux';

const ResetPasswordForm = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState('');
  const [error, setError] = useState(null);

  // http://dev.sanroj.com:3000/reset-password/dd0bba38482b4b0d56b0b9fc4b767e94d6ae14b049010189396583490261842e?email=jatavmohit747@gmail.com
  console.log('location: ', location);

  // Extract the token from the pathname
  const getToken = location.pathname.split('/').pop();
  console.log('getToken: ', getToken);
  if (getToken) {
    setToken(getToken);
  }

  // Extract the email from the search query
  const params = new URLSearchParams(location.search);
  const getEmail = params.get('email');

  console.log('getEmail: ', getEmail);

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    password_confirmation: '',
  });

  const [formErrors, setFormErrors] = useState({
    email: '',
    password: '',
    password_confirmation: '',
  });

  const [touched, setTouched] = useState({
    email: false,
    password: '',
    password_confirmation: '',
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
    setTouched((prevTouched) => ({
      ...prevTouched,
      [id]: true,
    }));
  };

  useEffect(() => {
    if (touched.email) {
      if (
        formData.email &&
        !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)
      ) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          email: 'Please enter a valid email address.',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          email: '',
        }));
      }
    }
  }, [formData.email, touched.email]);

  useEffect(() => {
    if (touched.password) {
      if (formData.password && formData.password.length < 8) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          password: 'New password must be at least 8 characters long.',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          password: '',
        }));
      }
    }
  }, [formData.password, touched.password]);

  useEffect(() => {
    if (touched.password_confirmation) {
      if (
        formData.password_confirmation &&
        formData.password_confirmation.length < 8
      ) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          password_confirmation:
            'New password_confirmation must be at least 8 characters long.',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          password_confirmation: '',
        }));
      }
    }
  }, [formData.password_confirmation, touched.password_confirmation]);

  const validateInputs = (data) => {
    let valid = true;
    const errors = {
      email: '',
      password: '',
      password_confirmation: '',
    };
    if (data.email === '') {
      valid = false;
      errors.email = 'Please enter your email!';
    }

    if (data.password === '') {
      valid = false;
      errors.password = 'Please enter password';
    }

    if (
      data.password &&
      data.password_confirmation &&
      data.password !== data.password_confirmation
    ) {
      valid = false;
      errors.password_confirmation = 'Password mismatch!';
    }

    setFormErrors(errors);
    return valid;
  };

  const handleOnPasswordReset = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError('');

    if (!validateInputs(formData)) {
      setIsLoading(false);
      return;
    }

    try {
      console.log('formData: ', formData);

      const dummyData = {
        token:
          'a02909aacba7f2706ab8dbebf7931a633695a69ebbf3edff9787351ad636a467',
        email: 'jatavmohit747@gmail.com',
        password: '12341234',
        password_confirmation: '12341234',
      };
      // console.log('dummyData: ', dummyData);

      const data = dispatch(resetPassword(dummyData));
      if (data) {
        console.log(data);
        const status = data?.status;
        const statusMessage = data?.data?.status;
        console.log(statusMessage);

        if (status === 200) {
          statusMessage && props.onShowSuccesMsgText(statusMessage);
        }
      }

      // props.onShowSuccesMsgText('Password Updated.');
    } catch (err) {
      console.error('Error occurred: ', err);
      setError(err.message || '');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      onSubmit={handleOnPasswordReset}
      className='flex flex-col justify-center items-start w-full md:w-[60%] lg:w-[35%] shadow-xl mt-6 p-6'
    >
      <Input
        id='email'
        placeholder='Enter your email'
        label='Email address:'
        className='w-[550px]'
        value={formData.email}
        onChange={handleChange}
        error={formErrors.email}
      />

      <Input
        id='token'
        placeholder='Token'
        label='Token:'
        className='w-[550px]'
        value={token}
        onChange={handleChange}
      />

      <Input
        id='password'
        placeholder='Enter new password'
        label='New Password:'
        className='w-[550px]'
        value={formData.password}
        onChange={handleChange}
        error={formErrors.password}
      />
      <Input
        id='password_confirmation'
        placeholder='Enter confirm password'
        label='Confirm Password:'
        className='w-[550px]'
        value={formData.password_confirmation}
        onChange={handleChange}
        error={formErrors.password_confirmation}
      />
      <Link
        className='w-full flex justify-center font-medium text-[16px] text-[#333333] mt-2'
        to='/signin'
      >
        Back To
        <p className='text-[#66B6FF] ml-1 hover:underline'>SignIn</p>
      </Link>

      <div className='w-full text-center text-[14px] text-[#FF4061] font-medium mt-5'>
        {error}
      </div>

      <div className='flex flex-col justify-center items-start w-full mt-6 mb-2'>
        <Button
          text={isLoading ? 'Loading...' : 'Reset'}
          className='w-full h-[60px] font-bold text-[24px]'
          // disabled={isLoading || !otpVerified}
        >
          {isLoading && <AiOutlineLoading className='animate-spin ml-2' />}
        </Button>
      </div>
    </form>
  );
};

export default ResetPasswordForm;

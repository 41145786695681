import { useSelector } from 'react-redux';

const UserPersonalData = ({
  first_name,
  last_name,
  dob,
  sub_community,
  height_ft,
  height_inch,
  weight,
  weight_measure,
  blood_group,
  marital_status,
  mother_tongue,
  religion,
  community,
  gothra,
  dietary_preferences,
  physically_status,
  complexion,
  time_of_birth,
  place_of_birth,
  manglic_status,
  raashi,
}) => {
  const subCommunityData = useSelector((state) => state.field.subCommunity);
  const motherTongueData = useSelector((state) => state.field.religion);
  const religionData = useSelector((state) => state.field.religion);
  const communityData = useSelector((state) => state.field.community);
  const physicallyStatusData = useSelector(
    (state) => state.field.physicalStatus
  );
  const dietaryPreferencesData = useSelector(
    (state) => state.field.dietaryPreferences
  );

  const firstName =
    first_name && `${first_name.charAt(0).toUpperCase()}${first_name.slice(1)}`;
  const lastName =
    last_name && `${last_name.charAt(0).toUpperCase()}${last_name.slice(1)}`;

  const subCommunityById = subCommunityData.find(
    (item) => item.id === sub_community * 1
  )?.name;
  const motherTongueById = motherTongueData.find(
    (item) => item.id === mother_tongue * 1
  )?.name;
  const religionById = religionData.find(
    (item) => item.id === religion * 1
  )?.name;
  const communityById = communityData.find(
    (item) => item.id === community * 1
  )?.name;

  const physicallyStatusById = physicallyStatusData.find(
    (item) => item.id === physically_status * 1
  )?.status;

  // console.log(dietaryPreferencesData);

  const dietaryPreferencesById = dietaryPreferencesData.find(
    (item) => item.id === dietary_preferences * 1
  )?.name;
  // console.log(dietaryPreferencesById);

  const weightWithMeasure = `${Math.floor(weight)}${weight_measure}`;
  const personalData = [
    { label: 'First Name', value: firstName || '----' },
    { label: 'Last Name', value: lastName || '----' },
    { label: 'Date of Birth', value: dob || '----' },
    { label: 'Sub-Community', value: subCommunityById || '----' },
    {
      label: 'Height',
      value: `${height_ft || '----'}'${height_inch || '----'}"`,
    },
    { label: 'Weight', value: weightWithMeasure || '----' },
    { label: 'Marital Status', value: marital_status || '----' },
    { label: 'Blood Group', value: blood_group || '----' },
    { label: 'Color Complexion', value: complexion || '----' },
    { label: 'Physically Status', value: physicallyStatusById || '----' },
    { label: 'Mother Tongue', value: motherTongueById || '----' },
    { label: 'Religion', value: religionById || '----' },
    { label: 'Community', value: communityById || '----' },
    { label: 'Gothra', value: gothra || '----' },
    { label: 'Dietary Preferences', value: dietaryPreferencesById || '----' },
    { label: 'Time of Birth', value: time_of_birth || '----' },
    { label: 'Place of Birth', value: place_of_birth || '----' },
    { label: 'Manglic Status', value: manglic_status || '----' },
    { label: 'Raashi', value: raashi || '----' },
  ];
  return (
    <div className='flex flex-col justify-center items-center w-full md:w-[49%] lg:w-[48%] bg-white shadow-lg py-6 rounded-lg mb-6'>
      <p className='text-left w-full px-6 text-[#333333] font-bold mb-6 text-[20px]'>
        Personal Information
      </p>
      <ul className='w-full'>
        {personalData.map((item, index) => (
          <li
            key={index}
            className='flex justify-between items-start mb-1 bg-[#FFC0CB22] px-6 py-1'
          >
            <span className='font-medium text-[#555555] text-[16px]'>
              {item.label}:
            </span>
            <span className='text-[#616161] font-normal text-[16px]'>
              {item.value}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UserPersonalData;

// const UserPersonalData = () => {
//   return (
//     <div className="flex flex-col justify-center items-center w-full lg:w-[49%]">
//       <div className="flex flex-col justify-center items-center md:items-start py-10 w-full">
//         <p className="text-[#333333] font-bold mb-4 text-[20px]">
//           Personal Information
//         </p>
//         <ul className="flex flex-wrap justify-between items-start mt-2 w-full">
//           <li className="flex justify-start items-start w-full md:w-[49%] my-2">
//             <span className="font-medium text-[#555555] text-[16px] mx-2 w-[40%]">
//               Date of Birth:
//             </span>
//             <span className="text-[#616161] font-normal text-[16px] ml-2 w-[60%]">
//               11/10/2000
//             </span>
//           </li>
//           <li className="flex justify-start items-start w-full md:w-[49%] my-2">
//             <span className="font-medium text-[#555555] text-[16px] mx-2 w-[40%]">
//               Sub-Community:
//             </span>
//             <span className="text-[#818181] ml-2 w-[60%]">Reddy</span>
//           </li>
//           <li className="flex justify-start items-start w-full md:w-[49%] my-2">
//             <span className="font-medium text-[#555555] text-[16px] mx-2 w-[40%]">
//               Height:
//             </span>
//             <span className="text-[#818181] ml-2 w-[60%]">5'6"</span>
//           </li>
//           <li className="flex justify-start items-start w-full md:w-[49%] my-2">
//             <span className="font-medium text-[#555555] text-[16px] mx-2 w-[40%]">
//               Weight:
//             </span>
//             <span className="text-[#818181] ml-2 w-[60%]">56Kg</span>
//           </li>
//           <li className="flex justify-start items-start w-full md:w-[49%] my-2">
//             <span className="font-medium text-[#555555] text-[16px] mx-2 w-[40%]">
//               Marital Status:
//             </span>
//             <span className="text-[#818181] ml-2 w-[60%]">Unmerid</span>
//           </li>
//           <li className="flex justify-start items-start w-full md:w-[49%] my-2">
//             <span className="font-medium text-[#555555] text-[16px] mx-2 w-[40%]">
//               Blood Group:
//             </span>
//             <span className="text-[#818181] ml-2 w-[60%]">O+</span>
//           </li>
//           <li className="flex justify-start items-start w-full md:w-[49%] my-2">
//             <span className="font-medium text-[#555555] text-[16px] mx-2 w-[40%]">
//               Mother Tongue:
//             </span>
//             <span className="text-[#818181] ml-2 w-[60%]">Hindi</span>
//           </li>
//           <li className="flex justify-start items-start w-full md:w-[49%] my-2">
//             <span className="font-medium text-[#555555] text-[16px] mx-2 w-[40%]">
//               Religion:
//             </span>
//             <span className="text-[#818181] ml-2 w-[60%]">Hindu</span>
//           </li>
//           <li className="flex justify-start items-start w-full md:w-[49%] my-2">
//             <span className="font-medium text-[#555555] text-[16px] mx-2 w-[40%]">
//               Community:
//             </span>
//             <span className="text-[#818181] ml-2 w-[60%]">Hindu</span>
//           </li>
//           <li className="flex justify-start items-start w-full md:w-[49%] my-2">
//             <span className="font-medium text-[#555555] text-[16px] mx-2 w-[40%]">
//               Gothra:
//             </span>
//             <span className="text-[#818181] ml-2 w-[60%]">Haswani</span>
//           </li>
//         </ul>
//       </div>
//     </div>
//   );
// };
// export default UserPersonalData;

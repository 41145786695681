import { useNavigate } from 'react-router-dom';
import Button from '../Button/Button';
// import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import defaultProfileIcon from './images/defaultProfileIcon.png';
import { useState } from 'react';
// import { getUserFullDetails } from '../../../redux/features/userSlice';
import { normalizeProfileData } from '../../../utils/profileUtils';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const ProfileCard = ({ className, profileData }) => {
  // const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const cityData = useSelector((state) => state.field.city);
  const occupationData = useSelector((state) => state.field.occupation);
  const highestQualificationData = useSelector(
    (state) => state.field.highestQualification
  );

  // console.log('profileData:', profileData);

  const normalizedProfileData = normalizeProfileData(profileData);

  const {
    full_name,
    profile_image,
    age,
    occupation,
    // marital_status,
    highest_qualification,
    profile_id,
    // gender,
    city,
  } = normalizedProfileData || {};

  // console.log('profile_image: ', profile_image);

  const cityById =
    cityData.find((cityItem) => cityItem.id === city * 1)?.name || '';
  const occupationById =
    occupationData.find((occupItem) => occupItem.id === occupation * 1)?.name ||
    '';
  const highestQualificationById =
    highestQualificationData.find(
      (highQualiItem) => highQualiItem.id === highest_qualification * 1
    )?.name || '';

  const handleViewProfileClick = async (profil_iId) => {
    setIsLoading(true);
    // const data = await dispatch(getUserFullDetails(profil_iId)).unwrap();
    // console.log(data);

    // if (data) {
    // console.log(data);
    // const status = data?.status;
    // console.log(status, status === 200);
    // if (status === 200) {
    navigate(`/view-profile/?profile_id=${profil_iId}`);
    // navigate(`/view-profile/${profil_iId}`);
    // }
    // }
    setIsLoading(false);
  };

  // console.log('profile_image: ', profile_image);

  const fullImageUrl = profile_image
    ? profile_image.startsWith(BACKEND_URL)
      ? profile_image
      : `${BACKEND_URL}/${profile_image}`
    : defaultProfileIcon;

  // console.log('fullImageUrl: ', fullImageUrl);

  // const fullImageUrl = profile_image
  //   ? `${BACKEND_URL}/${profile_image}`
  //   : defaultProfileIcon;
  // console.log('fullImageUrl: ', fullImageUrl);

  return (
    <div
      className={`${
        className ? className : 'w-full md:w-[48%] lg:w-[30%]'
      } flex flex-col justify-between items-center rounded-lg bg-[#FFFFF4] my-4 md:my-10 shadow-[0_0_10px_rgba(0,0,0,0.25)]`}
    >
      <div className='flex justify-center items-center w-full h-[200px] lg:h-[160px] relative'>
        {/* <img
          src={profile_image || defaultProfileIcon}
          alt='profile-pic'
          className='rounded-t-lg w-full h-full object-cover absolute'
        /> */}
        <img
          src={fullImageUrl}
          alt='profile-pic'
          onError={(e) => {
            e.target.onerror = null; // Prevents infinite fallback loop
            e.target.src = defaultProfileIcon; // Fallback image
          }}
          className='rounded-t-lg w-full h-full object-cover absolute'
        />
      </div>
      <div className='flex flex-col justify-center items-center w-full px-2 mt-6 bg-[#FFFFFf]'>
        <div className='flex text-[#707070] font-semibold text-[18px] md:text-[20px] lg:text-[22px] mb-2'>
          {full_name}
        </div>
        <div className='flex justify-between items-center w-full'>
          <div className='flex flex-col justify-center items-start h-[50px] '>
            <p className='text-[#707070] text-[16px] md:text-[16px] lg:text-[16px] font-medium text-center my-1'>
              {cityById.slice(0, 15)}
              {cityById.length > 15 && '...'}
            </p>
            <span className='flex font- text-[#707070] ml-1 '>
              <p className='flex justify-center items-end text-[#FF4061] text-[16px]'>
                {age}
              </p>
              <span className='text-[14px] mt-2'>yrs</span>
            </span>
          </div>
          <span className='w-[1px] h-[50px] bg-[#999999]'></span>
          <div className='flex flex-col justify-center items-start '>
            <p className='text-[#707070] text-[16px] md:text-[16px] lg:text-[16px] font-medium text-center my-1'>
              {occupationById.slice(0, 15)}
              {occupationById.length > 15 && '...'}
            </p>
            <p className='text-[#707070] text-[16px] md:text-[16px] lg:text-[16px] font-medium text-center my-1'>
              {highestQualificationById.slice(0, 15)}
              {highestQualificationById.length > 15 && '...'}
            </p>
          </div>
        </div>
      </div>
      <div className='flex justify-center items-center py-4 bg-[#FFF9F0] w-full rounded-b-lg'>
        {/* <Link to={`/view-profile/${profile_id}`}> */}
        <Button
          className='font-semibold !w-[195px] !h-[35px] hover:text-[#FFFFFF] text-[18px] rounded-xl !border-2 !px-8 !border-[#FF4061]'
          bgBtn='bg-transparent'
          colorBtn='text-[#FF4061]'
          onButtonClick={() => handleViewProfileClick(profile_id)}
        >
          {isLoading ? '' : 'View Profile'}
          {isLoading && (
            <div className='flex justify-center items-center bg-white rounded-full ml-2'>
              <svg
                className='animate-spin h-6 w-6 text-pink-500'
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
              >
                <circle
                  className='opacity-25'
                  cx='12'
                  cy='12'
                  r='10'
                  stroke='currentColor'
                  strokeWidth='4'
                ></circle>
                <path
                  className='opacity-75'
                  fill='currentColor'
                  d='M4 12a8 8 0 018-8v8H4z'
                ></path>
              </svg>
            </div>
          )}
        </Button>
        {/* </Link> */}
      </div>
    </div>
  );
};
export default ProfileCard;

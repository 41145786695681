import ReactDOM from 'react-dom';
import classes from './Modal.module.css';

const Backdrop = (props) => {
  return (
    <div
      onClick={props.onHideBackdrop}
      className='fixed cursor-pointer top-0 w-[100%] min-h-[100vh] z-30 blur bg-[rgba(0,0,0,0.27)]'
    />
  );
};

// const ModalOverlay = (props) => {
//   return (
//     <div
//       className={`w-[95%] md:w-[80%] lg:w-[60%] fixed top-0 flex justify-center items-center bg-[#FFFFFF] p-[1rem] rounded-lg shadow-[0_2px_8px_rgba(0, 0, 0, 0.25)] z-30 ${classes["modal"]}`}
//     >
//       {props.children}
//     </div>
//   );
// };

const ModalOverlay = (props) => {
  return (
    <div
      className={`fixed top-0 left-0 w-[100%] h-[100%] overflow-y-auto flex justify-center items-start bg-transparent p-[1rem] z-40 ${classes.modal}`}
    >
      <div className='relative bg-white p-[1rem] rounded-lg shadow-[0_2px_8px_rgba(0, 0, 0, 0.25)]  max-w-[95%] md:max-w-[80%] lg:max-w-[80%]'>
        {props.children}
      </div>
    </div>
  );
};

const Modal = (props) => {
  const portalElement = document.getElementById('overlays');
  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onHideBackdrop={props.onHidePopup} />,
        portalElement
      )}
      {ReactDOM.createPortal(
        <ModalOverlay>{props.children}</ModalOverlay>,
        portalElement
      )}
    </>
  );
};

export default Modal;

// import ReactDOM from "react-dom";
// import classes from "./Modal.module.css";

// const Backdrop = (props) => {
//   return (
//     <div
//       onClick={props.onHideBackdrop}
//       className="fixed cursor-pointer flex flex-col justify-center items-center top-0 w-[100%] min-h-[100vh] z-30 bg-[rgba(0,0,0,0.75)]"
//     />
//   );
// };

// const ModalOverlay = (props) => {
//   return (
//     <div
//       className={`w-[95%] md:w-[80%] lg:w-[60%] fixed top-0 flex justify-center items-center bg-[#FFFFFF] p-[1rem] rounded-lg shadow-[0_2px_8px_rgba(0, 0, 0, 0.25)] z-30 ${classes["modal"]}`}
//     >
//       {props.children}
//     </div>
//   );
// };

// const Modal = (props) => {
//   const portalElement = document.getElementById("overlays");
//   return (
//     <>
//       {ReactDOM.createPortal(
//         <Backdrop onHideBackdrop={props.onHidePopup} />,
//         portalElement
//       )}
//       {ReactDOM.createPortal(
//         <ModalOverlay>{props.children}</ModalOverlay>,
//         portalElement
//       )}
//     </>
//   );
// };

// export default Modal;

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cropper from 'react-easy-crop';
import UserIcon from './UserIcon.svg';
import { MdOutlineModeEdit } from 'react-icons/md';
import { AiOutlineLoading } from 'react-icons/ai';
import { uploadImage } from '../../../../redux/features/imageSlice';
import {
  applyWatermark,
  getCompressedImage,
  getCroppedImg,
  handleCropComplete,
} from '../../../../utils/imageUtils';
import { FaCheckCircle } from 'react-icons/fa';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const PersonalProfileImageProcessing = ({ formErrors, setFormErrors }) => {
  const dispatch = useDispatch();
  const [isLoding, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isCropping, setIsCropping] = useState(false);
  const [profilePicUrl, setProfilePicUrl] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const [isUploaded, setIsUploaded] = useState(false);

  const profilePicUrlMyDetail = useSelector(
    (state) => state.image.profilePicUrlMyDetail
  );

  useEffect(() => {
    if (profilePicUrlMyDetail) {
      const fullPorfilePicUrl = BACKEND_URL + profilePicUrlMyDetail;
      setProfilePicUrl(fullPorfilePicUrl);
    }
  }, [profilePicUrlMyDetail]);

  const handleProfilePicChange = async (e) => {
    let file = e.target.files[0];
    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];

    if (file && allowedTypes.includes(file.type)) {
      const reader = new FileReader();
      reader.onload = () => setSelectedImage(reader.result);
      reader.readAsDataURL(file);
    } else {
      setProfilePicUrl('');
      setFormErrors((prevState) => ({
        ...prevState,
        profilePic:
          'Invalid file type. Please select an image file (jpeg, png, gif).',
      }));
    }
    setIsLoading(false);
  };

  const saveCroppedImage = async () => {
    if (isCropping) return;
    setIsCropping(true);
    setIsLoading(true);

    try {
      // Step 1: Crop the image
      const croppedImage = await getCroppedImg(
        selectedImage,
        crop,
        croppedAreaPixels
      );

      // Step 2: Apply watermark to the cropped image
      const watermarkedImage = await applyWatermark(croppedImage);

      // Step 3: Compress the image after adding the watermark
      const compressedImage = await getCompressedImage(watermarkedImage);
      // console.log('compressedImage: ', compressedImage.size / 1024 / 1024);

      // Step 4: Convert to Blob URL and set to state/form data
      const BLOB_URL = URL.createObjectURL(compressedImage);
      setProfilePicUrl(BLOB_URL);

      // Reset cropper after saving
      setIsUploading(true);
      const data = await dispatch(
        uploadImage({ image: compressedImage, typeId: 2 })
      ).unwrap();
      // console.log(data);

      if (data) {
        const status = data.status;
        // console.log(status, status === 201);
        if (status === 201) {
          setIsUploaded(true);
        }
      }

      setSelectedImage(null);
    } catch (error) {
      console.error('Error cropping or processing image:', error);
    } finally {
      setIsUploading(false);
      setIsCropping(false);
    }
    setIsLoading(false);
  };

  return (
    <div className='flex justify-around items-center w-full'>
      <div className='flex flex-col justify-center items-center'>
        {selectedImage && (
          <div className='flex justify-center w-[100%] translate-x-[-50%] left-[50%] top-0 bg-[rgba(0,0,0,0.57)] absolute z-50 shadow rounded-md p-4'>
            <div className='w-[90%] md:w-[50%] lg:w-[30%] md:top-0 bg-white shadow rounded-md p-2'>
              <div className='flex justify-between items-center p-2'>
                <button
                  className='flex justify-center items-center bg-red-500 lg:!text-[18px] lg:!font-bold  text-[#FFFFFF] border-2 px-4 py-1 bottom-0 rounded-lg font-bold'
                  onClick={() => {
                    setSelectedImage(null);
                  }}
                >
                  close
                </button>

                <button
                  className={`flex justify-center items-center ${
                    isUploading ? 'bg-green-500' : 'bg-black'
                  } lg:!text-[18px] lg:!font-semibold  text-[#FFFFFF] border-2 px-4 py-1 bottom-0 rounded-lg font-bold`}
                  onClick={saveCroppedImage}
                >
                  {isUploading ? 'saving..' : 'crop'}
                  {isLoding && (
                    <AiOutlineLoading className='animate-spin ml-2' />
                  )}
                </button>
              </div>
              <div className='relative'>
                <div
                  className={`flex flex-col justify-center items-center ative py-5 p-4 w-[250px] md:w-[500px] lg:w-[700px] h-[250px] md:h-[400px]`}
                >
                  <div className='cropper-container'>
                    <Cropper
                      image={selectedImage}
                      crop={crop}
                      zoom={zoom}
                      aspect={1}
                      onCropChange={setCrop}
                      onZoomChange={setZoom}
                      onCropComplete={(croppedArea, croppedAreaPixels) =>
                        handleCropComplete(
                          croppedAreaPixels,
                          setCroppedAreaPixels
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className='flex justify-center items-center'>
          <label htmlFor='profilePic'>
            <span
              className={`flex justify-center items-center outline-dashed outline-2 ${
                formErrors.profilePic
                  ? 'outline-[#FF4061]'
                  : 'outline-[#9CA3AF]'
              } rounded-full w-[100px] h-[100px] cursor-pointer relative bg-[#F4F4F4]`}
            >
              <div className='flex justify-center items-center w-full h-full rounded-full overflow-hidden relative'>
                {profilePicUrl && (
                  <img
                    src={profilePicUrl || UserIcon}
                    alt='SelectedProfilePic'
                    className='absolute h-full max-w-none inset-0 object-cover'
                  />
                )}
                {/* {console.log('profilePicUrl: ', profilePicUrl)} */}
              </div>
              <MdOutlineModeEdit className='text-[#FFFFFF] rounded-full w-[24px] h-[24px] p-1 bg-[#FF4061] absolute bottom-1 right-1' />
            </span>
          </label>
          <input
            id='profilePic'
            type='file'
            className='hidden'
            accept='image/*'
            onChange={handleProfilePicChange}
          />
          {isUploaded && (
            <span className='flex justify-center items-center font-semibold text-[#333]'>
              <FaCheckCircle className='mr-2 text-[20px] text-[#4CAF50] ml-2' />
              Uploaded
            </span>
          )}
        </div>
        {/* {fileName && (
          <div className='text-[#9CA3AF] px-2 text-sm mt-1 border-2'>
            {fileName}
          </div>
        )} */}
      </div>
    </div>
  );
};

export default PersonalProfileImageProcessing;

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import Cropper from 'react-easy-crop';
import { MdError, MdOutlineFileUpload } from 'react-icons/md';
import {
  removeImage,
  uploadImage,
} from '../../../../redux/features/imageSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import {
  applyWatermark,
  getCompressedImage,
  getCroppedImg,
  handleCropComplete,
} from '../../../../utils/imageUtils';
import { AiOutlineLoading } from 'react-icons/ai';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const HoroscopeImage = () => {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  const images = useSelector((state) => state.image.horoscopeImages);
  let img = images && images[0] && images[0].url;
  const fullImageUrl = img ? BACKEND_URL + img : '';
  // console.log('profilePhotoImages', images);

  useEffect(() => {
    if (fullImageUrl) {
      setHoroscopePicUrl(fullImageUrl);
    }
  }, [fullImageUrl]);

  const dispatch = useDispatch();
  const [isLoding, setIsLoading] = useState(false);
  const [isRemoveLoading, setIsRemoveLoading] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [isCropping, setIsCropping] = useState(false);

  const [isTwoImages, setIsTwoImages] = useState(false);
  const [horoScopeImages, setHoroScopeImages] = useState([]);
  const horoScopeImagesFromSlice = useSelector(
    (state) => state.image.horoscopeImages
  );

  const removeAlbumImageHandler = async (image_id) => {
    setIsRemoveLoading((prevState) => ({ ...prevState, [image_id]: true }));
    // console.log('removeAlbumImageHandler', image_id);
    await dispatch(removeImage({ image_id }));
    setIsRemoveLoading((prevState) => ({ ...prevState, [image_id]: false }));
  };

  useEffect(() => {
    // console.log('horoScopeImagesFromSlice: ', horoScopeImagesFromSlice);
    const lastTwoImage = horoScopeImagesFromSlice?.slice(-2);
    setHoroScopeImages(lastTwoImage);
    if (horoScopeImages?.length === 2) {
      setIsTwoImages(true);
      // console.log('isTwoImages:', isTwoImages);
    }
  }, [horoScopeImagesFromSlice]);

  // console.log('horoScopeImages: ', horoScopeImages);

  // console.log(horoScopeImages.length);

  // const [fileName, setFileName] = useState('');
  const [horoscopePicUrl, setHoroscopePicUrl] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const handleProfilePicChange = async (e) => {
    if (isCropping) return;
    if (isTwoImages) {
      return;
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });

    let file = e.target.files[0];
    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];

    if (file && allowedTypes.includes(file.type)) {
      const reader = new FileReader();
      reader.onload = () => setSelectedImage(reader.result);
      reader.readAsDataURL(file);
    } else {
      // setFileName('');
      setHoroscopePicUrl('');
    }
    setIsLoading(false);
  };

  const saveCroppedImage = async () => {
    setIsLoading(true);

    try {
      // Step 1: Crop the image
      const croppedImage = await getCroppedImg(
        selectedImage,
        crop,
        croppedAreaPixels
      );

      const watermarkedImage = await applyWatermark(croppedImage);

      const compressedImage = await getCompressedImage(watermarkedImage);

      const BLOB_URL = URL.createObjectURL(compressedImage);
      setHoroscopePicUrl(BLOB_URL);

      setIsUploading(true);
      const image = await dispatch(
        uploadImage({ image: compressedImage, typeId: 1 })
      );

      console.log(image?.payload);

      setSelectedImage(null);
      setHoroscopePicUrl(null);
    } catch (error) {
      console.error('Error cropping or processing image:', error);
    } finally {
      setIsUploading(false);
      setIsCropping(false);
    }
    setIsLoading(false);
  };

  return (
    <>
      <div className='flex flex-col justify-center items-center w-full h-full md:w-[49%]'>
        {selectedImage && (
          <div className='flex justify-center w-[100%] translate-x-[-50%] left-[50%] top-0 bg-[rgba(0,0,0,0.57)] absolute z-50 shadow rounded-md p-4'>
            <div className='w-[90%] md:w-[50%] lg:w-[30%] md:top-0 bg-white shadow rounded-md p-2'>
              <div className='flex justify-between items-center p-2'>
                <button
                  className='flex justify-center items-center bg-red-500 lg:!text-[18px] lg:!font-bold  text-[#FFFFFF] border-2 px-4 py-1 bottom-0 rounded-lg font-bold'
                  onClick={() => {
                    setSelectedImage(null);
                  }}
                >
                  close
                </button>
                <button
                  className={`flex justify-center items-center ${
                    isUploading ? 'bg-green-500' : 'bg-black'
                  } lg:!text-[18px] lg:!font-semibold  text-[#FFFFFF] border-2 px-4 py-1 bottom-0 rounded-lg font-bold`}
                  onClick={saveCroppedImage}
                >
                  {isUploading ? 'saving..' : 'crop'}
                  {isLoding && (
                    <AiOutlineLoading className='animate-spin ml-2' />
                  )}
                </button>
              </div>
              <div className='relative'>
                <div
                  className={`flex flex-col justify-center items-center ative py-5 p-4 w-[250px] md:w-[500px] lg:w-[700px] h-[250px] md:h-[400px]`}
                >
                  <div className='cropper-containe'>
                    <Cropper
                      image={selectedImage}
                      crop={crop}
                      zoom={zoom}
                      aspect={1}
                      onCropChange={setCrop}
                      onZoomChange={setZoom}
                      onCropComplete={(croppedArea, croppedAreaPixels) =>
                        handleCropComplete(
                          croppedAreaPixels,
                          setCroppedAreaPixels
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {horoScopeImages && horoScopeImages.length <= 1 && (
          <div className='flex gap-3 flex-col justify-center items-center w-[90%] md:w-[80%] lg:w-[70%]'>
            <p className='flex justify-start items-center border-2 border-orange-400 bg-orange-100 text-orange-400 text-[14px] p-2 w-full '>
              <MdError className='mr-2 text-[20px] text-orange-400' />
              Only two images allowed!
            </p>
            <label
              htmlFor='horoscopeimage'
              className='flex justify-center items-center text-slate-500 font-bold w-[100%] px-4 py-2 lg:py-4 mb-2 outline-2 outline-slate-300 hover:outline-[#FF406155] outline-dashed rounded-md cursor-pointer bg-slate-50 group'
            >
              <span>Select Horoscope:</span>
              <MdOutlineFileUpload className='text-[25px] ml-2 group-hover:ml-4 duration-100 ' />
            </label>
            <input
              id='horoscopeimage'
              type='file'
              className='hidden'
              accept='image/*'
              onChange={handleProfilePicChange}
            />
          </div>
        )}
        {horoScopeImages &&
          horoScopeImages.map((image, idx) => {
            // console.log(image);

            const imageUrl = `${BACKEND_URL}${image?.url}`;
            return (
              <div
                key={idx}
                className='flex gap-3 flex-col justify-center items-start w-[90%] md:w-[70%] lg:w-[70%]'
              >
                <div className='flex justify-between items-center w-full text-[#333333] mt-2'>
                  <span className='flex justify-center items-center font-semibold text-[#333]'>
                    {imageUrl && (
                      <FaCheckCircle className='mr-2 text-[20px] text-[#4CAF50] ml-2' />
                    )}
                    uploaded
                  </span>
                  <button
                    className='flex justify-center items-center bg-red-500 lg:!text-[18px] lg:!font-blod  text-[#FFFFFF] border-2 px-3 py-1 bottom-0 rounded-lg font-bold'
                    onClick={() => {
                      removeAlbumImageHandler(image.id);
                    }}
                  >
                    {isRemoveLoading ? '' : 'remove'}
                    {isRemoveLoading[image.id] ? (
                      <div className='flex justify-center items-center bg-white rounded-full'>
                        <svg
                          className='animate-spin h-6 w-6 text-pink-500'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 24 24'
                        >
                          <circle
                            className='opacity-25'
                            cx='12'
                            cy='12'
                            r='10'
                            stroke='currentColor'
                            strokeWidth='4'
                          ></circle>
                          <path
                            className='opacity-75'
                            fill='currentColor'
                            d='M4 12a8 8 0 018-8v8H4z'
                          ></path>
                        </svg>
                      </div>
                    ) : (
                      'remove'
                    )}
                  </button>
                </div>
                {/* {fileError && (
          <p className='flex justify-start items-center border-2 border-[#FF4061aa] bg-[#FF406122] text-[#FF4061] text-[14px] p-2 w-full'>
            <MdError className='mr-2 text-[20px]' /> File upload failed!
          </p>
        )} */}

                <div className='flex flex-col justify-center items-center w-full p-3 outline-2 outline-slate-300 outline-dashed rounded-md cursor-pointer bg-slate-50'>
                  <img
                    src={imageUrl || ''}
                    alt='horoscopeimage'
                    className='max-w-full'
                  />
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default HoroscopeImage;

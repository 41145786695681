import React from 'react';
import { useSelector } from 'react-redux';

const CancellationPolicy = () => {
  let companyInfoArray = useSelector((state) => state.companyMaster.info);
  const companyInfo = companyInfoArray?.length > 0 ? companyInfoArray[0] : null;
  const { email } = companyInfo || {};

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <section className='bg-gray-50 w-full mb-20'>
      {/* Header */}
      <div className='py-12 bg-[#FF8C9C]'>
        <h1 className='text-white font-bold text-2xl sm:text-3xl lg:text-4xl px-20'>
          Cancellation Policy
        </h1>
      </div>

      {/* Content Section */}
      <div className='max-w-6xl mx-auto py-10 px-5 sm:px-10 lg:px-20'>
        <div className='bg-white p-6 sm:p-10 rounded-lg shadow-md'>
          {/* Introduction Section */}
          <h2 className='text-xl sm:text-2xl font-bold text-gray-800 mb-4'>
            Cancellation Policy:
          </h2>
          <p className='text-gray-700 text-base sm:text-lg leading-6 mb-6'>
            We at <span className='font-bold'>Katiya Samaj</span> are committed
            to providing high-quality matrimonial services to the Katiya
            community. We understand that sometimes, circumstances may require
            the cancellation of services. Please read our cancellation policy
            carefully.
          </p>

          {/* Cancellation Terms Section */}
          <h3 className='text-lg sm:text-xl font-semibold text-gray-800 my-4'>
            Cancellation Terms:
          </h3>
          <ul className='list-disc pl-5 text-gray-700 text-base sm:text-lg leading-7 space-y-4'>
            <li>
              <p>
                <strong>Services:</strong> As our services are provided on a
                non-refundable basis, cancellation requests can only be accepted
                under specific conditions.
              </p>
            </li>
            <li>
              <p>
                <strong>Request for Cancellation:</strong> If you wish to cancel
                your account or any service, please contact us within 48 hours
                of registration via the contact form or email at{' '}
                <a
                  href={`mailto:${email}`}
                  className='text-[#FF4061] hover:underline'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {email}
                </a>
              </p>
            </li>
            <li>
              <p>
                <strong>Processing of Cancellation:</strong> Once your
                cancellation request is processed, we will notify you via email
                or phone.
              </p>
            </li>
            <li>
              <p>
                <strong>Non-Refundable Services:</strong> There will be no
                refund on any subscription or service fees once processed and
                activated.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default CancellationPolicy;

// const CancellationPolicy = () => {
//   window.scrollTo({ top: 0, behavior: 'smooth' });
//   return (
//     <section className=''>
//       <div className='pb-20'>
//         {/* <div className='mt-20 pb-20'> */}
//         <div className='py-14 bg-[#FF8C9C]'>
//           <p className='font-bold px-20 text-white text-[24px]'>
//             Cancellation Policy
//           </p>
//         </div>
//         <div className='flex flex-col justify-start items-center py-10 px-20'>
//           <div className='flex flex-col gap-5 text-[18px]'>
//             <p className='font-medium tracking-wider flex flex-col gap-3'>
//               You may return most new, unopened items within 30 days of delivery
//               for a full refund. We'll also pay the return shipping costs if the
//               return is a result of our error you received an incorrect or
//               defective item, etc.. <p />
//               You should expect to receive your refund within four weeks of
//               giving your package to the return shipper, however, in many cases
//               you will receive a refund more quickly. This time period includes
//               the transit time for us to receive your return from the shipper (5
//               to 10 business days), the time it takes us to process your return
//               once we receive it (3 to 5 business days), and the time it takes
//               your bank to process our refund request (5 to 10 business days).{' '}
//               <p />
//               If you need to return an item, simply login to your account, view
//               the order using the 'Complete Orders' link under the My Account
//               menu and click the Return Item(s) button. We'll notify you via
//               e-mail of your refund once we've received and processed the
//               returned item.
//             </p>
//           </div>
//         </div>
//         <div className='flex flex-col justify-start items-center py-10 px-20'>
//           <div className='flex flex-col gap-5 text-[18px]'>
//             <p className='font-medium tracking-wider flex flex-col gap-3'>
//               You may return most new, unopened items within 30 days of delivery
//               for a full refund. We'll also pay the return shipping costs if the
//               return is a result of our error you received an incorrect or
//               defective item, etc.. <p />
//               You should expect to receive your refund within four weeks of
//               giving your package to the return shipper, however, in many cases
//               you will receive a refund more quickly. This time period includes
//               the transit time for us to receive your return from the shipper (5
//               to 10 business days), the time it takes us to process your return
//               once we receive it (3 to 5 business days), and the time it takes
//               your bank to process our refund request (5 to 10 business days).{' '}
//               <p />
//               If you need to return an item, simply login to your account, view
//               the order using the 'Complete Orders' link under the My Account
//               menu and click the Return Item(s) button. We'll notify you via
//               e-mail of your refund once we've received and processed the
//               returned item.
//             </p>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default CancellationPolicy;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const fetchCompanyInfo = createAsyncThunk(
  'companyMaster/fetchCompanyInfo',
  async () => {
    const response = await axios.get(`${BACKEND_URL}/api/util/company-master`);
    return response.data.company_master;
  }
);

const companyMasterSlice = createSlice({
  name: 'companyMaster',
  initialState: {
    info: {},
    status: 'idle',
    error: null,
    isLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanyInfo.pending, (state) => {
        state.isLoading = true;
        state.status = 'loading';
      })
      .addCase(fetchCompanyInfo.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.info = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchCompanyInfo.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.isLoading = false;
      });
  },
});

export default companyMasterSlice.reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

// Async thunk to fetch profile data
export const fetchProfileData = createAsyncThunk(
  'profile/fetchProfileData',
  async (profileId, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BACKEND_URL}/api/profile/${profileId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk to update profile data
export const updateProfileData = createAsyncThunk(
  'profile/updateProfileData',
  async ({ profileId, formData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${BACKEND_URL}/api/profile/${profileId}`,
        formData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk to update personal profile data
export const updatePersonalProfileData = createAsyncThunk(
  'profile/updatePersonalProfileData',
  async ({ profileId, formData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${BACKEND_URL}/`, formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk to update family profile data
export const updateFamilyProfileData = createAsyncThunk(
  'profile/updateFamilyProfileData',
  async ({ profileId, formData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${BACKEND_URL}/`, formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk to update education profile data
export const updateEducationProfileData = createAsyncThunk(
  'profile/updateEducationProfileData',
  async ({ profileId, formData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${BACKEND_URL}/`, formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk to update address profile data
export const updateAddressProfileData = createAsyncThunk(
  'profile/updateAddressProfileData',
  async ({ profileId, formData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${BACKEND_URL}/`, formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk to update social profile data
export const updateSocialProfileData = createAsyncThunk(
  'profile/updateSocialProfileData',
  async ({ profileId, formData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${BACKEND_URL}/`, formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk to update horoscope profile data
export const updateHoroscopeProfileData = createAsyncThunk(
  'profile/updateHoroscopeProfileData',
  async ({ profileId, formData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${BACKEND_URL}/`, formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk to update about more profile data
export const updateAboutMoreProfileData = createAsyncThunk(
  'profile/updateAboutMoreProfileData',
  async ({ profileId, formData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${BACKEND_URL}/`, formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk to update match preferences profile data
export const updateMatchPreferencesProfileData = createAsyncThunk(
  'profile/updateMatchPreferencesProfileData',
  async ({ profileId, formData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${BACKEND_URL}/`, formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDefaultSearchFormFields = createAsyncThunk(
  'user/getDefaultSearchFormFields',
  async (_, { dispatch }) => {
    try {
      const token = localStorage.getItem('auth_token');
      // console.log('token: ', token);
      if (token && token !== 'undefined') {
        const response = await axios.get(BACKEND_URL + '/api/search/form', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        });
        // console.log('formFields:', response);
        const formFields = response?.data;
        return formFields;
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
      throw error;
    }
  }
);

export const getFindMatchProfiles = createAsyncThunk(
  'user/getFindMatchProfiles',
  async (data, { dispatch }) => {
    // console.log(data);

    try {
      const token = localStorage.getItem('auth_token');
      // console.log('token: ', token);
      if (token && token !== 'undefined') {
        const response = await axios.post(
          BACKEND_URL + '/api/profile/search-users',
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        );
        // console.log('profilesData:', response?.data);
        const profilesData = response?.data;
        return profilesData;
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
      throw error;
    }
  }
);

export const fetchCotactDetailsByProfileId = createAsyncThunk(
  'user/fetchCotactDetailsByProfileId',
  async (profile_id, { rejectWithValue }) => {
    // console.log('profile_id: ', profile_id);

    try {
      const token = localStorage.getItem('auth_token');
      // console.log('token: ', token);
      if (token && token !== 'undefined') {
        const response = await axios.get(
          // {{baseUrl}}/api/user/contact/profile/{profile_id}
          BACKEND_URL + `/api/user/contact/profile/${profile_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        );
        // console.log('contactDetails By Profile id: ', response);
        const status = response.status;
        // console.log(status);

        if (status === 200) {
          return response.data;
        }

        // const fetchedContactData = response.data;
        // return fetchedContactData;
      }
    } catch (error) {
      // const status = error.response?.status;
      // console.log(status);

      // console.error(':', error);
      throw error;
    }
  }
);

const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    profileData: null,
    findMatchProfilesData: [],
    defaultSearchFormFields: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Handle fetch profile data
    builder
      .addCase(fetchProfileData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProfileData.fulfilled, (state, action) => {
        state.loading = false;
        state.profileData = action.payload;
      })
      .addCase(fetchProfileData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    // Handle update profile data
    builder
      .addCase(updateProfileData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateProfileData.fulfilled, (state, action) => {
        state.loading = false;
        state.profileData = action.payload;
      })
      .addCase(updateProfileData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    // Handle DefaultSearchFormFields
    builder
      .addCase(getDefaultSearchFormFields.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDefaultSearchFormFields.fulfilled, (state, action) => {
        state.loading = false;
        // console.log(action.payload);
        state.defaultSearchFormFields = action.payload;
      })
      .addCase(getDefaultSearchFormFields.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    // Handle FindMatchProfilesData
    builder
      .addCase(getFindMatchProfiles.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getFindMatchProfiles.fulfilled, (state, action) => {
        state.loading = false;
        state.findMatchProfilesData = action.payload?.users;
      })
      .addCase(getFindMatchProfiles.rejected, (state, action) => {
        state.loading = false;
        state.error = null;
      });
  },
});

export default profileSlice.reducer;

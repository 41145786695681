import ForgotPasswordForm from './ForgotPasswordForm';

const ForgotPassword = (props) => {
  // console.log(props.onShowSuccessMessage);
  // props.onShowSuccessMessage('mohit');

  return (
    <div className='flex flex-col justify-center items-center relative py-3 md:p-4 mb-20 w-full lg:min-w-full'>
      <div className='flex flex-col justify-center items-center w-full py-10'>
        <div className='flex flex-col justify-center items-center w-full'>
          <h2 className='font-extrabold text-[26px] md:text-[32px] text-[#333333] text-center'>
            Forgot Password
          </h2>
        </div>
        <ForgotPasswordForm onShowSuccesMsgText={props.onShowSuccessMessage} />
      </div>
    </div>
  );
};

export default ForgotPassword;

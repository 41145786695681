/* eslint-disable no-unused-vars */
import { useDispatch } from 'react-redux';
import { useCallback, useState } from 'react';
import Cropper from 'react-easy-crop';
import Button from '../../../../components/UI/Button/Button';
import { AiOutlineLoading } from 'react-icons/ai';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { uploadImage } from '../../../../redux/features/imageSlice';
import {
  applyWatermark,
  getCompressedImage,
  getCroppedImg,
  handleCropComplete,
} from '../../../../utils/imageUtils';
import { debounce } from 'lodash';

const PhotoAlbumCard = ({
  imageUrl,
  changeAlbumImageHandler,
  removeAlbumImageHandler,
  imageId,
  handleImageClick,
  isRemoveLoading,
}) => {
  const dispatch = useDispatch();
  const [isLoding, setIsLoading] = useState(false);
  // const [isRemoveLoading, setIsRemoveLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  // const [fileName, setFileName] = useState('');
  const [profilePicUrl, setProfilePicUrl] = useState(imageUrl || '');
  const [selectedImage, setSelectedImage] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [isCropping, setIsCropping] = useState(false);

  // const removeImage = () => {
  // setIsRemoveLoading(true);
  // removeAlbumImageHandler();
  // setIsRemoveLoading(false);
  // };

  const handleProfilePicChange = async (e) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    let file = e.target.files[0];
    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];

    if (file && allowedTypes.includes(file.type)) {
      const reader = new FileReader();
      reader.onload = () => setSelectedImage(reader.result);
      reader.readAsDataURL(file);
    } else {
      // setFileName('');
      setProfilePicUrl('');
    }
    setIsLoading(false);
  };

  const saveCroppedImage = async () => {
    if (isCropping) return;

    setIsCropping(true);
    setIsLoading(true);

    try {
      // Step 1: Crop the image
      console.log('crop');
      const croppedImage = await getCroppedImg(
        selectedImage,
        crop,
        croppedAreaPixels
      );

      // Step 2: Apply watermark to the cropped image
      const watermarkedImage = await applyWatermark(croppedImage);

      // Step 3: Compress the image after adding the watermark
      const compressedImage = await getCompressedImage(watermarkedImage);
      // console.log(compressedImage.size / 1024 / 1024);

      // Step 4: Convert to Blob URL and set to state/form data
      const BLOB_URL = URL.createObjectURL(compressedImage);
      setProfilePicUrl(BLOB_URL);

      console.log(typeof BLOB_URL, 'dispach');

      // Reset cropper after saving

      setIsUploading(false);
      await dispatch(uploadImage({ image: compressedImage, typeId: 3 }));
      setSelectedImage(null);
      setProfilePicUrl(null);
    } catch (error) {
      console.error('Error cropping or processing image:', error);
    } finally {
      setIsCropping(false);
      setIsUploading(false);
    }
    setIsLoading(false);
  };
  return (
    <li className='flex flex-col justify-center items-center rounded-lg w-[90%] md:w-[40%] lg:w-[23%] aspect-square self-start my-6'>
      {selectedImage && (
        <div className='flex justify-center w-[100%] translate-x-[-50%] left-[50%] top-0 bg-[rgba(0,0,0,0.57)] absolute z-50 shadow rounded-md p-4'>
          <div className='w-[90%] md:w-[50%] lg:w-[30%] md:top-0 bg-white shadow rounded-md p-2'>
            <div className='flex justify-between items-center p-2'>
              <button
                className='flex justify-center items-center bg-red-500 lg:!text-[18px] lg:!font-bold  text-[#FFFFFF] border-2 px-4 py-1 bottom-0 rounded-lg font-bold'
                onClick={() => {
                  setSelectedImage(null);
                }}
              >
                close
              </button>
              <button
                className='flex justify-center items-center bg-green-500 lg:!text-[18px] lg:!font-bold  text-[#FFFFFF] border-2 px-4 py-1 bottom-0 rounded-lg font-bold'
                onClick={saveCroppedImage}
              >
                crop
                {isLoding && <AiOutlineLoading className='animate-spin ml-2' />}
              </button>
            </div>
            <div className='relative'>
              <div
                className={`flex flex-col justify-center items-center ative py-5 p-4 w-[250px] md:w-[500px] lg:w-[700px] h-[250px] md:h-[400px]`}
              >
                <div className='cropper-containe'>
                  <Cropper
                    image={selectedImage}
                    crop={crop}
                    zoom={zoom}
                    aspect={1}
                    onCropChange={setCrop}
                    onZoomChange={setZoom}
                    onCropComplete={(croppedArea, croppedAreaPixels) =>
                      handleCropComplete(
                        croppedAreaPixels,
                        setCroppedAreaPixels
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className={`relative flex justify-center items-center overflow-hidden rounded-t-xl border-2 border-dashed w-full h-full mb-[-1px] ${
          profilePicUrl
            ? 'border-b-transparent'
            : 'bg-[#F7F7F7] border-2 border-dashed border-[#CCCCCC]'
        }`}
        // className={`relative flex justify-center items-center w-full lg:min-w-full min-h-[250px] lg:max-w-full max-h-[250px] overflow-hidden rounded-t-xl border-2 border-dashed ${
        //   profilePicUrl
        //     ? 'border-b-transparent'
        //     : 'bg-[#F7F7F7] border-2 border-dashed border-[#CCCCCC]'
        // }`}
      >
        {/* pre cocde1 */}
        {profilePicUrl && (
          <img
            src={profilePicUrl}
            alt='ablum'
            className='rounded-t-xl inset-0 top-0 object-cover cursor-pointer w-full h-full'
            onClick={handleImageClick}
          />
        )}
      </div>
      {profilePicUrl && (
        <div className='flex justify-center items-center w-full mt-[-1px]'>
          {/* <Button
            onButtonClick={changeAlbumImageHandler}
            text='Change'
            className='!bg-[#333333] font-bold w-[45%] rounded-bl-xl rounded-br-3xl rounded-t-none'
          ></Button> */}
          <Button
            onButtonClick={removeAlbumImageHandler}
            text={isRemoveLoading ? '' : 'Remove'}
            className='font-bold w-[99%] rounded-bl-3xl rounded-br-3xl rounded-t-none'
          >
            {isRemoveLoading[imageId] ? (
              <div className='flex justify-center items-center bg-white rounded-full'>
                <svg
                  className='animate-spin h-6 w-6 text-pink-500'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                >
                  <circle
                    className='opacity-25'
                    cx='12'
                    cy='12'
                    r='10'
                    stroke='currentColor'
                    strokeWidth='4'
                  ></circle>
                  <path
                    className='opacity-75'
                    fill='currentColor'
                    d='M4 12a8 8 0 018-8v8H4z'
                  ></path>
                </svg>
              </div>
            ) : (
              <>
                <RiDeleteBin5Line />
                {'remove'}
              </>
            )}
          </Button>
        </div>
      )}
    </li>
  );
};

export default PhotoAlbumCard;

/* {!profilePicUrl && (
        <span className='absolute flex justify-center items-center w-full h-full'>
          <label
            htmlFor='profilePic'
            className='flex justify-center items-center h-[45px] px-4 rounded-md font-bold text-white bg-[#FF4061CC] hover:bg-[#FF4061] cursor-pointer active:border-[#808080] border-2 border-[transparent]'
          >
            Add
          </label>
          <input
            id='profilePic'
            type='file'
            className='hidden'
            accept='image/*'
            onChange={handleProfilePicChange}
          />
        </span>
      )} */
/* {console.log('dc:', profilePicUrl)} */

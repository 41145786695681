import { useSelector } from 'react-redux';

const MatchPreferenceData = ({
  match_age_from,
  match_age_to,
  match_annual_income,
  match_country,
  match_dietary_preferences,
  match_having_children,
  match_height_from_ft,
  match_height_from_inch,
  match_height_to_ft,
  match_height_to_inch,
  match_highest_qualification,
  match_manglic_status,
  match_marital_status,
  match_occupation,
  match_qualification_type,
  match_state,
}) => {
  const qualificationTypeData = useSelector(
    (state) => state.field.qualificationType
  );
  const countryData = useSelector((state) => state.field.country);
  const statesData = useSelector((state) => state.field.states);

  const highestQualificationData = useSelector(
    (state) => state.field.highestQualification
  );
  const annualIncomeData = useSelector((state) => state.field.annualIncome);

  const highestQualificationTypeByShortCode = qualificationTypeData?.find(
    (item) => item.short_code === match_qualification_type
  )?.name;
  const highestQualificationById = highestQualificationData?.find(
    (item) => item.id === match_highest_qualification * 1
  )?.name;
  const annualIncomeById = annualIncomeData?.find(
    (item) => item.value === match_annual_income
  )?.desc;

  const countryByCountryCode = countryData?.find(
    (item) => item.country_code === match_country
  )?.name;
  const stateByStCode = statesData?.find(
    (item) => item.stcode === match_state
  )?.name;

  // console.log(match_dietary_preferences);

  const matchPreferenceData = [
    {
      label: 'Qualification Type',
      value: highestQualificationTypeByShortCode || '----',
    },
    {
      label: 'Highest Qualification',
      value: highestQualificationById || '----',
    },
    {
      label: 'Dietary Preferences',
      value: match_dietary_preferences || '----',
    },
    { label: 'Manglic Status', value: match_manglic_status || '----' },
    { label: 'Marital Status', value: match_marital_status || '----' },
    {
      label: 'Occupation',
      value: match_occupation || '----',
    },
    {
      label: ' Annual-Income',
      value: annualIncomeById || '----',
    },
    {
      label: 'Country',
      value: countryByCountryCode || '----',
    },
    {
      label: 'State',
      value: stateByStCode || '----',
    },
    { label: 'Having Children', value: match_having_children || '----' },
    { label: ' Match Age From', value: match_age_from + ' yrs' || '----' },
    { label: 'Match Age To', value: match_age_to || '----' },
    { label: 'Height From (Ft)', value: match_height_from_ft || '----' },
    { label: 'Height To (Ft)', value: match_height_to_ft || '----' },
    { label: 'Height From (Inch)', value: match_height_from_inch || '----' },
    { label: 'Height To (Inch)', value: match_height_to_inch || '----' },
  ];

  // console.log('match_age_from: ', match_age_from);
  // console.log('match_age_to: ', match_age_to);
  // console.log('match_annual_income: ', match_annual_income);
  // console.log('match_dietary_preferences: ', match_dietary_preferences);
  // console.log('match_having_children: ', match_having_children);
  // console.log('match_height_from_ft: ', match_height_from_ft);
  // console.log('match_height_from_inch: ', match_height_from_inch);
  // console.log('match_height_to_ft: ', match_height_to_ft);
  // console.log('match_height_to_inch: ', match_height_to_inch);
  // console.log('match_highest_qualification: ', match_highest_qualification);
  // console.log('match_manglic_status: ', match_manglic_status);
  // console.log('match_marital_status: ', match_marital_status);
  // console.log('match_occupation: ', match_occupation);
  // console.log('match_qualification_type: ', match_qualification_type);
  // console.log('match_country: ', match_country);
  // console.log('match_state: ', match_state);

  return (
    <div className='flex flex-col justify-center items-center w-full bg-white shadow-lg py-6 rounded-lg mb-6'>
      {/* <div className='flex flex-col justify-center items-center w-full md:w-[49%] lg:w-[48%] bg-white shadow-lg py-6 rounded-lg mb-6'> */}
      <p className='text-left w-full px-6 text-[#333333] font-bold mb-6 text-[20px]'>
        Match Preference
      </p>
      <ul className='flex flex-wrap justify-between items-start mt-2 w-full'>
        {/* <ul className='w-full'> */}
        {matchPreferenceData.map((item, index) => (
          <li
            key={index}
            className='flex justify-start items-start w-full bg-[#FFC0CB22] py-1 px-6 md:w-[49%] my-2'
            // className='flex justify-between items-start mb-1 bg-[#FFC0CB22] px-6 py-1'
          >
            <span className='font-medium text-[#555555] text-[16px]'>
              {item.label}:
            </span>
            <span className='text-[#616161] font-normal text-[16px] ml-2 w-[60%]'>
              {/* <span className='text-[#616161] font-normal text-[16px]'> */}
              {item.value}
            </span>
          </li>
        ))}
      </ul>
    </div>
    // <div className='flex flex-col justify-center items-center w-full lg:w-[100%]'>
    //   <div className='flex flex-col justify-center items-center md:items-start py-10 w-full'>
    //     <p className='text-[#333333] font-bold mb-4'>Match Preference</p>
    //     <ul className='flex flex-wrap justify-between items-start mt-2 w-full'>
    //       <li className='flex justify-start items-start w-full md:w-[49%] my-2'>
    //         <span className='font-medium text-[#555555] text-[16px] mx-2 w-[40%]'>
    //           Qualification Type:
    //         </span>
    //         <span className='text-[#616161] font-normal text-[16px] ml-2 w-[60%]'>
    //           {highestQualificationTypeByShortCode || '----'}
    //         </span>
    //       </li>
    //       <li className='flex justify-start items-start w-full md:w-[49%] my-2'>
    //         <span className='font-medium text-[#555555] text-[16px] mx-2 w-[40%]'>
    //           Highest Qualification:
    //         </span>
    //         <span className='text-[#616161] font-normal text-[16px] ml-2 w-[60%]'>
    //           {highestQualificationById || '----'}
    //         </span>
    //       </li>
    //       <li className='flex justify-start items-start w-full md:w-[49%] my-2'>
    //         <span className='font-medium text-[#555555] text-[16px] mx-2 w-[40%]'>
    //           Dietary Preferences:
    //         </span>
    //         <span className='text-[#616161] font-normal text-[16px] ml-2 w-[60%]'>
    //           {match_dietary_preferences || '----'}
    //         </span>
    //       </li>
    //       <li className='flex justify-start items-start w-full md:w-[49%] my-2'>
    //         <span className='font-medium text-[#555555] text-[16px] mx-2 w-[40%]'>
    //           Manglic Status:
    //         </span>
    //         <span className='text-[#616161] font-normal text-[16px] ml-2 w-[60%]'>
    //           {match_manglic_status || '----'}
    //         </span>
    //       </li>
    //       <li className='flex justify-start items-start w-full md:w-[49%] my-2'>
    //         <span className='font-medium text-[#555555] text-[16px] mx-2 w-[40%]'>
    //           Marital Status:
    //         </span>
    //         <span className='text-[#616161] font-normal text-[16px] ml-2 w-[60%]'>
    //           {match_marital_status || '----'}
    //         </span>
    //       </li>
    //       <li className='flex justify-start items-start w-full md:w-[49%] my-2'>
    //         <span className='font-medium text-[#555555] text-[16px] mx-2 w-[40%]'>
    //           Occupation:
    //         </span>
    //         <span className='text-[#616161] font-normal text-[16px] ml-2 w-[60%]'>
    //           {match_occupation || '----'}
    //         </span>
    //       </li>
    //       <li className='flex justify-start items-start w-full md:w-[49%] my-2'>
    //         <span className='font-medium text-[#555555] text-[16px] mx-2 w-[40%]'>
    //           Annual-Income:
    //         </span>
    //         <span className='text-[#616161] font-normal text-[16px] ml-2 w-[60%]'>
    //           {annualIncomeById || '----'}
    //         </span>
    //       </li>
    //       <li className='flex justify-start items-start w-full md:w-[49%] my-2'>
    //         <span className='font-medium text-[#555555] text-[16px] mx-2 w-[40%]'>
    //           Country:
    //         </span>
    //         <span className='text-[#616161] font-normal text-[16px] ml-2 w-[60%]'>
    //           {countryByCountryCode || '----'}
    //         </span>
    //       </li>
    //       <li className='flex justify-start items-start w-full md:w-[49%] my-2'>
    //         <span className='font-medium text-[#555555] text-[16px] mx-2 w-[40%]'>
    //           State:
    //         </span>
    //         <span className='text-[#616161] font-normal text-[16px] ml-2 w-[60%]'>
    //           {stateByStCode || '----'}
    //         </span>
    //       </li>
    //       <li className='flex justify-start items-start w-full md:w-[49%] my-2'>
    //         <span className='font-medium text-[#555555] text-[16px] mx-2 w-[40%]'>
    //           Having Children:
    //         </span>
    //         <span className='text-[#616161] font-normal text-[16px] ml-2 w-[60%]'>
    //           {match_having_children || '----'}
    //         </span>
    //       </li>
    //       <li className='flex justify-start items-start w-full md:w-[49%] my-2'>
    //         <span className='font-medium text-[#555555] text-[16px] mx-2 w-[40%]'>
    //           Match Age From:
    //         </span>
    //         <span className='text-[#616161] font-normal text-[16px] ml-2 w-[60%]'>
    //           {match_age_from + ' yrs' || '----'}
    //         </span>
    //       </li>
    //       <li className='flex justify-start items-start w-full md:w-[49%] my-2'>
    //         <span className='font-medium text-[#555555] text-[16px] mx-2 w-[40%]'>
    //           Match Age To:
    //         </span>
    //         <span className='text-[#616161] font-normal text-[16px] ml-2 w-[60%]'>
    //           {match_age_to + ' yrs' || '----'}
    //         </span>
    //       </li>

    //       <li className='flex justify-start items-start w-full md:w-[49%] my-2'>
    //         <span className='font-medium text-[#555555] text-[16px] mx-2 w-[40%]'>
    //           Height From (Ft):
    //         </span>
    //         <span className='text-[#616161] font-normal text-[16px] ml-2 w-[60%]'>
    //           {match_height_from_ft || '----'}
    //         </span>
    //       </li>
    //       <li className='flex justify-start items-start w-full md:w-[49%] my-2'>
    //         <span className='font-medium text-[#555555] text-[16px] mx-2 w-[40%]'>
    //           Height To (Ft):
    //         </span>
    //         <span className='text-[#616161] font-normal text-[16px] ml-2 w-[60%]'>
    //           {match_height_to_ft || '----'}
    //         </span>
    //       </li>
    //       <li className='flex justify-start items-start w-full md:w-[49%] my-2'>
    //         <span className='font-medium text-[#555555] text-[16px] mx-2 w-[40%]'>
    //           Height From (Inch):
    //         </span>
    //         <span className='text-[#616161] font-normal text-[16px] ml-2 w-[60%]'>
    //           {match_height_from_inch || '----'}
    //         </span>
    //       </li>
    //       <li className='flex justify-start items-start w-full md:w-[49%] my-2'>
    //         <span className='font-medium text-[#555555] text-[16px] mx-2 w-[40%]'>
    //           Height To (Inch):
    //         </span>
    //         <span className='text-[#616161] font-normal text-[16px] ml-2 w-[60%]'>
    //           {match_height_to_inch || '----'}
    //         </span>
    //       </li>
    //     </ul>
    //   </div>
    // </div>
  );
};
export default MatchPreferenceData;

// export const dietaryPreferencesOptions = [
//   { name: 'Vegetarian' },
//   { name: 'Non-Vegetarian' },
//   { name: 'Vegan' },
//   { name: 'Eggetarian' },
//   { name: 'Pescatarian' },
//   { name: 'Jain Vegetarian' },
//   { name: 'Lacto Vegetarian' },
//   { name: 'Ovo Vegetarian' },
//   { name: 'Lacto-Ovo Vegetarian' },
//   { name: 'Fruitarian' },
//   { name: 'Gluten-Free' },
//   { name: 'Ketogenic' },
//   { name: 'Paleo' },
//   { name: 'Raw Vegan' },
//   { name: 'Low Carb' },
//   { name: 'Low Fat' },
//   { name: 'Organic' },
//   { name: 'Halal' },
//   { name: 'Kosher' },
//   { name: 'Other' },
// ];

export const hobbiesOptions = [
  { name: 'Reading' },
  { name: 'Writing' },
  { name: 'Drawing/Painting' },
  { name: 'Photography' },
  { name: 'Music (Listening)' },
  { name: 'Music (Playing Instrument)' },
  { name: 'Singing' },
  { name: 'Dancing' },
  { name: 'Cooking' },
  { name: 'Baking' },
  { name: 'Gardening' },
  { name: 'Traveling' },
  { name: 'Hiking' },
  { name: 'Camping' },
  { name: 'Sports (Playing)' },
  { name: 'Sports (Watching)' },
  { name: 'Fitness/Exercise' },
  { name: 'Yoga' },
  { name: 'Meditation' },
  { name: 'Volunteering' },
  { name: 'Learning New Skills' },
  { name: 'DIY Projects' },
  { name: 'Watching Movies/TV Shows' },
  { name: 'Gaming' },
  { name: 'Collecting (Stamps, Coins, etc.)' },
  { name: 'Fashion/Shopping' },
  { name: 'Foodie/Culinary Exploration' },
  { name: 'Pets/Animal Care' },
  { name: 'Socializing/Networking' },
  { name: 'Outdoor Activities' },
  { name: 'Adventure Sports' },
  { name: 'Art and Crafts' },
  { name: 'Writing Poetry/Prose' },
  { name: 'Fishing' },
  { name: 'Cycling' },
  { name: 'Photography' },
  { name: 'Birdwatching' },
  { name: 'Calligraphy' },
  { name: 'Astrology' },
  { name: 'Board Games/Card Games' },
  { name: 'Reading Literature' },
  { name: 'Cultural Events' },
  { name: 'Languages' },
  { name: 'Technology/Computer Science' },
  { name: 'Entrepreneurship' },
  { name: 'Financial Investments' },
  { name: 'Philanthropy' },
  { name: 'Spirituality' },
  { name: 'Environmental Conservation' },
  { name: 'Politics/Current Affairs' },
  { name: 'History' },
  { name: 'Science' },
  { name: 'Health and Wellness' },
  { name: 'Personal Development' },
  { name: 'Other' },
];

export const numOfChildren = [
  { name: 0 },
  { name: 1 },
  { name: 2 },
  { name: 3 },
];

export const colorComplexionArray = [
  { name: 'Fair' },
  { name: 'Wheatish' },
  { name: 'Dark' },
  { name: 'Very Fair' },
];

export const familyValuesArray = [
  { name: 'Traditional' },
  { name: ' Modern' },
  { name: ' Religious' },
  { name: ' Liberal' },
  { name: ' Conservative' },
  { name: ' Nuclear' },
  { name: ' Joint' },
  { name: ' Supportive' },
  { name: ' Educated' },
  { name: ' Community-Oriented' },
];

export const familyTypesArray = [
  { name: 'Nuclear' },
  { name: 'Joint' },
  { name: 'Extended' },
  { name: 'Single' },
  { name: 'Parent' },
  { name: 'Blended' },
];

export const numberOfSiblings = [
  { name: '1' },
  { name: '2' },
  { name: '3' },
  { name: '4' },
  { name: '5 or more' },
];

export const manglikStatusOptions = [{ name: 'Yes' }, { name: 'No' }];
export const havingChildrenOptions = [{ name: 'Yes' }, { name: 'No' }];

export const maritalStatusOptions = [
  { name: 'Unmarried' },
  { name: 'Divorced' },
  { name: 'Widowed' },
  { name: 'Separated' },
];

// Rashi############################
export const rashiOptions = [
  { name: 'Mesh (Aries)' },
  { name: 'Vrishabh (Taurus)' },
  { name: 'Mithun (Gemini)' },
  { name: 'Kark (Cancer)' },
  { name: 'Singh (Leo)' },
  { name: 'Kanya (Virgo)' },
  { name: 'Tula (Libra)' },
  { name: 'Vrishchik (Scorpio)' },
  { name: 'Dhanu (Sagittarius)' },
  { name: 'Makar (Capricorn)' },
  { name: 'Kumbh (Aquarius)' },
  { name: 'Meen (Pisces)' },
];

// About more############################
export const dummyAboutMySelf = `I am a [qualification-type] who enjoys living a balanced and meaningful life. I was born in [PlaceofBirth] and believe in staying healthy and active. My hobbies include [Hobbies], which help to relax and express myself.`;

export const dummyAboutFamily = `My family is my biggest support system. We follow [Family_Values] that keep our bond close and connected. We believe in helping each other. We come from a [Family_Type] and value love, respect, and happiness in our home.`;

export const numberWords = [
  'zero',
  'one',
  'two',
  'three',
  'four',
  'five',
  'six',
  'seven',
  'eight',
  'nine',
  'ten',
  'eleven',
  'twelve',
  'thirteen',
  'fourteen',
  'fifteen',
  'sixteen',
  'seventeen',
  'eighteen',
  'nineteen',
  'twenty',
  'thirty',
  'forty',
  'fifty',
  'sixty',
  'seventy',
  'eighty',
  'ninety',
  'hundred',
  'thousand',
  'million',
  'billion',
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
];
export const emailWords = [
  'at',
  'dot',
  'com',
  'net',
  'org',
  'edu',
  'gov',
  'mil',
  'co',
  'uk',
  'de',
  'jp',
  'fr',
  'au',
  'br',
  'it',
  'us',
  'ca',
  'cn',
  'ru',
  'info',
  'biz',
  'me',
  'name',
  'tv',
  'cc',
  'us',
  'ws',
  'mobi',
  'tel',
  'asia',
  'jobs',
  'cat',
  'pro',
  'aero',
  'coop',
  'museum',
];
export const addressWords = [
  'street',
  'st',
  'road',
  'rd',
  'lane',
  'ln',
  'avenue',
  'ave',
  'boulevard',
  'blvd',
  'drive',
  'dr',
  'court',
  'ct',
  'circle',
  'cir',
  'place',
  'pl',
  'apartment',
  'apt',
  'unit',
  'suite',
  'building',
  'bldg',
  'floor',
  'fl',
  'block',
  'zipcode',
  'zip',
];

import { configureStore } from '@reduxjs/toolkit';
import userReducer from './features/userSlice';
import fieldsSlice from './features/fieldsSlice';
import companyMasterSlice from './features/companyMasterSlice';
import imageSlice from './features/imageSlice';
import profileReducer from './features/profileSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    field: fieldsSlice,
    companyMaster: companyMasterSlice,
    image: imageSlice,
    profile: profileReducer,
  },
});

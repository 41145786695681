// import FindYourMatchForm from './FindYourMatchForm';
// import matrimonialHeroImage from './matrimonialHeroImage.webp';

const HeroSection = () => {
  return (
    // <section
    //   className={`relative flex justify-center items-center w-[100%] h-[100vh] lg:h-[100vh] bg-[url('https://img.freepik.com/free-vector/abstract-luxury-beautiful-decorative-yellow-background_1055-6454.jpg?t=st=1718832305~exp=1718835905~hmac=7020a2b099f1cc80e5235812348d804df321c740ef605ff4a6fc7931248826c2&w=740')] bg-cover bg-no-repeat bg-top`}
    // >
    <section
      className={`relative flex justify-center items-center w-[100%] h-[100vh] lg:h-[100vh]`}
    >
      <div className='w-full h-full bg-gray-600 absolute inset-0 overflow-hidden'>
        <iframe
          className='w-full h-full absolute -top-14'
          frameBorder='0'
          allowFullScreen
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          referrerPolicy='strict-origin-when-cross-origin'
          title='authentic slow motion footage of traditional india 2023 11 27 04 54 31 utc'
          width='640'
          height='360'
          src='https://www.youtube.com/embed/yKALM7Lydok?autoplay=1&mute=1&controls=0&rel=0&modestbranding=1&loop=1&playlist=yKALM7Lydok&vq=small&playsinline=1&enablejsapi=1&origin=https%3A%2F%2Ftemplatekits.modeltheme.com&widgetid=1'
          id='widget2'
          style={{ width: '1536px', height: '864px' }}
        ></iframe>
      </div>
      <span className='absolute z-0 left-0 top-0 bg-[rgb(0,0,0,0.5)] w-[100%] h-[100%]'></span>

      <div className='relative z-10 flex flex-col justify-center items-center w-[90%] lg:w-full'>
        <div className='flex justify-center items-center mt-10 w-full'>
          <h2 className='text-[#FFFFFF] font-shadow mt-10 md:mt-0 text-[24px] lg:text-[28px] font-semibold lg:font-black text-center'>
            {/* Forever Starts Here. Your Journey, Your Wedding Wonderland! */}
            Wellcome To KatiyaSamaj.com
          </h2>
        </div>
        <div className='flex flex-col justify-center items-center w-full'>
          {/* <FindYourMatchForm /> */}
        </div>
      </div>
    </section>
  );
};

export default HeroSection;

/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import { VscEyeClosed } from 'react-icons/vsc';
import { VscEye } from 'react-icons/vsc';
import Button from '../../components/UI/Button/Button';
import Input from '../../components/UI/Input/Input';
import { useEffect, useState } from 'react';
import { loginUser } from '../../redux/features/userSlice';
import { Link, useNavigate } from 'react-router-dom';

const SignInForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { status } = useSelector((state) => state.user);
  // const isLoading = status === 'loading';

  const [isLoading, setIsLoading] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [error, setError] = useState(null);
  const [isPasswordShow, setIsPasswordShow] = useState(false);

  const [formData, setFormData] = useState({ email: '', password: '' });
  const [formErrors, setFormErrors] = useState({ email: '', password: '' });
  const [touched, setTouched] = useState({ email: false, password: false });

  const loginStatus = useSelector((state) => state.user.status);
  const loginError = useSelector((state) => state.user.loginError);
  // console.log('loginError: ', loginError);
  useEffect(() => {
    // console.log('loginError: ', loginError);
    if (loginError) {
      setError(loginError);
    }
  }, [dispatch, loginError]);

  // useEffect(() => {
  //   if (loginStatus === 'logged in') {
  //     // setIsLoading(false);
  //     props.onShowSuccesMsgText('Login successful!');
  //     // Show success popup
  //   } else if (loginStatus === 'login failed') {
  //     // setIsLoading(false);
  //     setError('Login failed, please try again');
  //   }
  // }, [loginStatus]);

  useEffect(() => {
    if (touched.email) {
      if (
        formData.email &&
        !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email) &&
        !/^\d{10}$/.test(formData.email)
      ) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          email: 'Please enter a valid email address or phone number.',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          email: '',
        }));
      }
    }
  }, [formData.email, touched.email]);

  useEffect(() => {
    if (touched.password) {
      if (formData.password && formData.password.length < 8) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          password: 'Password must be at least 8 characters long.',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          password: '',
        }));
      }
    }
  }, [formData.password, touched.password]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
    setTouched((prevTouched) => ({
      ...prevTouched,
      [id]: true,
    }));
  };

  const validateInputs = (data) => {
    let valid = true;
    const errors = {
      email: '',
      password: '',
    };

    if (data.email === '') {
      valid = false;
      errors.email = 'Please enter email!';
    }
    if (data.password === '') {
      valid = false;
      errors.password = 'Please enter password!';
    }

    setFormErrors(errors);
    return valid;
  };

  const onSignInHandler = async (event) => {
    event.preventDefault();
    // setIsLoading(true);

    if (!validateInputs(formData)) {
      // setIsLoading(false);
      return;
    }
    try {
      // console.log(formData);
      setIsLoading(true);
      const data = await dispatch(loginUser(formData));
      // console.log(data.payload.data.status);
      if (data?.payload?.data?.status === 'success') {
        props.onShowSuccesMsgText('Login successful!');
        navigate('/dashboard');
        setIsLogin(true);
        setError('');
      }
    } catch (err) {
      // setError(err.message);
      // console.log(error);
    } finally {
      setIsLoading(false);
    }
    //Close success Popup
  };

  return (
    <form
      onSubmit={onSignInHandler}
      className='flex flex-col justify-center items-start w-full md:w-[60%] lg:w-[35%] shadow-xl mt-6 p-6'
    >
      <Input
        id='email'
        placeholder='Enter email'
        label='Email id'
        value={formData.email}
        onChange={handleChange}
        className={`w-[550px] ${formErrors.email ? 'border-red-500' : ''}`}
        error={formErrors.email}
      />

      <Input
        id='password'
        type={isPasswordShow ? 'text' : 'password'}
        placeholder='Enter password'
        label='Password'
        value={formData.password}
        onChange={handleChange}
        inputBtn={
          isPasswordShow ? (
            <VscEye className='!text-[#858585] mr-2' />
          ) : (
            <VscEyeClosed className='!text-[#858585] mr-2' />
          )
        }
        className={`w-full ${formErrors.password ? 'border-red-500' : ''}`}
        inputBtnHandler={() => setIsPasswordShow(!isPasswordShow)}
        error={formErrors.password}
      />
      <Link to='/forgot-password'>
        <p className='text-[#66B6FF] ml-2 hover:underline cursor-pointer'>
          Forgot Password?
        </p>
      </Link>

      <div className='w-full text-center text-[14px] text-[#FF4061] font-medium mt-5'>
        {error}
      </div>

      <div className='flex flex-col justify-center items-start w-full mt-5 mb-2'>
        <Button
          text='Signin'
          className='flex-row-reverse justify-center items-center w-full h-[60px] font-bold text-[24px]'
          disabled={isLoading}
        >
          {isLoading && (
            <div className='flex justify-center items-center bg-white rounded-full ml-2'>
              <svg
                className='animate-spin h-6 w-6 text-pink-500'
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
              >
                <circle
                  className='opacity-25'
                  cx='12'
                  cy='12'
                  r='10'
                  stroke='currentColor'
                  strokeWidth='4'
                ></circle>
                <path
                  className='opacity-75'
                  fill='currentColor'
                  d='M4 12a8 8 0 018-8v8H4z'
                ></path>
              </svg>
            </div>
          )}
        </Button>
      </div>
    </form>
  );
};

export default SignInForm;

import Button from '../../components/UI/Button/Button';

const AllMatchesPaginate = ({
  allMatchingProfileData,
  totalPages,
  currentPage,
  setCurrentPage,
  recordsPerPage,
  setRecordsPerPage,
}) => {
  // allMatchingProfileData?.length > 0 && console.log(allMatchingProfileData);

  // const [currentPage, setCurrentPage] = useState(1);
  // const [recordsPerPage, setRecordsPerPage] = useState(10);

  // const totalRecords = allMatchingProfileData?.length || 0;
  // const totalPages = Math.ceil(totalRecords / recordsPerPage);

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handleRecordsPerPageChange = (event) => {
    setRecordsPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to the first page when changing records per page
  };

  // Calculate the slice of data to display
  // const startIndex = (currentPage - 1) * recordsPerPage;
  // const endIndex = startIndex + recordsPerPage;
  // const currentData = allMatchingProfileData?.slice(startIndex, endIndex);

  // console.log('currentData: ', currentData);

  return (
    <div className='flex flex-col items-center w-full p-4'>
      {/* Pagination Controls */}
      <div className='flex justify-between items-center w-[90%] p-4 mt-4'>
        <div className='flex items-center'>
          <Button
            text='Prev'
            onButtonClick={handlePrev}
            className='!bg-[#FFFFFF] !shadow-transparent text-[14px] lg:text-[20px] font-light rounded-none w-[40px] lg:w-[72px] !border-[1px] !border-[#333333] hover:text-[#FF4061] active:text-[#333333]'
            colorBtn='text-[#333333]'
            heightBtn='h-[30px] lg:h-[36px]'
          />
          <span className='flex justify-center items-center font-semibold text-[24px] text-[#FF4061] border-[1px] border-[#333333] mx-2 p-2 h-[30px] lg:h-[36px]'>
            {currentPage}
          </span>
          <Button
            text='Next'
            onButtonClick={handleNext}
            className='!bg-[#FFFFFF] !shadow-transparent text-[14px] lg:text-[20px] font-light rounded-none w-[40px] lg:w-[72px] !border-[1px] !border-[#333333] hover:text-[#FF4061] active:text-[#333333]'
            colorBtn='text-[#333333]'
            heightBtn='h-[30px] lg:h-[36px]'
          />
        </div>
        <div className='flex items-center'>
          <label className='text-[14px] font-medium text-[#333333] mr-2'>
            Records per page:
          </label>
          <select
            value={recordsPerPage}
            onChange={handleRecordsPerPageChange}
            className='h-[36px] w-[60px] text-[#333333] font-medium rounded-md px-2 border-[1px] border-[#999999]'
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
          </select>
        </div>
        <div className='flex items-center'>
          <span className='text-[14px] font-medium text-[#333333] mr-2'>
            Page:
          </span>
          <span className='font-semibold'>
            {currentPage} of {totalPages}
          </span>
        </div>
      </div>
    </div>
  );
};

export default AllMatchesPaginate;

import { useState } from 'react';
import { AiOutlineCalendar } from 'react-icons/ai';
import { FaUser } from 'react-icons/fa6';
import { GiBodyHeight } from 'react-icons/gi';
import {
  MdCastForEducation,
  MdContentCopy,
  MdOutlineLocationCity,
} from 'react-icons/md';
import { useSelector } from 'react-redux';

const UserAboutData = ({
  occupation_detail,
  height_ft,
  height_inch,
  dob,
  gender,
  city,
  first_name,
  last_name,
  about_myself,
  profile_id,
  place_of_birth,
  qualification_type,
  hobbies,
}) => {
  // console.log(hobbies);

  // console.log(height_ft);
  // console.log(height_inch);

  // const [profileId, setProfileID] = useState();
  // setProfileID(profile_id || '');
  // console.log(profileId);

  const cityData = useSelector((state) => state.field.city);

  const [isProfileIdCopy, setIsProfileIdCopy] = useState(false);
  const [copyAlertMessage, setCopyAlertMessage] = useState('Copied!');

  const handleCopyProfileId = () => {
    if (navigator && navigator.clipboard) {
      navigator.clipboard
        .writeText(profile_id)
        // .writeText(profileId)
        .then(() => {
          setIsProfileIdCopy(true);
          setCopyAlertMessage('Copied!');
          setTimeout(() => setIsProfileIdCopy(false), 1000);
        })
        .catch((err) => {
          console.error('Failed to copy text: ', err);
          setCopyAlertMessage('Failed to copy!');
        });
    } else {
      // Fallback approach
      const textArea = document.createElement('textarea');
      textArea.value = profile_id;
      // textArea.value = profileId;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand('copy');
        setIsProfileIdCopy(true);
        setCopyAlertMessage('Copied!');
      } catch (err) {
        console.error('Fallback: Copying text command was unsuccessful', err);
        setCopyAlertMessage('Failed to copy!');
      }
      document.body.removeChild(textArea);
      setTimeout(() => setIsProfileIdCopy(false), 1000);
    }
  };

  // const handleCopyProfileId = () => {
  //   navigator.clipboard
  //     .writeText(profileId)
  //     .then(() => {
  //       setIsProfileIdCopy(true);
  //       setTimeout(() => setIsProfileIdCopy(false), 1000);
  //     })
  //     .catch((err) => setCopyAlertMessage('Failed to copy!'));
  // };

  const userName =
    first_name && last_name
      ? `${first_name?.charAt(0)?.toUpperCase()}${first_name?.slice(
          1
        )} ${last_name?.charAt('')?.toUpperCase()}${last_name?.slice(1)}`
      : '';
  // console.log(userName);

  const selectedDate = new Date(dob);
  const today = new Date();
  let age = today.getFullYear() - selectedDate.getFullYear();
  // console.log('today: ', today);
  // console.log('selectedDate: ', selectedDate);

  // console.log('age: ', age);

  const cityById = cityData?.find((item) => item.id === city * 1)?.name || '';
  // console.log(cityById);
  // const height = height_ft && height_inch ? `${height_ft}'${height_inch}"` : '';

  // console.log('about_myself: ', about_myself);
  // console.log('dummyAboutMySelf: ', dummyAboutMySelf);

  const qualificationTypeData = useSelector(
    (state) => state.field.qualificationType
  );

  const getHighestQualificationByShortCode =
    qualificationTypeData?.find(
      (item) => item.short_code === qualification_type
    )?.name || '';

  // console.log(getHighestQualificationByShortCode);

  const dummyAboutMySelf = `I am a ${
    getHighestQualificationByShortCode || '[qualification - type]'
  } who enjoys living a balanced and meaningful life. I was born in ${
    place_of_birth || '[PlaceofBirth]'
  } and believe in staying healthy and active. My hobbies include ${
    hobbies || '[Hobbies]'
  }, which help to relax and express myself.`;

  return (
    <ul className='flex flex-col justify-start items-start w-full md:w-full lg:w-[64%]'>
      <li className='flex flex-col justify-start items-center lg:items-start w-full mb-4 mt-4 lg:mt-0'>
        <p className='font-extrabold text-[#FF4061] text-[24px] md:text-[26px] lg:text-[36px] mb-2'>
          {userName || ''}
        </p>
        <div className='flex flex-col-reverse md:flex-row justify-center items-center'>
          <div className='flex justify-center pl-2 items-center font-bold text-[#FF4061] bg-[#FFC0CB] h-[30px] rounded-md text-[20px]'>
            Profile Id:
            <span className='text-[#FFFFFF] ml-1' id='textToCopy'>
              {profile_id}
              {/* {profileId} */}
            </span>
            <span
              onClick={handleCopyProfileId}
              className='flex px-2 justify-center items-center ml-2 text-[#FFFFFF] bg-[#333333] hover:bg-[#FF4061] rounded-r-md h-full cursor-pointer active:text-[#FF4061] '
            >
              <span
                className={`${
                  isProfileIdCopy ? 'flex' : 'opacity-0'
                } justify-center items-center text-[14px] absolute mt-[-70px] h-[30px] rounded-sm p-2 bg-[#393939] font-bold text-[#FFFFFF] transition-opacity duration-100`}
              >
                {copyAlertMessage}
              </span>
              <MdContentCopy />
            </span>
          </div>
          <span className='flex justify-center items-center bg-[#4CAF50] font-medium text-[#FFFFFF] rounded-md px-3 my-2 h-[30px] ml-4'>
            Available
          </span>
        </div>
      </li>
      <li className='flex justify-center items-center w-full my-4'>
        <ul className='flex flex-wrap md:flex-row justify-between items-center w-full'>
          <li className='flex flex-col justify-center items-center w-[45%] md:w-[18%] lg:w-[18%] h-[130px] border-2 rounded-lg my-3 md:my-0'>
            <MdOutlineLocationCity size={42} className='text-[#FFC0CB]' />
            <span className='font-medium text-[#818181] text-[20px] my-1'>
              City
            </span>
            <span className='font-bold text-[#FF4061] text-[16px] lg:text-[18px]'>
              {cityById || ''}
            </span>
          </li>
          <li className='flex flex-col justify-center items-center w-[45%] md:w-[18%] lg:w-[18%] h-[130px] border-2 rounded-lg my-3 md:my-0'>
            <FaUser size={42} className='text-[#FFC0CB]' />
            <span className='font-medium text-[#818181] text-[20px] my-1'>
              Gender
            </span>
            <span className='font-bold text-[#FF4061] text-[16px] lg:text-[18px]'>
              {gender || ''}
            </span>
          </li>
          <li className='flex flex-col justify-center items-center w-[45%] md:w-[18%] lg:w-[18%] h-[130px] border-2 rounded-lg my-3 md:my-0'>
            <AiOutlineCalendar size={42} className='text-[#FFC0CB]' />
            <span className='font-medium text-[#818181] text-[20px] my-1'>
              Age
            </span>
            <span className='font-bold text-[#FF4061] text-center text-[16px] lg:text-[18px]'>
              {age || ''}
            </span>
          </li>
          <li className='flex flex-col justify-center items-center w-[45%] md:w-[18%] lg:w-[18%] h-[130px] border-2 rounded-lg my-3 md:my-0'>
            <GiBodyHeight size={42} className='text-[#FFC0CB]' />
            <span className='font-medium text-[#818181] text-[20px] my-1'>
              Height
            </span>
            <span className='font-bold text-[#FF4061] text-center text-[16px] lg:text-[18px]'>
              {`${height_ft}'${height_inch}"` || ''}
            </span>
          </li>
          <li className='flex flex-col justify-center items-center w-[45%] md:w-[18%] lg:w-[18%] h-[130px] border-2 rounded-lg px-1 my-3 md:my-0'>
            <MdCastForEducation size={42} className='text-[#FFC0CB]' />
            <span className='font-medium text-[#818181] text-[20px] my-1'>
              Occupation
            </span>
            <span className='font-bold text-[#FF4061] text-center text-[16px] lg:text-[18px]'>
              {occupation_detail || ''}
            </span>
          </li>
        </ul>
      </li>
      <li className='flex flex-col justify-center items-center md:items-start w-full my-4'>
        <p className='text-[#333333] font-bold my-2 text-[20px]'>
          About Myself
        </p>
        <p className='text-[#818181] my-2 text-center md:text-left text-[18px]'>
          {about_myself || dummyAboutMySelf}
        </p>
      </li>
    </ul>
  );
};
export default UserAboutData;

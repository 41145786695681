// import { AiOutlineLoading } from 'react-icons/ai';
import Button from '../../../../components/UI/Button/Button';
import SelectInput from '../../../../components/UI/SelectInput/SelectInput';
import EditProfileLayout from './EditProfileLayout';
import { useEffect, useState } from 'react';
import {
  havingChildrenOptions,
  manglikStatusOptions,
  maritalStatusOptions,
} from '../../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserMatchPreferenceProfile } from '../../../../redux/features/userSlice';

const MatchPreferences = (props) => {
  const dispatch = useDispatch();

  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const additionalDetails = useSelector(
    (state) => state.user.additionalDetails
  );
  // console.log('user: ', user);

  const {
    match_age_from,
    match_age_to,
    match_annual_income,
    match_country,
    match_having_children,
    match_height_from_ft,
    match_height_from_inch,
    match_height_to_ft,
    match_height_to_inch,
    match_highest_qualification,
    match_manglic_status,
    match_dietary_preferences,
    match_marital_status,
    match_occupation,
    match_qualification_type,
    match_state,
  } = additionalDetails || {};

  // console.log(
  // match_age_from,
  // match_age_to
  // match_annual_income,
  // match_country,
  // match_having_children,
  // match_height_from_ft,
  // match_height_from_inch,
  // match_height_to_ft,
  // match_height_to_inch
  // match_highest_qualification,
  // match_manglic_status
  // match_dietary_preferences
  // match_marital_status,
  // match_occupation
  // match_qualification_type
  // match_state
  // );

  //////////DATA////////
  let heightFtData = [{ name: 4 }, { name: 5 }, { name: 6 }, { name: 7 }];
  let heightInchData = [
    { name: 0 },
    { name: 1 },
    { name: 2 },
    { name: 3 },
    { name: 4 },
    { name: 5 },
    { name: 6 },
    { name: 7 },
    { name: 8 },
    { name: 9 },
  ];
  const ageData = Array.from({ length: 50 - 18 + 1 }, (_, index) => ({
    name: 18 + index,
  }));
  // console.log(ageData);

  const qualificationTypeData = useSelector(
    (state) => state.field.qualificationType
  );
  const highestQualificationData = useSelector(
    (state) => state.field.highestQualification
  );
  const occupationTypeData = useSelector((state) => state.field.occupation);
  const annualIncomeData = useSelector((state) => state.field.annualIncome);
  const dietaryPreferencesData = useSelector(
    (state) => state.field.dietaryPreferences
  );
  // console.log(dietaryPreferencesData);

  const countryData = useSelector((state) => state.field.country);
  const statesData = useSelector((state) => state.field.states);
  // console.log(countryData);
  // console.log(statesData);

  //////////OPTIONS////////
  // const qualificationTypeOptions = qualificationTypeData.filter(
  //   (options) => options.id !== qualification_type
  // );
  // const highestQualificationOptions = highestQualificationData.filter(
  //   (options) => options.id !== highest_qualification
  // );
  // const occupationTypeOptions = occupationTypeData.filter(
  //   (options) => options.id !== occupation_type
  // );
  const annualIncomeOptions = annualIncomeData
    //   .filter((option) => option.value !== current_income)
    .map((option) => {
      return {
        ...option,
        name: option.desc,
      };
    });

  const countryOptions = countryData?.filter(
    (option) => option.country_code !== match_country
  );

  // console.log(countryOptions);

  // const countryOptions = countryData.filter((option) => option.id !== country);
  // const selectedCountryCode =
  //   countryData.find((item) => item.id === country)?.country_code || '';
  // const statesOptions = statesData.filter(
  //   (option) => option.country_code === selectedCountryCode
  // );
  // console.log(countryOptions, statesOptions);

  const selectedQualificationType =
    qualificationTypeData && qualificationTypeData.length > 0
      ? qualificationTypeData?.find(
          (quaTypObj) => quaTypObj.short_code === match_qualification_type
        )?.name || ''
      : '';
  const selectedHighestQualification =
    highestQualificationData && highestQualificationData.length > 0
      ? highestQualificationData?.find(
          (highQualObj) => highQualObj.id === match_highest_qualification * 1
        )?.name || ''
      : '';
  const selectedOccupation =
    occupationTypeData && occupationTypeData.length > 0
      ? occupationTypeData?.find(
          (occuTypObj) => occuTypObj.id === match_occupation * 1
        )?.name || ''
      : '';

  const selectedAnnualIncome =
    annualIncomeData && annualIncomeData.length > 0
      ? annualIncomeData?.find(
          (annuIncObj) => annuIncObj.value === match_annual_income
        )?.desc || ''
      : '';
  const selectedState =
    statesData && statesData.length > 0
      ? statesData?.find((st) => st.stcode === match_state)?.name || ''
      : '';
  const selectedCountry =
    countryData && countryData.length > 0
      ? countryData?.find((ctry) => ctry.country_code === match_country)
          ?.name || ''
      : '';

  const selectedCountryFormData =
    countryData && countryData.length > 0
      ? countryData?.find((ctry) => ctry.country_code === match_country)?.id ||
        ''
      : '';
  // console.log(qualificationTypeData);

  const selectedQualificationTypeFormData =
    qualificationTypeData && qualificationTypeData.length > 0
      ? qualificationTypeData?.find(
          (qualification) =>
            qualification.short_code === match_qualification_type
        )?.id || ''
      : '';

  // console.log(selectedQualificationTypeFormData);

  const [formData, setFormData] = useState({
    qualificationType: selectedQualificationTypeFormData || '',
    highestQualification: match_highest_qualification || '',
    occupation: match_occupation || '',
    annualIncome: match_annual_income || '',
    manglicStatus: match_manglic_status || '',
    dietaryPreferences: match_dietary_preferences || '',
    maritalStatus: match_marital_status || '',
    havingChildren: match_having_children || '',
    country: selectedCountryFormData || '',
    state: match_state || '',
    ageFrom: match_age_from || '',
    ageTo: match_age_to || '',
    heightFromIn: match_height_from_inch ?? '',
    heightToIn: match_height_to_inch ?? '',
    heightFromFt: match_height_from_ft || '',
    heightToFt: match_height_to_ft || '',
  });

  const [formErrors, setFormErrors] = useState({
    qualificationType: '',
    highestQualification: '',
    occupation: '',
    annualIncome: '',
    manglicStatus: '',
    dietaryPreferences: '',
    maritalStatus: '',
    havingChildren: '',
    country: '',
    state: '',
    ageFrom: '',
    ageTo: '',
    heightFromIn: '',
    heightToIn: '',
    heightFromFt: '',
    heightToFt: '',
  });

  const [touched, setTouched] = useState({
    qualificationType: false,
    highestQualification: false,
    occupation: false,
    annualIncome: false,
    manglicStatus: false,
    dietaryPreferences: false,
    maritalStatus: false,
    havingChildren: false,
    country: false,
    state: false,
    ageFrom: false,
    ageTo: false,
    heightFromIn: false,
    heightToIn: false,
    heightFromFt: false,
    heightToFt: false,
  });

  useEffect(() => {
    if (touched.qualificationType) {
      if (!formData.qualificationType) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          qualificationType: 'Please select qualification type!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          qualificationType: '',
        }));
      }
    }
  }, [formData.qualificationType, touched.qualificationType]);

  useEffect(() => {
    if (touched.highestQualification) {
      if (!formData.highestQualification) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          highestQualification: 'Please select highest qualification!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          highestQualification: '',
        }));
      }
    }
  }, [formData.highestQualification, touched.highestQualification]);

  useEffect(() => {
    if (touched.occupation) {
      if (!formData.occupation) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          occupation: 'Please select highest qualification!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          occupation: '',
        }));
      }
    }
  }, [formData.occupation, touched.occupation]);

  useEffect(() => {
    if (touched.annualIncome) {
      if (!formData.annualIncome) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          annualIncome: 'Please select annual income!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          annualIncome: '',
        }));
      }
    }
  }, [formData.annualIncome, touched.annualIncome]);

  useEffect(() => {
    if (touched.manglicStatus) {
      if (!formData.manglicStatus) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          manglicStatus: 'Please select manglic status!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          manglicStatus: '',
        }));
      }
    }
  }, [formData.manglicStatus, touched.manglicStatus]);

  useEffect(() => {
    if (touched.dietaryPreferences) {
      if (!formData.dietaryPreferences) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          dietaryPreferences: 'Please select dietary preferences!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          dietaryPreferences: '',
        }));
      }
    }
  }, [formData.dietaryPreferences, touched.dietaryPreferences]);

  useEffect(() => {
    if (touched.maritalStatus) {
      if (!formData.maritalStatus) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          maritalStatus: 'Please select marital status!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          maritalStatus: '',
        }));
      }
    }
  }, [formData.maritalStatus, touched.maritalStatus]);

  useEffect(() => {
    if (touched.havingChildren) {
      if (!formData.havingChildren) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          havingChildren: 'Please select marital status!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          havingChildren: '',
        }));
      }
    }
  }, [formData.havingChildren, touched.havingChildren]);

  useEffect(() => {
    if (touched.country) {
      if (!formData.country) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          country: 'Please select country!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          country: '',
        }));
      }
    }
  }, [formData.country, touched.country]);

  useEffect(() => {
    if (touched.state) {
      if (!formData.state) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          state: 'Please select state!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          state: '',
        }));
      }
    }
  }, [formData.state, touched.state]);

  useEffect(() => {
    if (touched.ageFrom) {
      if (!formData.ageFrom) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          ageFrom: 'Please select age from!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          ageFrom: '',
        }));
      }
    }
  }, [formData.ageFrom, touched.ageFrom]);

  useEffect(() => {
    if (touched.ageTo) {
      if (!formData.ageTo) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          ageTo: 'Please select age to!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          ageTo: '',
        }));
      }
    }
  }, [formData.ageTo, touched.ageTo]);

  useEffect(() => {
    if (touched.heightFromFt) {
      if (!formData.heightFromFt) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          heightFromFt: 'Please select height from (Ft.)!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          heightFromFt: '',
        }));
      }
    }
  }, [formData.heightFromFt, touched.heightFromFt]);

  useEffect(() => {
    if (touched.heightToFt) {
      if (!formData.heightToFt) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          heightToFt: 'Please select height to (Ft.)!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          heightToFt: '',
        }));
      }
    }
  }, [formData.heightToFt, touched.heightToFt]);

  useEffect(() => {
    if (touched.heightFromIn) {
      if (!formData.heightFromIn) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          heightFromIn: 'Please select height from (In.)!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          heightFromIn: '',
        }));
      }
    }
  }, [formData.heightFromIn, touched.heightFromIn]);

  useEffect(() => {
    if (touched.heightToIn) {
      if (!formData.heightToIn) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          heightToIn: 'Please select height to (In.)!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          heightToIn: '',
        }));
      }
    }
  }, [formData.heightToIn, touched.heightToIn]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    let updatedFormData = { ...formData, [id]: value };

    setFormData(updatedFormData);
    setTouched((prevTouched) => ({
      ...prevTouched,
      [id]: true,
    }));
  };

  const validateInputs = () => {
    let valid = true;
    const errors = {};

    if (!formData.qualificationType) {
      errors.qualificationType = 'Qualification type is required.';
      valid = false;
    }
    if (!formData.highestQualification) {
      errors.highestQualification = 'Highest qualification is required.';
      valid = false;
    }
    if (!formData.occupation) {
      errors.occupation = 'Occupation is required.';
      valid = false;
    }
    if (!formData.annualIncome) {
      errors.annualIncome = 'Annual income is required.';
      valid = false;
    }
    if (!formData.manglicStatus) {
      errors.manglicStatus = 'Manglic status is required.';
      valid = false;
    }
    if (!formData.dietaryPreferences) {
      errors.dietaryPreferences = 'Dietary Preferences is required.';
      valid = false;
    }
    if (!formData.heightFromFt) {
      errors.heightFromFt = 'Height from (ft.) is required.';
      valid = false;
    }
    if (!formData.heightToFt) {
      errors.heightToFt = 'Height to (ft.) is required.';
      valid = false;
    }
    if (!formData.heightFromIn) {
      errors.heightFromIn = 'Height from (ft.) is required.';
      valid = false;
    }
    if (!formData.heightToIn) {
      errors.heightToIn = 'Height to (ft.) is required.';
      valid = false;
    }
    if (!formData.ageFrom) {
      errors.ageFrom = 'Age from is required.';
      valid = false;
    }
    if (!formData.ageTo) {
      errors.ageTo = 'Age to is required.';
      valid = false;
    }
    if (!formData.maritalStatus) {
      errors.maritalStatus = 'Marital Status is required.';
      valid = false;
    }
    if (!formData.havingChildren) {
      errors.havingChildren = 'Having Children is required.';
      valid = false;
    }
    if (!formData.country) {
      errors.country = 'Country is required.';
      valid = false;
    }
    if (!formData.state) {
      errors.state = 'State is required.';
      valid = false;
    }

    setFormErrors(errors);
    return valid;
  };

  const handleOnSave = async () => {
    setIsLoading(true);
    setError(null);

    if (!validateInputs()) {
      setIsLoading(false);
      return;
    }

    try {
      // console.log(formData.country);
      // console.log(formData.qualificationType);

      const selectedCountry = countryData.find((item) => {
        // console.log(item, formData.country);

        return item.id === formData.country * 1;
      })?.country_code;
      // console.log('selectedCountry: ', selectedCountry);
      const selectedQualificationType = qualificationTypeData.find((item) => {
        return item.id === formData.qualificationType * 1;
      })?.short_code;
      // console.log('selectedQualificationType: ', selectedQualificationType);

      const formDataToSubmit = {
        match_qualification_type: selectedQualificationType,
        match_highest_qualification: formData.highestQualification,
        match_occupation: formData.occupation,
        match_annual_income: formData.annualIncome,
        match_manglic_status: formData.manglicStatus,
        match_dietary_preferences: formData.dietaryPreferences,
        match_age_from: formData.ageFrom,
        match_age_to: formData.ageTo,
        match_height_from_ft: formData.heightFromFt,
        match_height_from_inch: formData.heightFromIn,
        match_height_to_ft: formData.heightToFt,
        match_height_to_inch: formData.heightToIn,
        match_marital_status: formData.maritalStatus,
        match_having_children: formData.havingChildren,
        match_state: formData.state,
        match_country: selectedCountry,
      };
      console.log(formDataToSubmit);
      await dispatch(updateUserMatchPreferenceProfile(formDataToSubmit));

      // Post data to API
    } catch (err) {
      setError(err.message);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <EditProfileLayout
      onShowSignUp={props.onShowSignUp}
      editPage='matchpreferences'
    >
      <div className='flex flex-col justify-center items-center w-full h-full'>
        <div className='flex justify-start items-center w-full font-bold text-[18px] text-[#FF4061]'>
          Match Preferences
        </div>
        <div className='flex flex-col md:flex-row justify-center md:justify-between items-start w-full'>
          <div className='flex justify-center items-center w-full md:w-[49%]'>
            <SelectInput
              id='qualificationType'
              label='Qualification-Type:'
              options={qualificationTypeData || []}
              selectType='id'
              select={selectedQualificationType}
              value={formData.qualificationType}
              onChange={handleChange}
              className='font-semibold cursor-pointer'
              width='w-full'
              error={formErrors.qualificationType}
            />
          </div>
          <div className='flex justify-center items-center w-full md:w-[49%]'>
            <SelectInput
              id='highestQualification'
              label='Highest Qualification:'
              options={highestQualificationData || []}
              selectType='id'
              select={selectedHighestQualification}
              value={formData.highestQualification}
              onChange={handleChange}
              className='font-semibold cursor-pointer'
              width='w-full'
              error={formErrors.highestQualification}
            />
          </div>
        </div>
        <div className='flex flex-col md:flex-row justify-center md:justify-between items-start w-full'>
          <div className='flex justify-center items-center w-full md:w-[49%]'>
            <SelectInput
              id='occupation'
              label='Occupation:'
              options={occupationTypeData || []}
              select={selectedOccupation}
              value={formData.occupation}
              onChange={handleChange}
              className='font-semibold cursor-pointer'
              width='w-full'
              error={formErrors.occupation}
            />
          </div>
          <div className='flex justify-center items-center w-full md:w-[49%]'>
            <SelectInput
              id='annualIncome'
              label='Annual-Income:'
              options={annualIncomeOptions || []}
              selectType='value'
              select={selectedAnnualIncome}
              value={formData.annualIncome}
              onChange={handleChange}
              className='font-semibold cursor-pointer'
              width='w-full'
              error={formErrors.annualIncome}
            />
          </div>
        </div>
        <div className='flex flex-col md:flex-row justify-center md:justify-between items-start w-full'>
          <div className='flex justify-center items-center w-full md:w-[49%]'>
            <SelectInput
              id='manglicStatus'
              options={manglikStatusOptions || []}
              label='Manglic Status:'
              value={formData.manglicStatus}
              onChange={handleChange}
              className='border-2 cursor-pointer'
              width='w-full'
              error={formErrors.manglicStatus}
            />
          </div>
          <div className='flex justify-center items-center w-full md:w-[49%]'>
            <SelectInput
              id='dietaryPreferences'
              label='Dietary Preferences:'
              options={dietaryPreferencesData}
              value={formData.dietaryPreferences}
              selectType='id'
              onChange={handleChange}
              className='font-semibold cursor-pointer'
              width='w-full'
              error={formErrors.dietaryPreferences}
            />
          </div>
        </div>

        <div className='flex flex-col md:flex-row justify-center md:justify-between items-start w-full '>
          <div className='flex justify-between items-center w-full md:w-[49%]'>
            <div className='flex justify-between items-center w-[48%]'>
              <SelectInput
                id='heightFromFt'
                name='heightFromFt'
                label='Height From (Ft.):'
                options={heightFtData}
                // select={height_ft || 'Ft.'}
                // selectValue={dob}
                className='w-full'
                value={formData.heightFromFt}
                onChange={handleChange}
                error={formErrors.ageFrom}
              />
            </div>
            <div className='flex justify-between items-center w-[48%]'>
              <SelectInput
                id='heightToFt'
                name='heightToFt'
                label='Height To (Ft):'
                options={heightFtData}
                // select={height_ft || 'Ft.'}
                // selectValue={dob}
                className='w-full'
                value={formData.heightToFt}
                onChange={handleChange}
                error={formErrors.ageFrom}
              />
            </div>
          </div>
          <div className='flex justify-between items-center w-full md:w-[49%]'>
            <div className='flex justify-between items-center w-[48%]'>
              <SelectInput
                id='heightFromIn'
                name='heightFromIn'
                label='Height From (Inch.):'
                options={heightInchData}
                // select={height_ft || 'Ft.'}
                // selectValue={dob}
                className='w-full'
                value={formData.heightFromIn}
                onChange={handleChange}
                error={formErrors.ageFrom}
              />
            </div>
            <div className='flex justify-between items-center w-[48%]'>
              <SelectInput
                id='heightToIn'
                name='heightToIn'
                label='Height To (Inch):'
                options={heightInchData}
                // select={height_ft || 'Ft.'}
                // selectValue={dob}
                className='w-full'
                value={formData.heightToIn}
                onChange={handleChange}
                error={formErrors.ageFrom}
              />
            </div>
          </div>
        </div>
        <div className='flex flex-col md:flex-row justify-center md:justify-between items-start w-full '>
          <div className='flex justify-between items-start w-full md:w-[49%]'>
            <div className='flex justify-between items-center w-[48%]'>
              <SelectInput
                id='ageFrom'
                name='ageFrom'
                label='Age From:'
                options={ageData}
                // select={height_ft || 'Ft.'}
                // selectValue={dob}
                className='w-full'
                value={formData.ageFrom}
                onChange={handleChange}
                error={formErrors.ageFrom}
              />
            </div>
            <div className='flex justify-between items-end w-[48%]'>
              <SelectInput
                id='ageTo'
                name='ageTo'
                label='Age To:'
                options={ageData}
                // select={height_inch || 'In.'}
                className='w-full'
                value={formData.ageTo}
                onChange={handleChange}
                error={formErrors.ageTo}
              />
            </div>
          </div>
          <div className='flex justify-center items-center w-full md:w-[49%]'>
            <SelectInput
              id='maritalStatus'
              options={maritalStatusOptions || []}
              label='Marital Status:'
              value={formData.maritalStatus}
              onChange={handleChange}
              className='border-2 cursor-pointer'
              width='w-full'
              error={formErrors.maritalStatus}
            />
          </div>
        </div>

        <div className='flex flex-col md:flex-row justify-center md:justify-between items-start w-full'>
          <div className='flex justify-center items-center w-full md:w-[49%]'>
            <SelectInput
              id='havingChildren'
              label='Having Children:'
              options={havingChildrenOptions || []}
              value={formData.havingChildren}
              onChange={handleChange}
              className='font-semibold cursor-pointer'
              width='w-full'
              error={formErrors.havingChildren}
            />
          </div>
          <div className='flex justify-center items-center w-full md:w-[49%]'>
            <SelectInput
              id='country'
              label='Country:'
              options={countryOptions || []}
              selectType='id'
              select={selectedCountry}
              value={formData.country}
              onChange={handleChange}
              className='font-semibold cursor-pointer'
              width='w-full'
              error={formErrors.country}
            />
          </div>
        </div>
        <div className='flex flex-col md:flex-row justify-center md:justify-between items-start w-full'>
          <div className='flex justify-center items-center w-full md:w-[49%]'>
            <SelectInput
              id='state'
              options={statesData || []}
              selectType='stcode'
              select={selectedState}
              label='State:'
              value={formData.state}
              onChange={handleChange}
              className='border-2 cursor-pointer'
              width='w-full'
              error={formErrors.state}
            />
          </div>
        </div>

        <div className='flex justify-start items-center my-5 w-full'>
          <Button
            onButtonClick={handleOnSave}
            text={isLoading ? 'Loading...' : 'Save'}
            className='font-bold w-[130px] h-[50px]'
            disabled={isLoading}
          >
            {isLoading && (
              <div className='flex justify-center items-center bg-white rounded-full ml-2'>
                <svg
                  className='animate-spin h-6 w-6 text-pink-500'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                >
                  <circle
                    className='opacity-25'
                    cx='12'
                    cy='12'
                    r='10'
                    stroke='currentColor'
                    strokeWidth='4'
                  ></circle>
                  <path
                    className='opacity-75'
                    fill='currentColor'
                    d='M4 12a8 8 0 018-8v8H4z'
                  ></path>
                </svg>
              </div>
            )}
          </Button>
        </div>
      </div>
    </EditProfileLayout>
  );
};

export default MatchPreferences;

import EditProfileLayout from './EditProfileLayout';
import Button from '../../../../components/UI/Button/Button';
import SelectInput from '../../../../components/UI/SelectInput/SelectInput';
import Input from '../../../../components/UI/Input/Input';
import { useEffect, useState } from 'react';
// import { AiOutlineLoading } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserEducationProfile } from '../../../../redux/features/userSlice';
import { FaCheckCircle } from 'react-icons/fa';

const EducationProfile = (props) => {
  const dispatch = useDispatch();

  const [error, setError] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const additionalDetails = useSelector(
    (state) => state.user.additionalDetails
  );
  // console.log('user: ', user);

  const {
    qualification_type,
    highest_qualification,
    occupation_type,
    occupation_detail,
    current_income,
    institute_name,
    company_name,
  } = additionalDetails || {};

  // qualification_type && console.log(qualification_type);
  // highest_qualification && console.log(highest_qualification);
  // occupation_type && console.log(occupation_type);
  // occupation_detail && console.log(occupation_detail);
  // current_income && console.log(current_income);

  const qualificationTypeData = useSelector(
    (state) => state.field.qualificationType
  );
  const highestQualificationData = useSelector(
    (state) => state.field.highestQualification
  );
  const occupationTypeData = useSelector((state) => state.field.occupation);
  const annualIncomeData = useSelector((state) => state.field.annualIncome);

  const qualificationTypeOptions = qualificationTypeData.filter(
    (options) => options.id !== qualification_type
  );
  const highestQualificationOptions = highestQualificationData.filter(
    (options) => options.id !== highest_qualification
  );
  const occupationTypeOptions = occupationTypeData.filter(
    (options) => options.id !== occupation_type
  );
  const annualIncomeOptions = annualIncomeData
    .filter((option) => option.value !== current_income)
    .map((option) => {
      return {
        ...option,
        name: option.desc,
      };
    });

  // console.log(qualificationTypeOptions);
  // console.log(highestQualificationOptions);
  // console.log(occupationTypeOptions);
  // console.log(annualIncomeOptions);

  const selectedQualificationType =
    qualificationTypeData && qualificationTypeData.length > 0
      ? qualificationTypeData.find(
          (quaTypObj) => quaTypObj.short_code === qualification_type
        )?.name || ''
      : '';
  const selectedHighestQualification =
    highestQualificationData && highestQualificationData.length > 0
      ? highestQualificationData.find(
          (highQualObj) => highQualObj.id === highest_qualification
        )?.name || ''
      : '';
  const selectedOccupation =
    occupationTypeData && occupationTypeData.length > 0
      ? occupationTypeData.find(
          (occuTypObj) => occuTypObj.id === occupation_type * 1
        )?.name || ''
      : '';

  const selectedAnnualIncome =
    annualIncomeData && annualIncomeData.length > 0
      ? annualIncomeData.find(
          (annuIncObj) => annuIncObj.value === current_income
        )?.desc || ''
      : '';

  // console.log(selectedQualificationType);
  // console.log(selectedHighestQualification);
  // console.log(selectedOccupation);
  // console.log(selectedAnnualIncome);

  const [formData, setFormData] = useState({
    qualificationType: qualification_type || '',
    highestQualification: highest_qualification || '',
    instituteName: institute_name || '',
    occupationDetails: occupation_detail || '',
    occupation: occupation_type || '',
    companyName: company_name || '',
    annualIncome: current_income || '',
  });

  const [formErrors, setFormErrors] = useState({
    qualificationType: '',
    highestQualification: '',
    instituteName: '',
    occupation: '',
    companyName: '',
    annualIncome: '',
  });

  const [touched, setTouched] = useState({
    qualificationType: false,
    highestQualification: false,
    instituteName: false,
    occupation: false,
    companyName: false,
    annualIncome: false,
  });

  useEffect(() => {
    if (touched.qualificationType) {
      if (!formData.qualificationType) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          qualificationType: 'Please select qualification type',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          qualificationType: '',
        }));
      }
    }
  }, [formData.qualificationType, touched.qualificationType]);

  useEffect(() => {
    if (touched.highestQualification) {
      if (!formData.highestQualification) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          highestQualification: 'Please select highest qualification',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          highestQualification: '',
        }));
      }
    }
  }, [formData.highestQualification, touched.highestQualification]);

  useEffect(() => {
    if (touched.instituteName) {
      if (/[^a-zA-Z\s]/.test(formData.instituteName)) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          instituteName: 'Only alphabets are allowed!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          instituteName: '',
        }));
      }
    }
  }, [formData.instituteName, touched.instituteName]);

  useEffect(() => {
    if (touched.occupation) {
      if (!formData.occupation) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          occupation: 'Please select occupation',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          occupation: '',
        }));
      }
    }
  }, [formData.occupation, touched.occupation]);

  useEffect(() => {
    if (touched.companyName) {
      if (/[^a-zA-Z\s]/.test(formData.companyName)) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          companyName: 'Only alphabets are allowed!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          companyName: '',
        }));
      }
    }
  }, [formData.companyName, touched.companyName]);

  useEffect(() => {
    if (touched.annualIncome) {
      if (!formData.annualIncome) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          annualIncome: 'Please select annualIncome',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          annualIncome: '',
        }));
      }
    }
  }, [formData.annualIncome, touched.annualIncome]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    let updatedFormData = { ...formData, [id]: value };

    setFormData(updatedFormData);
    setTouched((prevTouched) => ({
      ...prevTouched,
      [id]: true,
    }));
  };

  const validateInputs = () => {
    let valid = true;
    const errors = {};

    if (!formData.qualificationType) {
      errors.qualificationType = 'Qualification type is required.';
      valid = false;
    }
    if (!formData.highestQualification) {
      errors.highestQualification = 'Highest qualification is required.';
      valid = false;
    }
    if (!formData.instituteName) {
      errors.instituteName = 'Institute name is required.';
      valid = false;
    }
    if (!formData.occupation) {
      errors.occupation = 'Oyccupation is required.';
      valid = false;
    }
    if (!formData.companyName) {
      errors.companyName = 'Company name is required.';
      valid = false;
    }
    if (!formData.annualIncome) {
      errors.annualIncome = 'Annual income is required.';
      valid = false;
    }

    setFormErrors(errors);
    return valid;
  };

  const handleOnSave = async () => {
    setIsLoading(true);
    setError(null);

    if (!validateInputs()) {
      setIsLoading(false);
      return;
    }

    try {
      const formDataToSubmit = {
        qualification_type: formData.qualificationType,
        highest_qualification: formData.highestQualification,
        occupation_type: formData.occupation,
        occupation_detail: formData.occupationDetails,
        institute_name: formData.instituteName,
        company_name: formData.companyName,
        current_income: formData.annualIncome,
      };
      console.log(formDataToSubmit);

      await dispatch(updateUserEducationProfile(formDataToSubmit));
      setSaveSuccess(true);
      // Post data to API
    } catch (err) {
      setError(err.message);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <EditProfileLayout onShowSignUp={props.onShowSignUp} editPage='education'>
      <div className='flex flex-col justify-center items-center w-full h-full'>
        <div className='flex justify-start items-center w-full font-bold text-[18px] text-[#FF4061]'>
          Educational Background
        </div>
        <div className='flex flex-col md:flex-row justify-center md:justify-between items-start w-full'>
          <div className='flex justify-center items-center w-full md:w-[49%]'>
            <SelectInput
              id='qualificationType'
              label='Qualification-Type:'
              options={qualificationTypeOptions}
              value={formData.qualificationType}
              selectType='short_code'
              select={selectedQualificationType}
              onChange={handleChange}
              className='font-semibold cursor-pointer'
              width='w-full'
              error={formErrors.qualificationType}
            />
          </div>
          <div className='flex justify-center items-center w-full md:w-[49%]'>
            <SelectInput
              id='highestQualification'
              label='Highest Qualification:'
              options={highestQualificationOptions}
              selectType='id'
              value={formData.highestQualification}
              select={selectedHighestQualification}
              onChange={handleChange}
              className='font-semibold cursor-pointer'
              width='w-full'
              error={formErrors.highestQualification}
            />
          </div>
        </div>
        <div className='flex flex-col md:flex-row justify-center md:justify-between items-start w-full'>
          <div className='flex justify-center items-center w-full md:w-[49%]'>
            <SelectInput
              id='occupation'
              label='Occupation:'
              options={occupationTypeOptions}
              value={formData.occupation}
              selectType='id'
              select={selectedOccupation}
              onChange={handleChange}
              className='font-semibold cursor-pointer'
              width='w-full'
              error={formErrors.occupation}
            />
          </div>
          <div className='flex justify-center items-center w-full md:w-[49%]'>
            <Input
              id='occupationDetails'
              placeholder='Enter occupation detail'
              label='Occupation Detail:'
              value={formData.occupationDetails}
              onChange={handleChange}
              className='font-semibold'
              width='w-full'
              error={formErrors.occupationDetails}
            />
          </div>
        </div>
        <div className='flex flex-col md:flex-row justify-center md:justify-between items-start w-full'>
          <div className='flex justify-center items-center w-full md:w-[49%]'>
            <Input
              id='instituteName'
              label='Institute Name:'
              placeholder='Enter institute name'
              value={formData.instituteName}
              onChange={handleChange}
              className='font-semibold'
              width='w-full'
              error={formErrors.instituteName}
            />
          </div>

          <div className='flex justify-center items-center w-full md:w-[49%]'>
            <Input
              id='companyName'
              label='Company Name:'
              placeholder='Enter company name'
              value={formData.companyName}
              onChange={handleChange}
              className='font-semibold cursor-pointer'
              width='w-full'
              error={formErrors.companyName}
            />
          </div>
        </div>
        <div className='flex flex-col md:flex-row justify-center md:justify-between items-startś w-full'>
          <div className='flex justify-center items-center w-full md:w-[49%]'>
            <SelectInput
              id='annualIncome'
              label='Annual-Income:'
              options={annualIncomeOptions}
              value={formData.annualIncome}
              selectType='value'
              select={selectedAnnualIncome}
              selectValue='1-5'
              onChange={handleChange}
              className='font-semibold cursor-pointer'
              width='w-full'
              error={formErrors.annualIncome}
            />
          </div>
        </div>
        <div className='flex justify-start items-center my-5 w-full'>
          <Button
            onButtonClick={handleOnSave}
            text={isLoading ? 'Loading...' : 'Save'}
            className='font-bold w-[130px] h-[50px]'
            disabled={isLoading}
          >
            {isLoading && (
              <div className='flex justify-center items-center bg-white rounded-full ml-2'>
                <svg
                  className='animate-spin h-6 w-6 text-pink-500'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                >
                  <circle
                    className='opacity-25'
                    cx='12'
                    cy='12'
                    r='10'
                    stroke='currentColor'
                    strokeWidth='4'
                  ></circle>
                  <path
                    className='opacity-75'
                    fill='currentColor'
                    d='M4 12a8 8 0 018-8v8H4z'
                  ></path>
                </svg>
              </div>
            )}
          </Button>
          {saveSuccess && (
            <span className='flex justify-center items-center font-semibold text-[#333]'>
              <FaCheckCircle className='mr-2 text-[20px] text-[#4CAF50] ml-2' />
              saved
            </span>
          )}
        </div>
      </div>
    </EditProfileLayout>
  );
};

export default EducationProfile;

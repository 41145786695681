/* eslint-disable react-hooks/exhaustive-deps */
import logo from '../../logo.svg';
import Button from '../UI/Button/Button';
import UserIcon from './UserIcon.svg';
import { FaUserGear } from 'react-icons/fa6';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IoLogOut } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../redux/features/userSlice';
import { useEffect, useState } from 'react';
import { ImProfile } from 'react-icons/im';
import { BiSolidEdit } from 'react-icons/bi';
// import { useCookies } from 'react-cookie';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const Navbar = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [companyLogo, setCompanyLogo] = useState();
  const [activePage, setActivePage] = useState('profile');
  const [isLoading, setIsLoading] = useState(false);

  const [expandNavMenu, setExpandNavMenu] = useState(false);

  const toggleExpandNavMenu = () => {
    setExpandNavMenu(!expandNavMenu);
  };

  const [profilePicUrl, setProfilePicUrl] = useState(null);

  const profilePicUrlMyDetail = useSelector(
    (state) => state.image.profilePicUrlMyDetail
  );
  // console.log('profilePicUrlMyDetail: ', profilePicUrlMyDetail);

  const token = localStorage.getItem('auth_token');

  // const [cookies] = useCookies(['laravel_session']);
  // console.log('cookies: ', cookies);

  useEffect(() => {
    if (profilePicUrlMyDetail) {
      const fullPorfilePicUrl = BACKEND_URL + profilePicUrlMyDetail;
      setProfilePicUrl(fullPorfilePicUrl);
    }
  }, [profilePicUrlMyDetail]);

  useEffect(() => {
    // Redirect to dashboard only if on the home or login/signup page
    if (
      token &&
      (location.pathname === '/' ||
        location.pathname === '/signin' ||
        location.pathname === '/signup')
    ) {
      navigate('/dashboard');
    }
  }, [token, location, navigate]);

  const images = useSelector((state) => state.image.companyLogoImages);
  let img = images && images[0] && images[0].url;
  const fullImageUrl = img ? BACKEND_URL + img : '';

  useEffect(() => {
    if (fullImageUrl) {
      setCompanyLogo(fullImageUrl);
    }
  }, [fullImageUrl]);

  const loggedInUser = useSelector((state) => state.user.userInfo);

  // useEffect(() => {
  // if (loggedInUser === null) {
  // console.log('undefined');
  // localStorage.removeItem('auth_token');
  // window.location.reload();
  // }
  // }, []);

  // console.log('loggedInUser: ', loggedInUser, loggedInUser === null);

  let companyInfoArray = useSelector((state) => state.companyMaster.info);

  const companyInfo = companyInfoArray?.length > 0 ? companyInfoArray[0] : null;
  const { company_name } = companyInfo || {};
  // console.log(company_name);

  let userName = loggedInUser?.additional_details?.first_name || '';
  userName = userName?.charAt(0).toUpperCase() + userName?.slice(1);

  const handleLogout = async () => {
    // console.log('logout');

    try {
      setIsLoading(true);
      await dispatch(logoutUser());

      // if (logoutRes) {
      // const status = logoutRes?.payload?.status;
      //##[25 Nov 2024] We need to replacce this code into userSlice file because auth_token does not removed proper
      // if (status === 200) {
      //   localStorage.removeItem('auth_token');
      //   props.onShowSuccessMessage('Successfully logged out.');
      //   navigate('/');
      // }
      // }
      setIsLoading(false);
      toggleExpandNavMenu();
    } catch (error) {
      console.error('Error logging out:', error.message);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    // <header
    //   className={`${props.className} fixed flex justify-center items-center top-0 left-0 w-[100%] z-20 bg-[#FFFFFF00] h-[5rem] lg:h-[6rem]`}
    // >
    <header
      className={`${props.className} relative flex justify-center items-center top-0 left-0 w-[100%] z-20 bg-[#FFFFFF] h-[5rem] lg:h-[5rem] shadow-[0_0_8px_0_rgba(0,0,0,0.25)]`}
      // className={`${props.className} fixed flex justify-center items-center top-0 left-0 w-[100%] z-20 bg-[#FFFFFF] h-[5rem] lg:h-[6rem] shadow-[0_0_8px_0_rgba(0,0,0,0.25)]`}
    >
      <nav className='w-full mx-auto flex justify-between items-center px-2 md:px-10'>
        {/* <nav className='container mx-auto flex justify-between items-center px-2 lg:px-10'> */}
        {/* <!-- Logo --> */}
        <Link
          to='/'
          className='flex justify-center items-center gap-1 text-[#FF4061] text-lg font-semibold'
        >
          <img src={companyLogo || logo} alt='logo' width={50} />
          <span className='hidden md:block lg:block'>{company_name}</span>
        </Link>

        {/* <!-- Sign-in Button --> */}
        <div className='flex justify-center items-center'>
          {!token && (
            // {!loggedInUser && (
            <>
              <Link to='/signin'>
                <Button
                  text='Sign In'
                  className='lg:!text-[24px] lg:!font-bold mr-2'
                />
              </Link>
              <Link to='/signup'>
                <Button
                  text='Register'
                  className='!text-[#FF4061] !border-[#FF4061] !bg-transparent lg:!text-[24px] lg:!font-bold mr-2'
                  // className='!text-[#FFFFFF] border-[#FF4061] !bg-[#fc404300] lg:!text-[24px] lg:!font-bold mr-2'
                />
              </Link>
            </>
          )}
          <div className='relative flex justify-center items-center ml-2 group px-3'>
            {token && (
              // {loggedInUser && (
              <Button
                textColor='#333333'
                text={userName}
                onButtonClick={toggleExpandNavMenu}
                className='!bg-[#FFFFFF] lg:!font-bold !text-[#FF4061] border-2 border-[#FF4061] px-2'
              >
                <img
                  className='rounded-full'
                  src={profilePicUrl || UserIcon}
                  width={30}
                  alt='user'
                />
                <span className='text-[#ff0000]'>Hello</span>
              </Button>
            )}
            {expandNavMenu && (
              <div className='absolute right-auto top-[100%] w-[95%] text-white bg-[rgba(255,255,255,1)] rounded-b-lg transition border-b-4 border-[#FFFFFF]'>
                {/* <div className='absolute right-auto top-[100%] w-[95%] text-white bg-[rgba(255,255,255,1)] rounded-b-lg hidden group-hover:flex transition border-b-4 border-[#FFFFFF]'> */}
                <ul className='flex flex-col justify-center items-start w-full pt-8 pb-2'>
                  <Link
                    onClick={() => {
                      setActivePage('profile');
                    }}
                    to='/dashboard'
                    className='w-full flex'
                  >
                    <li
                      onClick={toggleExpandNavMenu}
                      className={`flex justify-start items-start hover:bg-[#FF4061aa] ${
                        activePage === 'profile'
                          ? 'bg-[#FF4061] text-[#FFFFFF]'
                          : 'bg-[#f4f4f4] text-[#333333]'
                      } hover:text-[#FFFFFF] cursor-pointer w-full px-2 py-3 duration-200`}
                    >
                      <FaUserGear size={24} className='' />
                      <p className='ml-2'>Profile</p>
                    </li>
                  </Link>
                  <Link
                    onClick={() => {
                      setActivePage('findprofile');
                    }}
                    to='/findprofile'
                    className='w-full flex'
                  >
                    <li
                      onClick={toggleExpandNavMenu}
                      className={`flex justify-start items-start hover:bg-[#FF4061aa] ${
                        activePage === 'findprofile'
                          ? 'bg-[#FF4061] text-[#FFFFFF]'
                          : 'bg-[#f4f4f4] text-[#333333]'
                      } hover:text-[#FFFFFF] cursor-pointer w-full px-2 py-3 duration-200`}
                    >
                      <ImProfile size={25} />
                      <p className='ml-2'>Find Profile</p>
                    </li>
                  </Link>
                  <Link
                    onClick={() => {
                      setActivePage('edit-profile');
                    }}
                    to='/edit-profile'
                    className='w-full flex'
                  >
                    <li
                      onClick={toggleExpandNavMenu}
                      className={`flex justify-start items-start hover:bg-[#FF4061aa] ${
                        activePage === 'edit-profile'
                          ? 'bg-[#FF4061] text-[#FFFFFF]'
                          : 'bg-[#f4f4f4] text-[#333333]'
                      } hover:text-[#FFFFFF] cursor-pointer w-full px-2 py-3 duration-200`}
                    >
                      <BiSolidEdit size={26} />
                      <p className='ml-2'>Edit Profile</p>
                    </li>
                  </Link>
                  <li
                    onClick={handleLogout}
                    className='flex justify-start items-start hover:bg-[#FF4061aa] bg-[#f4f4f4] text-[#333333] hover:text-[#FFFFFF] cursor-pointer w-full px-2 py-3 duration-200'
                  >
                    <IoLogOut size={26} />
                    <p className='ml-2'>Logout</p>
                    {isLoading && (
                      <div className='flex justify-center items-center bg-white rounded-full ml-2'>
                        <svg
                          className='animate-spin h-6 w-6 text-pink-500'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 24 24'
                        >
                          <circle
                            className='opacity-25'
                            cx='12'
                            cy='12'
                            r='10'
                            stroke='currentColor'
                            strokeWidth='4'
                          ></circle>
                          <path
                            className='opacity-75'
                            fill='currentColor'
                            d='M4 12a8 8 0 018-8v8H4z'
                          ></path>
                        </svg>
                      </div>
                    )}
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;

// import { AiOutlineLoading } from 'react-icons/ai';
import Button from '../../../../components/UI/Button/Button';
import Input from '../../../../components/UI/Input/Input';
import SelectInput from '../../../../components/UI/SelectInput/SelectInput';
import EditProfileLayout from './EditProfileLayout';
import { useEffect, useState } from 'react';
import {
  familyTypesArray,
  familyValuesArray,
  numberOfSiblings,
} from '../../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserFamilyProfile } from '../../../../redux/features/userSlice';
import { FaCheckCircle } from 'react-icons/fa';

const FamilyProfile = (props) => {
  const dispatch = useDispatch();

  const additionalDetails = useSelector(
    (state) => state.user.additionalDetails
  );
  // console.log('user: ', user);

  const {
    father_name,
    mother_name,
    father_status_occupation,
    mother_status_occupation,
    family_type,
    family_values,
    number_of_siblings,
    siblings_brother,
    siblings_brother_married,
    siblings_sister,
    siblings_sister_married,
  } = additionalDetails || {};

  // console.log('siblings_sister_married: ', siblings_sister_married);
  // console.log('siblings_sister_married: ', siblings_sister_married);
  // console.log('siblings_sister_married: ', siblings_sister_married);
  // console.log('siblings_sister_married: ', siblings_sister_married);
  // console.log('family_type: ', family_type);
  // console.log('family_values: ', family_values);
  // console.log('siblings_sister_married: ', siblings_sister_married);

  const [error, setError] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const occupationData = useSelector((state) => state.field.occupation) || [];
  // console.log(occupationData);

  const [formData, setFormData] = useState({
    fatherName: father_name || '',
    motherName: mother_name || '',
    fatherStatus: father_status_occupation || '',
    motherStatus: mother_status_occupation || '',
    familyType: family_type || '',
    familyValues: family_values || 'default family  ',
    sibling: number_of_siblings || '',
    siblingsBrother: siblings_brother || '',
    siblingsBrotherMarried: siblings_brother_married || '',
    siblingsSister: siblings_sister || '',
    siblingsSisterMarried: siblings_sister_married || '',
  });

  const [formErrors, setFormErrors] = useState({
    fatherName: '',
    motherName: '',
    fatherStatus: '',
    motherStatus: '',
    sibling: '',
    familyType: '',
    familyValues: '',
    siblingsBrother: '',
    siblingsBrotherMarried: '',
    siblingsSister: '',
    siblingsSisterMarried: '',
  });

  const [touched, setTouched] = useState({
    fatherName: false,
    motherName: false,
    fatherStatus: false,
    motherStatus: false,
    sibling: false,
    familyType: false,
    familyValues: false,
    siblingsBrother: false,
    siblingsBrotherMarried: false,
    siblingsSister: false,
    siblingsSisterMarried: false,
  });

  const selectedFatherStatus =
    occupationData && occupationData.length > 0
      ? occupationData.find(
          (occuTypObj) => occuTypObj.id === father_status_occupation * 1
        )?.name || ''
      : '';

  const selectedMotherStatus =
    occupationData && occupationData.length > 0
      ? occupationData.find(
          (occuTypObj) => occuTypObj.id === mother_status_occupation * 1
        )?.name || ''
      : '';

  useEffect(() => {
    if (touched.fatherName) {
      if (/[^a-zA-Z\s]/.test(formData.fatherName)) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          fatherName: 'Only alphabets are allowed!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          fatherName: '',
        }));
      }
    }
  }, [formData.fatherName, touched.fatherName]);

  useEffect(() => {
    if (touched.motherName) {
      if (/[^a-zA-Z\s]/.test(formData.motherName)) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          motherName: 'Only alphabets are allowed!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          motherName: '',
        }));
      }
    }
  }, [formData.motherName, touched.motherName]);

  useEffect(() => {
    if (touched.fatherStatus) {
      if (!formData.fatherStatus) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          fatherStatus: 'Please select father status',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          fatherStatus: '',
        }));
      }
    }
  }, [formData.fatherStatus, touched.fatherStatus]);

  useEffect(() => {
    if (touched.motherStatus) {
      if (!formData.motherStatus) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          motherStatus: 'Please select mother status',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          motherStatus: '',
        }));
      }
    }
  }, [formData.motherStatus, touched.motherStatus]);

  useEffect(() => {
    if (touched.sibling) {
      if (!formData.sibling) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          sibling: 'Please select number of siblings',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          sibling: '',
        }));
      }
    }
  }, [formData.sibling, touched.sibling]);

  useEffect(() => {
    if (touched.familyType) {
      if (!formData.familyType) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          familyType: 'Please select family type',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          familyType: '',
        }));
      }
    }
  }, [formData.familyType, touched.familyType]);

  useEffect(() => {
    if (touched.familyValues) {
      if (!formData.familyValues) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          familyValues: 'Please select family values',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          familyValues: '',
        }));
      }
    }
  }, [formData.familyValues, touched.familyValues]);

  useEffect(() => {
    if (touched.siblingsBrother) {
      if (!formData.siblingsBrother) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          siblingsBrother: 'Please select family values',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          siblingsBrother: '',
        }));
      }
    }
  }, [formData.siblingsBrother, touched.siblingsBrother]);

  useEffect(() => {
    if (touched.siblingsBrotherMarried) {
      if (!formData.siblingsBrotherMarried) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          siblingsBrotherMarried: 'Please select family values',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          siblingsBrotherMarried: '',
        }));
      }
    }
  }, [formData.siblingsBrotherMarried, touched.siblingsBrotherMarried]);

  useEffect(() => {
    if (touched.siblingsSister) {
      if (!formData.siblingsSister) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          siblingsSister: 'Please select family values',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          siblingsSister: '',
        }));
      }
    }
  }, [formData.siblingsSister, touched.siblingsSister]);

  useEffect(() => {
    if (touched.siblingsSisterMarried) {
      if (!formData.siblingsSisterMarried) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          siblingsSisterMarried: 'Please select family values',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          siblingsSisterMarried: '',
        }));
      }
    }
  }, [formData.siblingsSisterMarried, touched.siblingsSisterMarried]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    // console.log(id, value);

    let updatedFormData = { ...formData, [id]: value };
    // console.log(updatedFormData);

    setFormData(updatedFormData);
    setTouched((prevTouched) => ({
      ...prevTouched,
      [id]: true,
    }));
  };

  const validateInputs = () => {
    let valid = true;
    const errors = {};

    if (!formData.fatherName) {
      errors.fatherName = 'Father name is required.';
      valid = false;
    } else if (!/^[a-zA-Z ]+$/.test(formData.fatherName)) {
      errors.fatherName = 'Only contain letters and spaces.';
      valid = false;
    }

    if (!formData.motherName) {
      errors.motherName = 'Mother name is required.';
      valid = false;
    } else if (!/^[a-zA-Z ]+$/.test(formData.motherName)) {
      errors.motherName = 'Only contain letters and spaces.';
      valid = false;
    }

    if (!formData.fatherStatus) {
      errors.fatherStatus = "Please select father's status.";
      valid = false;
    }

    if (!formData.motherStatus) {
      errors.motherStatus = "Please select mother's status.";
      valid = false;
    }

    if (!formData.sibling) {
      errors.sibling = 'Please select number of siblings.';
      valid = false;
    }

    if (!formData.familyType) {
      errors.familyType = 'Please select family type.';
      valid = false;
    }

    if (!formData.familyValues) {
      errors.familyValues = 'Please select family values.';
      valid = false;
    }

    if (!formData.siblingsBrother) {
      errors.siblingsBrother = 'Please select siblings brother.';
      valid = false;
    }

    if (!formData.siblingsBrotherMarried) {
      errors.siblingsBrotherMarried = 'Please select siblings brother married.';
      valid = false;
    }

    if (!formData.siblingsSister) {
      errors.siblingsSister = 'Please select siblings sister.';
      valid = false;
    }

    if (!formData.siblingsSisterMarried) {
      errors.siblingsSisterMarried = 'Please select siblings sister married.';
      valid = false;
    }

    setFormErrors(errors);
    return valid;
  };

  const handleOnSave = async () => {
    setIsLoading(true);
    setError(null);

    if (!validateInputs()) {
      setIsLoading(false);
      return;
    }

    try {
      const formDataToSubmit = {
        father_name: formData.fatherName,
        mother_name: formData.motherName,
        father_status_occupation: formData.fatherStatus,
        mother_status_occupation: formData.motherStatus,
        family_type: formData.familyType,
        family_values: formData.familyValues,
        number_of_siblings: formData.sibling,
        siblings_brother: formData.siblingsBrother,
        siblings_brother_married: formData.siblingsBrotherMarried,
        siblings_sister: formData.siblingsSister,
        siblings_sister_married: formData.siblingsSisterMarried,
      };

      console.log(formDataToSubmit);
      // Post data to API
      await dispatch(updateUserFamilyProfile(formDataToSubmit));
      setSaveSuccess(true);
    } catch (err) {
      setError(err.message);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <EditProfileLayout onShowSignUp={props.onShowSignUp} editPage='family'>
      <div className='flex flex-col justify-center items-center w-full h-full'>
        <div className='flex justify-start items-center w-full font-bold text-[18px] text-[#FF4061]'>
          Family Background
        </div>
        <div className='flex flex-col md:flex-row justify-center md:justify-between items-start w-full'>
          <div className='flex justify-center items-center w-full md:w-[49%]'>
            <Input
              id='fatherName'
              label='Father Name:'
              placeholder='Enter father name'
              className='font-semibold cursor-pointer'
              value={formData.fatherName}
              onChange={handleChange}
              width='w-full'
              error={formErrors.fatherName}
            />
          </div>
          <div className='flex justify-center items-center w-full md:w-[49%]'>
            <Input
              id='motherName'
              label='Mother Name:'
              placeholder='Enter mother name'
              value={formData.motherName}
              onChange={handleChange}
              className='font-semibold cursor-pointer'
              width='w-full'
              error={formErrors.motherName}
            />
          </div>
        </div>
        <div className='flex flex-col md:flex-row justify-center md:justify-between items-start w-full'>
          <div className='flex justify-center items-center w-full md:w-[49%]'>
            <SelectInput
              id='fatherStatus'
              label="Father's Status:"
              options={occupationData}
              value={formData.fatherStatus}
              selectType='id'
              select={selectedFatherStatus || ''}
              onChange={handleChange}
              className='font-semibold cursor-pointer'
              width='w-full'
              error={formErrors.fatherStatus}
            />
          </div>
          <div className='flex justify-center items-center w-full md:w-[49%]'>
            <SelectInput
              id='motherStatus'
              label="Mother's Status:"
              options={occupationData}
              value={formData.motherStatus}
              selectType='id'
              select={selectedMotherStatus}
              onChange={handleChange}
              className='font-semibold cursor-pointer'
              width='w-full'
              error={formErrors.motherStatus}
            />
          </div>
        </div>
        <div className='flex flex-col md:flex-row justify-center md:justify-between items-start w-full'>
          <div className='flex justify-center items-center w-full md:w-[49%]'>
            <SelectInput
              id='familyType'
              label='Family Type:'
              options={familyTypesArray}
              value={formData.familyType}
              onChange={handleChange}
              className='font-semibold cursor-pointer'
              width='w-full'
              error={formErrors.familyType}
            />
          </div>
          <div className='flex justify-center items-center w-full md:w-[49%]'>
            <SelectInput
              id='familyValues'
              label='Family Values:'
              options={familyValuesArray}
              select={family_values}
              value={formData.familyValues}
              onChange={handleChange}
              className='font-semibold cursor-pointer'
              width='w-full'
              error={formErrors.familyValues}
            />
          </div>
        </div>
        <div className='flex flex-col md:flex-row justify-center md:justify-between items-start w-full'>
          <div className='flex justify-center items-center w-full md:w-[49%]'>
            <SelectInput
              id='sibling'
              label='Number of Siblings:'
              options={numberOfSiblings}
              value={formData.sibling}
              onChange={handleChange}
              className='font-semibold cursor-pointer'
              width='w-full'
              error={formErrors.sibling}
            />
          </div>
          <div className='flex justify-center items-center w-full md:w-[49%]'>
            <SelectInput
              id='siblingsBrother'
              label='Siblings Brother:'
              options={numberOfSiblings}
              value={formData.siblingsBrother}
              onChange={handleChange}
              className='font-semibold cursor-pointer'
              width='w-full'
              error={formErrors.siblingsBrother}
            />
          </div>
        </div>
        <div className='flex flex-col md:flex-row justify-center md:justify-between items-start w-full'>
          <div className='flex justify-center items-center w-full md:w-[49%]'>
            <SelectInput
              id='siblingsBrotherMarried'
              label='Siblings Brother Married:'
              options={numberOfSiblings}
              value={formData.siblingsBrotherMarried}
              onChange={handleChange}
              className='font-semibold cursor-pointer'
              width='w-full'
              error={formErrors.siblingsBrotherMarried}
            />
          </div>
          <div className='flex justify-center items-center w-full md:w-[49%]'>
            <SelectInput
              id='siblingsSister'
              label='Siblings Sister:'
              options={numberOfSiblings}
              value={formData.siblingsSister}
              onChange={handleChange}
              className='font-semibold cursor-pointer'
              width='w-full'
              error={formErrors.siblingsSister}
            />
          </div>
        </div>
        <div className='flex flex-col md:flex-row justify-center md:justify-between items-start w-full'>
          <div className='flex justify-center items-center w-full md:w-[49%]'>
            <SelectInput
              id='siblingsSisterMarried'
              label='Siblings Sister Married:'
              options={numberOfSiblings}
              value={formData.siblingsSisterMarried}
              onChange={handleChange}
              className='font-semibold cursor-pointer'
              width='w-full'
              error={formErrors.siblingsSisterMarried}
            />
          </div>
        </div>

        <div className='flex justify-start items-center my-5 w-full'>
          <Button
            onButtonClick={handleOnSave}
            text={isLoading ? 'Loading...' : 'Save'}
            className='font-bold w-[130px] h-[50px]'
            disabled={isLoading}
          >
            {isLoading && (
              <div className='flex justify-center items-center bg-white rounded-full ml-2'>
                <svg
                  className='animate-spin h-6 w-6 text-pink-500'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                >
                  <circle
                    className='opacity-25'
                    cx='12'
                    cy='12'
                    r='10'
                    stroke='currentColor'
                    strokeWidth='4'
                  ></circle>
                  <path
                    className='opacity-75'
                    fill='currentColor'
                    d='M4 12a8 8 0 018-8v8H4z'
                  ></path>
                </svg>
              </div>
            )}
          </Button>
          {saveSuccess && (
            <span className='flex justify-center items-center font-semibold text-[#333]'>
              <FaCheckCircle className='mr-2 text-[20px] text-[#4CAF50] ml-2' />
              saved
            </span>
          )}
        </div>
      </div>
    </EditProfileLayout>
  );
};

export default FamilyProfile;

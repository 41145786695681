/* eslint-disable react-hooks/exhaustive-deps */
import Button from '../../../../components/UI/Button/Button';
import Input from '../../../../components/UI/Input/Input';
import SelectInput from '../../../../components/UI/SelectInput/SelectInput';
import EditProfileLayout from './EditProfileLayout';
import MultipleHobbiesSelect from '../../../../components/UI/SelectInput/MultipleHobbiesSelect';
import { useEffect, useState } from 'react';
// import { AiOutlineLoading } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import PersonalProfileImageProcessing from './PersonalProfileImageProcessing';
import {
  colorComplexionArray,
  hobbiesOptions,
  numOfChildren,
} from '../../../../constants';
import { updateUserPersonalProfile } from '../../../../redux/features/userSlice';
import { FaCheckCircle } from 'react-icons/fa';

const bloodGroupOptions = [
  { name: 'A+' },
  { name: 'A-' },
  { name: 'B+' },
  { name: 'B-' },
  { name: 'AB+' },
  { name: 'AB-' },
  { name: 'O+' },
  { name: 'O-' },
];

let heightFtData = [{ name: 4 }, { name: 5 }, { name: 6 }, { name: 7 }];
let heightInchData = [
  { name: 0 },
  { name: 1 },
  { name: 2 },
  { name: 3 },
  { name: 4 },
  { name: 5 },
  { name: 6 },
  { name: 7 },
  { name: 8 },
  { name: 9 },
  { name: 10 },
  { name: 11 },
];

const PersonalProfile = (props) => {
  const dispatch = useDispatch();
  const weightData = useSelector((state) => state.field.weight);

  const physicalStatusData =
    useSelector((state) => state.field.physicalStatus) || [];
  const dietaryPreferencesData =
    useSelector((state) => state.field.dietaryPreferences) || [];

  // console.log('dietaryPreferencesData: ', dietaryPreferencesData);

  const physicalStatusArray = physicalStatusData?.map((status) => {
    const newStatus = { ...status, name: status.status };
    return newStatus;
  });
  // console.log('physicalStatusData: ', physicalStatusData);
  // console.log('physicalStatusArray: ', physicalStatusArray);

  const additionalDetails = useSelector(
    (state) => state.user.additionalDetails
  );

  // console.log('additionalDetails: ', additionalDetails);

  const {
    first_name,
    last_name,
    marital_status,
    blood_group,
    physically_status,
    number_of_siblings,
    complexion,
    height_ft,
    height_inch,
    weight,
    hobbies,
    dietary_preferences,
  } = additionalDetails || {};
  // console.log(additionalDetails);

  const [isMarried, setIsMarried] = useState(false);
  const [isChildren, setIsChildren] = useState(false);
  const [childrenLivingOptions, setChildrenLivingOptions] = useState([]);

  // console.log('physically_status: ', physically_status);
  // console.log('complexion: ', complexion);
  // console.log('hobbies: ', hobbies);
  // console.log('height_ft: ', height_ft);
  // console.log('height_inch: ', height_inch);
  // console.log('blood_group: ', blood_group);

  const heightFt = heightFtData.filter(
    (option) => option.name !== parseInt(height_ft)
  );
  const heightInch = heightInchData.filter(
    (option) => option.name !== parseInt(height_inch)
  );

  // console.log(heightInch);

  const hobbiesArray = hobbies && JSON.parse(hobbies);

  const weightArray = [];
  if (weightData && weightData[0]) {
    const { weight_from, weight_to, measure } = weightData[0];
    for (let w = weight_from; w <= weight_to; w++) {
      weightArray.push({ name: `${w} ${measure}` });
    }
  }
  // console.log(
  // height_inch !== null && height_inch !== undefined ? height_inch : ''
  // );
  // console.log(height_inch || 'mohit');

  const [error, setError] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    firstName: first_name || '',
    lastName: last_name || '',
    maritalStatus: marital_status || '',
    heightFt: height_ft || '',
    heightIn: height_inch + '' || '',
    // heightIn:
    //   height_inch !== null && height_inch !== undefined ? height_inch : '',
    weight: parseInt(weight) || '',
    bloodGroup: blood_group || '',
    hobbiesInterest: hobbiesArray || [],
    dietaryPreferences: dietary_preferences || '',
    physicalStatus: physically_status || '',
    colorComplexion: complexion || '',
    number_of_children: number_of_siblings || '',
    children_living_with_me: '',
    profilePic: null,
  });

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      firstName: first_name || '',
      lastName: last_name || '',
      maritalStatus: marital_status || '',
      heightFt: height_ft || '',
      heightIn: height_inch + '' || '',
      // heightIn:
      //   height_inch !== null && height_inch !== undefined ? height_inch : '',
      weight: parseInt(weight) || '',
      bloodGroup: blood_group || '',
      hobbiesInterest: hobbiesArray || [],
      dietaryPreferences: dietary_preferences || '',
      physicalStatus: physically_status || '',
      colorComplexion: complexion || '',
      number_of_children: number_of_siblings || '',
      children_living_with_me: '',
    }));
  }, [additionalDetails]);

  // console.log(physically_status, formData.physicalStatus);

  // useEffect(() => {
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     firstName: first_name || '',
  //     lastName: last_name || '',
  //     maritalStatus: marital_status || '',
  //     heightFt: height_ft || '',
  //     heightIn: height_inch + '' || '',
  //     // heightIn:
  //     //   height_inch !== null && height_inch !== undefined ? height_inch : '',
  //     weight: parseInt(weight) || '',
  //     bloodGroup: blood_group || '',
  //     hobbiesInterest: hobbiesArray || [],
  //     physicalStatus: physically_status || '',
  //     colorComplexion: complexion || '',
  //     number_of_children: number_of_siblings || '',
  //   }));
  // }, [additionalDetails]);

  useEffect(() => {
    // console.log('ms: ', marital_status);

    // console.log(marital_status && marital_status !== 'Unmarried');

    if (marital_status && marital_status !== 'Unmarried') {
      setIsMarried(true);
    }

    setFormData((prevData) => ({
      ...prevData,
      weight: weight || '',
    }));
  }, [weight, marital_status]);
  // }, [height_ft, height_inch, weight, marital_status]);

  // console.log(physically_status);

  const [formErrors, setFormErrors] = useState({
    heightFt: '',
    heightIn: '',
    weight: '',
    // manglicStatus: '',
    bloodGroup: '',
    hobbiesInterest: '',
    dietaryPreferences: '',
    profilePic: '',
    physicalStatus: '',
    colorComplexion: '',
    number_of_children: '',
    children_living_with_me: '',
  });

  const [touched, setTouched] = useState({
    heightFt: false,
    heightIn: false,
    weight: false,
    // manglicStatus: false,
    bloodGroup: false,
    hobbiesInterest: false,
    dietaryPreferences: false,
    physicalStatus: false,
    number_of_children: false,
    children_living_with_me: false,
  });

  useEffect(() => {
    if (touched.heightFt) {
      if (!formData.heightFt) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          heightFt: 'Please select height!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          heightFt: '',
        }));
      }
    }
  }, [formData.heightFt, touched.heightFt]);

  useEffect(() => {
    if (touched.heightIn) {
      // console.log('formData.heightIn: ', formData.heightIn);
      if (!formData.heightIn) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          heightIn: 'Please select height!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          heightIn: '',
        }));
      }
    }
  }, [formData.heightIn, touched.heightIn]);

  useEffect(() => {
    if (touched.bloodGroup) {
      if (!formData.bloodGroup) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          bloodGroup: 'Blood group is required',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          bloodGroup: '',
        }));
      }
    }
  }, [formData.bloodGroup, touched.bloodGroup]);

  useEffect(() => {
    if (touched.number_of_children) {
      if (!formData.number_of_children) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          number_of_children: 'Number of children is required',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          number_of_children: '',
        }));
      }
    }
  }, [formData.number_of_children, touched.number_of_children]);

  useEffect(() => {
    if (touched.children_living_with_me) {
      if (!formData.children_living_with_me) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          children_living_with_me: 'Please select children_living_with_me!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          children_living_with_me: '',
        }));
      }
    }
  }, [formData.children_living_with_me, touched.children_living_with_me]);

  useEffect(() => {
    if (touched.weight) {
      if (!formData.weight) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          weight: 'Please select weight',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          weight: '',
        }));
      }
    }
  }, [formData.weight, touched.weight]);

  useEffect(() => {
    if (touched.hobbiesInterest) {
      if (!formData.hobbiesInterest) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          hobbiesInterest: 'Please select atleast one hobby!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          hobbiesInterest: '',
        }));
      }
    }
  }, [formData.hobbiesInterest, touched.hobbiesInterest]);

  useEffect(() => {
    if (touched.dietaryPreferences) {
      if (!formData.dietaryPreferences) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          dietaryPreferences: 'Please select dietary preferences!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          dietaryPreferences: '',
        }));
      }
    }
  }, [formData.dietaryPreferences, touched.dietaryPreferences]);

  useEffect(() => {
    if (touched.physicalStatus) {
      if (!formData.physicalStatus) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          physicalStatus: 'Please select physical status!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          physicalStatus: '',
        }));
      }
    }
  }, [formData.physicalStatus, touched.physicalStatus]);

  useEffect(() => {
    if (touched.colorComplexion) {
      if (!formData.colorComplexion) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          colorComplexion: 'Please select color complexion!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          colorComplexion: '',
        }));
      }
    }
  }, [formData.colorComplexion, touched.colorComplexion]);

  useEffect(() => {
    const numChildren = parseInt(formData.number_of_children);
    if (numChildren > 0) {
      const options = Array.from({ length: numChildren + 1 }, (_, i) => ({
        name: i,
      }));
      setChildrenLivingOptions(options);
    } else {
      setChildrenLivingOptions([]);
    }
  }, [formData.number_of_children]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    // console.log('id: ', id);
    // console.log('value: ', value);

    // const formattedValue =
    //   id === 'children_living_with_me' && value === 'yes'
    //     ? 'Yes'
    //     : id === 'children_living_with_me' && value === 'no'
    //     ? 'No'
    //     : value;

    if (id === 'number_of_children') {
      // console.log("value", parseInt(value));
      if (parseInt(value) > 0) {
        setIsChildren(true);
      } else {
        setIsChildren(false);
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
    setTouched((prevTouched) => ({
      ...prevTouched,
      [id]: true,
    }));
  };

  const handleHobbiesChange = (hobbies) => {
    // console.log(hobbies);

    setFormData({
      ...formData,
      hobbiesInterest: hobbies,
    });
    setFormErrors({
      ...formErrors,
      hobbiesInterest: '',
    });
  };

  const validateInputs = () => {
    let valid = true;
    const errors = {};

    if (!formData.heightFt) {
      errors.heightFt = 'Height required.';
      valid = false;
    }
    if (!formData.heightIn) {
      errors.heightIn = 'Height required.';
      valid = false;
    }
    if (!formData.weight) {
      errors.weight = 'Weight is required.';
      valid = false;
    }
    if (!formData.bloodGroup) {
      errors.bloodGroup = 'Blood group is required.';
      valid = false;
    }
    if (formData.hobbiesInterest.length === 0) {
      errors.hobbiesInterest = 'At least one hobby is required.';
      valid = false;
    }
    if (!formData.dietaryPreferences) {
      errors.dietaryPreferences = 'Dietary preferences is required.';
      valid = false;
    }
    if (!formData.physicalStatus) {
      errors.physicalStatus = 'Physical status is required.';
      valid = false;
    }
    if (!formData.colorComplexion) {
      errors.colorComplexion = 'Color complexion is required.';
      valid = false;
    }
    // if (
    //   formData.maritalStatus &&
    //   formData.maritalStatus !== 'Unmarried' &&
    //   formData.number_of_children === ''
    // ) {
    //   valid = false;
    //   errors.number_of_children = 'Please select number of children.';
    // }

    // if (
    //   formData.maritalStatus &&
    //   formData.number_of_children &&
    //   formData.number_of_children > 0 &&
    //   formData.children_living_with_me === ''
    // ) {
    //   valid = false;
    //   errors.children_living_with_me = 'Select an option to proceed.';
    // }

    setFormErrors(errors);

    return valid;
  };

  const handleOnSave = async () => {
    setIsLoading(true);
    setError(null);

    if (!validateInputs()) {
      setIsLoading(false);
      return;
    }

    try {
      // if (formData && formData.weight) {
      //   formData.weight = Math.floor(formData.weight?.split(' ')[0]);
      // }
      // console.log(formData.hobbiesInterest);
      if (formData && formData.weight) {
        formData.weight = Math.floor(
          typeof formData.weight === 'string'
            ? formData.weight.split(' ')[0]
            : formData.weight
        );
      }

      console.log(formData.children_living_with_me);
      console.log(formData.number_of_children);

      if (!formData.number_of_children) {
        delete formData.number_of_children;
      }

      if (!formData.children_living_with_me) {
        delete formData.children_living_with_me;
      }

      delete formData.firstName;
      delete formData.lastName;
      delete formData.profilePic;
      delete formData.maritalStatus;

      const formDataToSubmit = {
        blood_group: formData.bloodGroup,
        height_ft: formData.heightFt,
        height_inch: formData.heightIn,
        weight: formData.weight,
        hobbies: formData.hobbiesInterest,
        children_living_with_me: formData.children_living_with_me,
        num_of_children: formData.number_of_children,
        dietary_preferences: parseInt(formData.dietaryPreferences),
        complexion: formData.colorComplexion,
        physically_status: parseInt(formData.physicalStatus),
      };

      console.log('formDataToSubmit: ', formDataToSubmit);

      const data = await dispatch(updateUserPersonalProfile(formDataToSubmit));

      console.log('personal data: ', data);

      setIsLoading(false);
      //condtion
      setSaveSuccess(true);

      // Post data to API
    } catch (err) {
      setError(err.message);
      console.log(error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <EditProfileLayout onShowSignUp={props.onShowSignUp} editPage='personal'>
      <div className='flex flex-col justify-center items-center w-full h-full'>
        <div className='flex justify-start items-center w-full font-bold text-[18px] text-[#FF4061]'>
          Personal Profile
        </div>
        <PersonalProfileImageProcessing
          formErrors={formErrors}
          setFormData={setFormData}
          setFormErrors={setFormErrors}
        />
        <div className='flex flex-col md:flex-row justify-center md:justify-between items-center w-full '>
          <div className='flex justify-center items-center w-full md:w-[49%]'>
            <Input
              id='firstName'
              type='text'
              label='First Name:'
              disabled={true}
              placeholder='first name'
              value={formData.firstName}
              // onChange={handleChange}
              className='font-semibold'
              width='w-full'
              error={formErrors.maritalStatus}
            />
          </div>
          <div className='flex justify-center items-center w-full md:w-[49%]'>
            <Input
              id='lastName'
              type='text'
              label='Last Name:'
              disabled={true}
              value={formData.lastName}
              // onChange={handleChange}
              placeholder='last name'
              className='font-semibold'
              width='w-full'
              error={formErrors.lastName}
            />
          </div>
        </div>

        <div className='flex flex-col md:flex-row justify-center md:justify-between items-center w-full '>
          <div className='flex justify-center items-center w-full md:w-[49%]'>
            <Input
              id='maritalStatus'
              type='text'
              label='Marital Status:'
              disabled={true}
              placeholder='marital status'
              value={formData.maritalStatus}
              // onChange={handleChange}
              className='font-semibold'
              width='w-full'
              error={formErrors.maritalStatus}
            />
          </div>
          <div className='flex justify-between items-center w-full md:w-[49%]'>
            <SelectInput
              id='bloodGroup'
              options={bloodGroupOptions}
              label='Blood Group:'
              value={formData.bloodGroup}
              onChange={handleChange}
              className='border-2 cursor-pointer'
              width='w-full'
              error={formErrors.bloodGroup}
            />
          </div>
        </div>

        {/* {console.log('isMarried: ', isMarried)} */}
        {/* {true && ( */}
        {isMarried && (
          <div className='flex flex-col md:flex-row justify-between items-start w-full'>
            <div className='flex justify-center items-center w-full md:w-[49%]'>
              <SelectInput
                id='number_of_children'
                name='number_of_children'
                label='Number of Children:'
                options={numOfChildren || []}
                className='w-full'
                value={formData.number_of_children}
                onChange={handleChange}
                error={formErrors.number_of_children}
              />
            </div>
            <div className='flex justify-start items-end w-full md:w-[48%]'>
              {isChildren && (
                <SelectInput
                  id='children_living_with_me'
                  name='children_living_with_me'
                  label='Children living with me:'
                  options={childrenLivingOptions}
                  className='w-full'
                  value={formData.children_living_with_me}
                  onChange={handleChange}
                  error={formErrors.children_living_with_me}
                />
              )}
            </div>
          </div>
        )}

        <div className='flex flex-col md:flex-row justify-center md:justify-between items-start w-full '>
          <div className='flex justify-between items-start w-full md:w-[49%]'>
            <div className='flex justify-between items-center w-[48%]'>
              <SelectInput
                id='heightFt'
                name='heightFt'
                label='Height (Ft):'
                select={height_ft || 'Ft.'}
                options={heightFt}
                // selectValue={dob}
                className='w-full'
                value={formData.heightFt}
                onChange={handleChange}
                error={formErrors.heightFt}
              />
            </div>
            <div className='flex justify-between items-end w-[48%]'>
              <SelectInput
                id='heightIn'
                name='heightIn'
                label='Height (In):'
                select={height_inch + '' || ''}
                options={heightInch}
                className='w-full'
                value={formData.heightIn}
                onChange={handleChange}
                error={formErrors.heightIn}
              />
            </div>
          </div>
          <div className='flex justify-between items-center w-full md:w-[49%]'>
            <SelectInput
              id='weight'
              name='weight'
              label='Weight:'
              options={weightArray}
              className='w-full'
              value={formData.weight}
              select={parseInt(weight) || ''}
              onChange={handleChange}
              error={formErrors.weight}
            />
          </div>
        </div>

        <div className='flex justify-around items-start w-full'>
          <div className='flex justify-between items-start w-full md:w-[49%]'>
            <MultipleHobbiesSelect
              label='Hobbies:'
              id='hobbies'
              options={hobbiesOptions}
              width='w-full md:w-full lg:w-full'
              value={formData.hobbiesInterest}
              onChange={handleHobbiesChange}
              error={formErrors.hobbiesInterest}
            />
          </div>
          <div className='flex justify-between items-start w-full md:w-[49%]'>
            <SelectInput
              id='dietaryPreferences'
              label='Dietary Preferences:'
              options={dietaryPreferencesData}
              value={formData.dietaryPreferences}
              selectType='id'
              onChange={handleChange}
              className='font-semibold cursor-pointer'
              width='w-full'
              error={formErrors.dietaryPreferences}
            />
          </div>
        </div>

        <div className='flex flex-col md:flex-row justify-center md:justify-between items-start w-full '>
          <div className='flex justify-between items-start w-full md:w-[49%]'>
            <SelectInput
              id='physicalStatus'
              name='physicalStatus'
              label='Physical Status:'
              options={physicalStatusArray || []}
              className='w-full'
              value={formData.physicalStatus}
              selectType='id'
              // select={parseInt(physicalStatus) || ''}
              onChange={handleChange}
              error={formErrors.physicalStatus}
            />
          </div>
          <div className='flex justify-between items-start w-full md:w-[49%]'>
            <SelectInput
              id='colorComplexion'
              name='colorComplexion'
              label='Color Complexion:'
              options={colorComplexionArray || []}
              className='w-full'
              value={formData.colorComplexion}
              // select={parseInt(physicalStatus) || ''}
              onChange={handleChange}
              error={formErrors.colorComplexion}
            />
          </div>
        </div>

        <div className='flex justify-start items-center my-5 w-full'>
          <Button
            onButtonClick={handleOnSave}
            text={isLoading ? 'Loading...' : 'Save'}
            className='font-bold w-[130px] h-[50px]'
            disabled={isLoading}
          >
            {isLoading && (
              <div className='flex justify-center items-center bg-white rounded-full ml-2'>
                <svg
                  className='animate-spin h-6 w-6 text-pink-500'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                >
                  <circle
                    className='opacity-25'
                    cx='12'
                    cy='12'
                    r='10'
                    stroke='currentColor'
                    strokeWidth='4'
                  ></circle>
                  <path
                    className='opacity-75'
                    fill='currentColor'
                    d='M4 12a8 8 0 018-8v8H4z'
                  ></path>
                </svg>
              </div>
            )}
          </Button>
          {saveSuccess && (
            <span className='flex justify-center items-center font-semibold text-[#333]'>
              <FaCheckCircle className='mr-2 text-[20px] text-[#4CAF50] ml-2' />
              saved
            </span>
          )}
          {formErrors.profilePic && (
            <div className='text-[14px] text-[#FF4061] font-medium ml-3'>
              {formErrors.profilePic}
            </div>
          )}
        </div>
      </div>
    </EditProfileLayout>
  );
};

export default PersonalProfile;

/* <div className='flex justify-start items-end w-full md:w-[49%]'>
              {true && (
                // {isChildren && (
                <div className='flex flex-col mt-3'>
                  <label className='block text-[#333333] mb-2'>
                    Children living with me:
                  </label>
                  <div className='flex items-center space-x-6'>
                    <label className='inline-flex items-center'>
                      <input
                        id='children_living_with_me'
                        type='radio'
                        name='children_living_with_me'
                        value='Yes'
                        className='form-radio h-4 w-4 text-[#FF4061] border-gray-300 focus:ring focus:ring-pink-300'
                        checked={formData.children_living_with_me === 'Yes'}
                        onChange={handleChange}
                      />
                      <span className='ml-2 text-gray-700'>yes</span>
                    </label>
                    <label className='inline-flex items-center'>
                      <input
                        id='children_living_with_me'
                        type='radio'
                        name='children_living_with_me'
                        value='No'
                        className='form-radio h-4 w-4 text-pink-500 border-gray-300 focus:ring focus:ring-pink-300'
                        checked={formData.children_living_with_me === 'No'}
                        onChange={handleChange}
                      />
                      <span className='ml-2 text-gray-700'>no</span>
                    </label>
                  </div>
                  {formErrors.children_living_with_me && (
                    <div className='text-[14px] text-[#FF4061] font-medium'>
                      {formErrors.children_living_with_me}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
             */

// import {
//   FaFacebookSquare,
//   FaInstagramSquare,
//   FaWhatsappSquare,
// } from 'react-icons/fa';
// import { FaXTwitter } from 'react-icons/fa6';

const UserHobbiesData = ({ hobbies }) => {
  let array = hobbies && JSON.parse(hobbies);

  return (
    <div className='flex flex-col justify-center items-center py-10 w-full lg:w-[49%]'>
      <div className='flex flex-col justify-center items-center md:items-start w-full'>
        <p className='text-[#333333] font-bold mb-4text-[20px]'>
          Hobbies & Interest
        </p>
        <ul className='flex flex-wrap justify-start items-start mt-2 w-full gap-3'>
          {array &&
            array.map((item, idx) => (
              <li
                key={idx}
                className='flex justify-center items-center w-[48%] md:w-[23%] my-2 lg:my-4 border-2 p-2 rounded-md text-[#818181] text-[16px] font-medium'
              >
                {item}
              </li>
            ))}
        </ul>
      </div>
      {/* <div className='flex flex-col justify-center items-start py-10 w-full'>
        <p className='text-[#555555] font-bold text-[20px] '>Social Media</p>
        <ul className='flex flex-wrap justify-between items-start mt-2 w-full md:w-[40%]  '>
          <li className='flex justify-center items-center w-[45px] h-[45px] my-4 border-2 rounded-md'>
            <FaWhatsappSquare size={45} className='text-[#515151]' />
          </li>
          <li className='flex justify-center items-center w-[45px] h-[45px] my-4 border-2 rounded-md'>
            <FaInstagramSquare size={45} className='text-[#515151]' />
          </li>
          <li className='flex justify-center items-center w-[45px] h-[45px] my-4 border-2 rounded-md'>
            <FaFacebookSquare size={45} className='text-[#515151]' />
          </li>
          <li className='flex justify-center items-center w-[45px] h-[45px] my-4 border-2 rounded-md'>
            <FaXTwitter size={45} className='text-[#515151]' />
          </li>
        </ul>
      </div> */}
    </div>
  );
};
export default UserHobbiesData;

// import { useEffect, useState } from 'react';
import { useEffect, useState } from 'react';
import { FaPhoneAlt, FaEnvelope, FaHome } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { fetchCotactDetailsByProfileId } from '../../../redux/features/profileSlice';
// import { IoWarningOutline } from 'react-icons/io5';
// import { RiErrorWarningLine } from 'react-icons/ri';

const UserContactData = ({
  email,
  mobile,
  address,
  profile_id,
  // contactError,
  // contactDetails,
  // contactLoading,
}) => {
  const dispatch = useDispatch();
  const [contactLoading, setContactLoading] = useState(false);
  const [contactDetails, setContactDetails] = useState(false);
  // const [error, setError] = useState('');

  useEffect(() => {
    const fetchContact = async () => {
      try {
        setContactLoading(true);
        // console.log(profile_id);
        const contactDetails = await dispatch(
          fetchCotactDetailsByProfileId(profile_id)
        ).unwrap();
        // console.log('contactDetails: ', contactDetails);
        if (contactDetails) {
          setContactDetails(contactDetails);
        }
      } catch (error) {
        // console.log(error.message);
      } finally {
        setContactLoading(false);
      }
    };
    fetchContact();
  }, [dispatch]);

  // contactLoading && console.log('contactLoading: ', contactLoading);
  // contactError && console.log(contactError);
  // contactDetails && console.log(contactDetails);
  return (
    <div className='flex flex-col justify-center items-center w-full md:w-[49%] p-6 bg-white shado-lg rounded-lg'>
      <div className='flex flex-col justify-center items-center md:items-start py-10 w-full'>
        <p className='text-[#333333] font-bold mb-4 text-[20px]'>
          Contact Information
        </p>
        {/* {true ? ( */}
        {contactLoading ? (
          <div className='flex justify-center items-center w-full bg-slate-100 rounded-lg'>
            <div className='flex flex-col justify-center items-center py-4 w-full space-y-4 shadow-lg'>
              {/* <div className='flex justify-center items-center rounded-full ml-2'> */}
              <svg
                className='animate-spin h-6 w-6 text-pink-500'
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
              >
                <circle
                  className='opacity-25'
                  cx='12'
                  cy='12'
                  r='10'
                  stroke='currentColor'
                  strokeWidth='4'
                ></circle>
                <path
                  className='opacity-75'
                  fill='currentColor'
                  d='M4 12a8 8 0 018-8v8H4z'
                ></path>
              </svg>
            </div>
          </div>
        ) : contactDetails?.status === 'error' ? (
          <div
            className='bg-orange-100 border border-orange-500 text-orange-500 px-4 py-3 mt-4 rounded relative mb-4 lg:w-[80%]'
            role='alert'
          >
            <span>{contactDetails?.error}</span>
          </div>
        ) : (
          <ul className='flex flex-col justify-center items-start mt-2 w-full space-y-4'>
            <li className='flex items-start w-full'>
              <div className='w-[10%]'>
                <FaPhoneAlt className='text-[#333333] w-[24px] h-[24px] mr-4' />
              </div>
              <div className='w-[90%]'>
                <span className='font-bold text-[#818181] text-[16px] md:text-[18px] lg:text-[20px]'>
                  Phone:
                </span>
                <span className='text-[#818181] ml-2 font-normal text-[16px] md:text-[18px] lg:text-[20px]'>
                  {contactDetails?.data?.mobile || ''}
                </span>
              </div>
            </li>
            {contactDetails?.data?.mobile_alternate && (
              <li className='flex items-start w-full'>
                <div className='w-[10%]'>
                  <FaPhoneAlt className='text-[#333333] w-[24px] h-[24px] mr-4' />
                </div>
                <div className='w-[90%]'>
                  <span className='font-bold text-[#818181] text-[16px] md:text-[18px] lg:text-[20px]'>
                    Alternate Phone:
                  </span>
                  <span className='text-[#818181] ml-2 font-normal text-[16px] md:text-[18px] lg:text-[20px]'>
                    {contactDetails?.data?.mobile_alternate || ''}
                  </span>
                </div>
              </li>
            )}
            <li className='flex items-start w-full'>
              <div className='w-[10%]'>
                <FaEnvelope className='text-[#333333] w-[24px] h-[24px] mr-4' />
              </div>
              <div className='w-[90%]'>
                <span className='font-bold text-[#818181] text-[16px] md:text-[18px] lg:text-[20px]'>
                  Email:
                </span>
                <span className='text-[#818181] ml-2 font-normal text-[16px] md:text-[18px] lg:text-[20px]'>
                  {/* {email || ''} */}
                  Hidden
                </span>
              </div>
            </li>
            <li className='flex items-start w-full'>
              <div className='w-[10%]'>
                <FaHome
                  size={24}
                  className='text-[#333333] w-[25px] h-[25px] mr-4'
                />
              </div>
              <div className='w-[90%]'>
                <span className='font-bold text-[#818181] text-[16px] md:text-[18px] lg:text-[20px]'>
                  Area Pincode:
                </span>
                <span className='text-[#818181] ml-2 font-normal text-[16px] md:text-[18px] lg:text-[20px] text-wrap'>
                  {contactDetails?.data?.pincode || ''}
                </span>
              </div>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default UserContactData;

// const UserContactData = () => {
//   return (
//     <div className="flex flex-col justify-center items-center w-full md:w-[49%]">
//       <div className="flex flex-col justify-center items-center md:items-start py-10 w-full">
//         <p className="text-[#333333] font-bold mb-4 text-[20px]">
//           Contact Information
//         </p>
//         <ul className="flex flex-col justify-center items-start mt-2 w-full ">
//           <li className="flex justify-start items-center w-full my-2">
//             <span className="w-[40px] h-[40px] md:w-[45px] md:h-[45px] lg:w-[50px] lg:h-[50px] rounded-md border-2 mr-2"></span>
//             <span className="font-bold text-[#818181] mx-2 text-[16px] md:text-[18px] lg:text-[20px]">
//               Phone:
//             </span>
//             <span className="text-[#818181] ml-2 font-normal text-[16px] md:text-[18px] lg:text-[20px]">
//               0000000000
//             </span>
//           </li>
//           <li className="flex justify-start items-center w-full my-2">
//             <span className="w-[40px] h-[40px] md:w-[45px] md:h-[45px] lg:w-[50px] lg:h-[50px] rounded-md border-2 mr-2"></span>
//             <span className="font-bold text-[#818181] mx-2 text-[16px] md:text-[18px] lg:text-[20px]">
//               Email:
//             </span>
//             <span className="text-[#818181] ml-2 font-normal text-[16px] md:text-[18px] lg:text-[20px]">
//               davis@gmail.com
//             </span>
//           </li>
//           <li className="flex justify-start items-center w-full my-2">
//             <span className="w-[40px] h-[40px] md:w-[45px] md:h-[45px] lg:w-[50px] lg:h-[50px] rounded-md border-2 mr-2"></span>
//             <span className="font-bold text-[#818181] mx-2 text-[16px] md:text-[18px] lg:text-[20px]">
//               Address:
//             </span>
//             <span className="text-[#818181] ml-2 font-normal text-[16px] md:text-[18px] lg:text-[20px]">
//               G-20, Arera colony
//             </span>
//           </li>
//         </ul>
//       </div>
//     </div>
//   );
// };
// export default UserContactData;

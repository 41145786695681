import { FaCheckCircle } from 'react-icons/fa';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';

const SuccessMessage = (props) => {
  // console.log("props.successMessage: ", props.successMessage);
  return (
    <Modal onHideSignup={props.onHideEditSuccess}>
      <div className='flex flex-col justify-center items-center relative py-5 p-4 w-[250px] md:w-[500px] lg:w-[700px]'>
        <div className='flex justify-between items-center my-4'>
          <FaCheckCircle className='mr-1 text-[#4CAF50] text-[32px] md:text-[40px]' />
          <span className='text-[18px] md:text-[26px] font-bold text-[#333333] ml-1'>
            {props.successMessage}
          </span>
        </div>
        <div className='flex justify-center items-center my-4'>
          <Button
            text='Done'
            className='font-bold h-[50px] w-[150px]'
            onButtonClick={() => {
              props.onHideSuccessMessage();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default SuccessMessage;

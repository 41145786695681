// import { FaCheckCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Button from '../../components/UI/Button/Button';
import Input from '../../components/UI/Input/Input';
import { useEffect, useState } from 'react';
import { forgotPassword } from '../../redux/features/userSlice';
import { useDispatch } from 'react-redux';

const ForgotPasswordForm = (props) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [formData, setFormData] = useState({
    email: '',
  });

  const [formErrors, setFormErrors] = useState({
    email: '',
  });

  const [touched, setTouched] = useState({
    email: false,
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
    setTouched((prevTouched) => ({
      ...prevTouched,
      [id]: true,
    }));
  };

  useEffect(() => {
    if (touched.email) {
      if (
        formData.email &&
        !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)
      ) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          email: 'Please enter a valid email address.',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          email: '',
        }));
      }
    }
  }, [formData.email, touched.email]);

  const validateInputs = (data) => {
    let valid = true;
    const errors = {
      email: '',
    };
    if (data.email === '') {
      valid = false;
      errors.email = 'Please enter your email!';
    }

    setFormErrors(errors);
    return valid;
  };

  const handleOnPasswordReset = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError('');

    if (!validateInputs(formData)) {
      setIsLoading(false);
      return;
    }

    try {
      // console.log(formData);
      const data = await dispatch(forgotPassword(formData)).unwrap();
      if (data) {
        console.log(data);
        const status = data?.status;
        const statusMessage = data?.data?.status;
        // console.log(statusMessage);

        if (status === 200) {
          statusMessage && props.onShowSuccesMsgText(statusMessage);
        }
      }
    } catch (err) {
      console.error('Error occurred: ', err);
      setError(err.message || '');
    } finally {
      setIsLoading(false);
    }
  };

  // console.log(error);

  return (
    <form
      onSubmit={handleOnPasswordReset}
      className='flex flex-col justify-center items-start w-full md:w-[60%] lg:w-[35%] shadow-xl mt-6 p-6'
    >
      <Input
        id='email'
        placeholder='Enter your email'
        label='Email address:'
        className='w-[550px]'
        value={formData.email}
        onChange={handleChange}
        error={formErrors.email}
      />

      <p className='w-full flex justify-center font-medium text-[16px] text-[#333333] mt-2'>
        Back To
        <Link to='/signin'>
          <p className='text-[#66B6FF] ml-1 hover:underline'>SignIn</p>
        </Link>
      </p>
      {/* <Link
        className='w-full flex justify-center font-medium text-[16px] text-[#333333] mt-2'
        to='/reset-password'
      >
        Reset
      </Link> */}

      <div className='w-full text-center text-[14px] text-[#FF4061] font-medium mt-5'>
        {error}
      </div>

      <div className='flex flex-col justify-center items-start w-full mt-6 mb-2'>
        <Button
          text={isLoading ? 'Loading...' : 'Send Link'}
          className='w-full h-[60px] font-bold text-[24px]'
          disabled={isLoading}
        >
          {isLoading && (
            <div className='flex justify-center items-center bg-white rounded-full ml-2'>
              <svg
                className='animate-spin h-6 w-6 text-pink-500'
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
              >
                <circle
                  className='opacity-25'
                  cx='12'
                  cy='12'
                  r='10'
                  stroke='currentColor'
                  strokeWidth='4'
                ></circle>
                <path
                  className='opacity-75'
                  fill='currentColor'
                  d='M4 12a8 8 0 018-8v8H4z'
                ></path>
              </svg>
            </div>
          )}
        </Button>
      </div>
    </form>
  );
};

export default ForgotPasswordForm;

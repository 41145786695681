import imageCompression from 'browser-image-compression';
import watermark from 'watermarkjs';

// const watermarkText = process.env.REACT_APP_WATERMARK_TEXT;
const watermarkText = process.env.REACT_APP_WATERMARK_TEXT;
const watermarkSize = process.env.REACT_APP_WATERMARK_SIZE;

// console.log(watermarkText);

// Convert dataURL to Blob
export const dataURLtoBlob = (dataurl) => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

// Compress the image
export const getCompressedImage = async (imageFile) => {
  const options = {
    maxSizeMB: 0.05,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };
  try {
    return await imageCompression(imageFile, options);
  } catch (error) {
    console.error('Error during image compression:', error);
  }
};

// Apply watermark
export const applyWatermark = async (imageFile) => {
  try {
    const watermarkedImageDataUrl = await watermark([imageFile]).image(
      watermark.text.center(
        watermarkText,
        `${watermarkSize} Arial`,
        '#fff',
        0.5
      )
    );
    if (typeof watermarkedImageDataUrl.src === 'string') {
      return dataURLtoBlob(watermarkedImageDataUrl.src);
    } else {
      throw new TypeError('Watermark result is not a valid data URL.');
    }
  } catch (error) {
    console.error('Error applying watermark:', error);
  }
};

// Create image element from URL
export const createImage = (url) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => resolve(image);
    image.onerror = (error) => reject(error);
    image.src = url;
  });
};

// Get cropped image
export const getCroppedImg = async (imageSrc, crop, croppedAreaPixels) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = croppedAreaPixels.width;
  canvas.height = croppedAreaPixels.height;

  ctx.drawImage(
    image,
    croppedAreaPixels.x * scaleX,
    croppedAreaPixels.y * scaleY,
    croppedAreaPixels.width * scaleX,
    croppedAreaPixels.height * scaleY,
    0,
    0,
    croppedAreaPixels.width,
    croppedAreaPixels.height
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (!blob) {
        reject('Canvas is empty');
        return;
      }
      const file = new File([blob], 'croppedImage.jpeg', {
        type: 'image/jpeg',
      });
      resolve(file);
    }, 'image/jpeg');
  });
};

// utils/cropUtils.js
export const handleCropComplete = (croppedArea, setCroppedAreaPixels) => {
  setCroppedAreaPixels(croppedArea);
};

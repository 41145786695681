import leafBgPic from '../../assets/images/svgviewer-png-output.png';
import icon from '../../logo.svg';
import HIWCard from './HIWCard';

const HowItsWorkSection = () => {
  return (
    <section className='flex justify-center items-center w-[100%] pt-10 pb-24 bg-[#FFFFF4]'>
      <div className='flex flex-col justify-center items-center w-[80%] mb-20'>
        <div className='relative flex justify-center items-center min-h-[300px] w-[100%]'>
          <img
            src={leafBgPic}
            width={500}
            alt='bg-pic'
            className='absolute font-black text-[140px] text-[#FFF0EA] z-10'
          />
          <div className='relative flex flex-col justify-center items-center z-10 -translate-y-16'>
            <img src={icon} alt='' className='opacity-[0.4]' />
            <p className='text-[#333333] font-extrabold text-[40px] '>
              How It's Work
            </p>
          </div>
        </div>
        <div className='flex justify-center items-center'>
          <ul className='flex flex-col justify-center items-start'>
            <HIWCard
              img={leafBgPic}
              title='Register'
              description='Register your profile 100% free. No charges/payment required. Matrimonial details required. After registration just verify your profile.'
              classNameDesc='text-left'
              className='flex-row'
            />
            <HIWCard
              img={leafBgPic}
              title='Find your Match'
              description='Easy to search and find the match as per your preferences. Edit your profile and update your Match Preferences. '
              classNameTitle='text-right'
              classNameDesc='text-right'
              className='flex-row-reverse'
            />
            <HIWCard
              img={leafBgPic}
              title='Send Interest'
              description='Send interest to Matching profiles. This will notify to the member and they will know you are interested to their profile.'
              classNameDesc='text-left'
              className='flex-row'
            />
            <HIWCard
              img={leafBgPic}
              title='Get Profile Information'
              description='View Contact details of any Member 100% Free. No charges / payment required.'
              classNameTitle='text-right'
              classNameDesc='text-right'
              className='flex-row-reverse'
            />
            <HIWCard
              img={leafBgPic}
              title='Self-Manage Profile'
              description='Manage your profile yourself. Add, update your information, photo, photo album, horoscope. '
              classNameDesc='text-left'
              className='flex-row'
            />
          </ul>
        </div>
      </div>
    </section>
  );
};

export default HowItsWorkSection;

import { useSelector } from 'react-redux';

const UserEducationData = ({
  highest_qualification,
  institute_name,
  current_income,
  occupation_type,
  occupation_detail,
}) => {
  const highestQualificationData = useSelector(
    (state) => state.field.highestQualification
  );
  const occupationTypeData = useSelector((state) => state.field.occupation);
  const annualIncomeData = useSelector((state) => state.field.annualIncome);

  const highestQualificationById = highestQualificationData.find(
    (item) => item.id === highest_qualification * 1
  )?.name;
  const occupationTypeById = occupationTypeData.find(
    (item) => item.id === occupation_type * 1
  )?.name;
  const annualIncomeById = annualIncomeData.find(
    (item) => item.value === current_income
  )?.desc;
  return (
    <div className='flex flex-col justify-center items-center w-full lg:w-[49%]'>
      <div className='flex flex-col justify-center items-center md:items-start py-10 w-full'>
        <p className='text-[#333333] font-bold mb-4'>Educational Background</p>
        <ul className='flex flex-wrap justify-between items-start mt-2 w-full'>
          <li className='flex justify-start items-start w-full md:w-[49%] my-2'>
            <span className='font-medium text-[#555555] text-[16px] mx-2 w-[40%]'>
              Highest Qualification:
            </span>
            <span className='text-[#616161] font-normal text-[16px] ml-2 w-[60%]'>
              {highestQualificationById || '----'}
            </span>
          </li>
          <li className='flex justify-start items-start w-full md:w-[49%] my-2'>
            <span className='font-medium text-[#555555] text-[16px] mx-2 w-[40%]'>
              College Name:
            </span>
            <span className='text-[#616161] font-normal text-[16px] ml-2 w-[60%]'>
              {institute_name || '----'}
            </span>
          </li>
          <li className='flex justify-start items-start w-full md:w-[49%] my-2'>
            <span className='font-medium text-[#555555] text-[16px] mx-2 w-[40%]'>
              Occupation-Type:
            </span>
            <span className='text-[#616161] font-normal text-[16px] ml-2 w-[60%]'>
              {occupationTypeById || '----'}
            </span>
          </li>
          <li className='flex justify-start items-start w-full md:w-[49%] my-2'>
            <span className='font-medium text-[#555555] text-[16px] mx-2 w-[40%]'>
              Occupation:
            </span>
            <span className='text-[#616161] font-normal text-[16px] ml-2 w-[60%]'>
              {occupation_detail || '----'}
            </span>
          </li>
          {/* <li className='flex justify-start items-start w-full md:w-[49%] my-2'>
            <span className='font-medium text-[#555555] text-[16px] mx-2 w-[40%]'>
              Company Name:
            </span>
            <span className='text-[#616161] font-normal text-[16px] ml-2 w-[60%]'>
              Solutions Limited
            </span>
          </li> */}
          <li className='flex justify-start items-start w-full md:w-[49%] my-2'>
            <span className='font-medium text-[#555555] text-[16px] mx-2 w-[40%]'>
              Annual-Income:
            </span>
            <span className='text-[#616161] font-normal text-[16px] ml-2 w-[60%]'>
              {annualIncomeById || '----'}
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default UserEducationData;

// import { BiSearch } from 'react-icons/bi';
import SelectInput from '../../../../components/UI/SelectInput/SelectInput';
import { TiStopwatch } from 'react-icons/ti';
import { FaRegThumbsUp } from 'react-icons/fa';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { FaShieldHalved } from 'react-icons/fa6';
import { useSelector } from 'react-redux';
import { FaBriefcase } from 'react-icons/fa';
import { FaUser } from 'react-icons/fa';

const AsideSortingBox = ({
  setSelectedHeight,
  setSelectedAge,
  setSelectedReligion,
  setSelectedLocation,
  setSelectedState,
  setSelectedOccupationType,
  setSelectedHasProfilePhoto,

  selectedHeight,
  selectedAge,
  selectedReligion,
  selectedLocation,
  selectedState,
  selectedOccupationType,
  selectedHasProfilePhoto,
}) => {
  // console.log('selectedHeight: ', selectedHeight);
  // console.log('selectedAge: ', selectedAge);
  // console.log('selectedReligion: ', selectedReligion);
  // console.log('selectedLocation: ', selectedLocation);
  // console.log('selectedState: ', selectedState);
  // console.log('selectedOccupationType: ', selectedOccupationType);
  // console.log('selectedHasProfilePhoto: ', selectedHasProfilePhoto);

  const maxAge = 60;
  const ages = Array.from({ length: maxAge - 18 + 1 }, (_, i) => ({
    name: 18 + i,
  }));

  const cityData = useSelector((state) => state.field.city);
  const stateData = useSelector((state) => state.field.states);
  // console.log('stateData: ', stateData);

  // const religionData = useSelector((state) => state.field.religion);
  const occupationTypeData = useSelector((state) => state.field.occupation);

  return (
    <ul className='flex flex-col justify-center items-center w-full'>
      {/* Filter by Age */}
      <li className='flex flex-col justify-center items-start w-full my-3'>
        <div className='flex justify-start items-center w-full'>
          <span className='flex justify-center items-center w-[25px] h-[25px] lg:w-[30px] lg:h-[30px] border-2 border-[#FFC0CB] rounded-md'>
            <TiStopwatch className='text-[#FF4061] text-[14px] lg:text-[20px]' />
          </span>
          <span className='ml-2 text-[#FF4061BB] font-bold text-[14px] lg:text-[16px]'>
            Age
          </span>
        </div>
        <div className='flex justify-center items-center w-full'>
          <SelectInput
            id='age'
            value={selectedAge}
            onChange={(e) =>
              setSelectedAge(e.target.value === 'Select' ? '' : e.target.value)
            } // Update state
            options={ages}
            className='border-[#FFC0CB] font-semibold !w-[100%]'
          />
        </div>
      </li>

      <li className='flex flex-col justify-center items-start w-full my-3'>
        <div className='flex justify-start items-center w-full'>
          <span className='flex justify-center items-center w-[25px] h-[25px] lg:w-[30px] lg:h-[30px] border-2 border-[#FFC0CB] rounded-md'>
            <FaBriefcase className='text-[#FF4061] text-[14px] lg:text-[20px]' />
          </span>
          <span className='ml-2 text-[#FF4061BB] font-bold text-[14px] lg:text-[16px]'>
            Occupation Type
          </span>
        </div>
        <div className='flex justify-center items-center w-full'>
          <SelectInput
            id='occupation_type'
            value={selectedOccupationType}
            onChange={(e) =>
              setSelectedOccupationType(
                e.target.value === 'Select' ? '' : e.target.value
              )
            } // Update state
            selectType='id'
            options={occupationTypeData}
            className='border-[#FFC0CB] font-semibold !w-[100%]'
          />
        </div>
      </li>

      {/* Filter by City Location */}
      <li className='flex flex-col justify-center items-start w-full my-3'>
        <div className='flex justify-start items-center w-full'>
          <span className='flex justify-center items-center w-[25px] h-[25px] lg:w-[30px] lg:h-[30px] border-2 border-[#FFC0CB] rounded-md'>
            <HiOutlineLocationMarker className='text-[#FF4061] text-[14px] lg:text-[20px]' />
          </span>
          <span className='ml-2 text-[#FF4061BB] font-bold text-[14px] lg:text-[16px]'>
            Location
          </span>
        </div>
        <div className='flex justify-center items-center w-full'>
          <SelectInput
            id='location'
            value={selectedLocation}
            onChange={(e) =>
              setSelectedLocation(
                e.target.value === 'Select' ? '' : e.target.value
              )
            } // Update state
            selectType='id'
            options={cityData}
            className='border-[#FFC0CB] font-semibold !w-[100%]'
          />
        </div>
      </li>

      {/* Filter by State Location */}
      <li className='flex flex-col justify-center items-start w-full my-3'>
        <div className='flex justify-start items-center w-full'>
          <span className='flex justify-center items-center w-[25px] h-[25px] lg:w-[30px] lg:h-[30px] border-2 border-[#FFC0CB] rounded-md'>
            <HiOutlineLocationMarker className='text-[#FF4061] text-[14px] lg:text-[20px]' />
          </span>
          <span className='ml-2 text-[#FF4061BB] font-bold text-[14px] lg:text-[16px]'>
            State
          </span>
        </div>
        <div className='flex justify-center items-center w-full'>
          <SelectInput
            id='state'
            value={selectedState}
            selectType='stcode'
            onChange={(e) =>
              setSelectedState(
                e.target.value === 'Select' ? '' : e.target.value
              )
            }
            options={stateData}
            className='border-[#FFC0CB] font-semibold !w-[100%]'
          />
        </div>
      </li>

      {/* Filter by Profile Photo */}
      <li className='flex flex-col justify-center items-start w-full my-3'>
        <div className='flex justify-start items-center w-full'>
          <span className='flex justify-center items-center w-[25px] h-[25px] lg:w-[30px] lg:h-[30px] border-2 border-[#FFC0CB] rounded-md'>
            <FaUser className='text-[#FF4061] text-[14px] lg:text-[20px]' />
          </span>
          <span className='ml-2 text-[#FF4061BB] font-bold text-[14px] lg:text-[16px]'>
            Profile Photo
          </span>
        </div>
        <div className='flex justify-center items-center w-full'>
          <SelectInput
            id='profile_photo'
            value={selectedHasProfilePhoto}
            onChange={(e) =>
              setSelectedHasProfilePhoto(
                e.target.value === 'Select' ? 'select' : e.target.value
              )
            } // Update state
            selectType='id'
            options={[
              { name: 'With Photo', id: 'yes' },
              { name: 'Without Photo', id: 'null' },
            ]}
            className='border-[#FFC0CB] font-semibold !w-[100%]'
          />
        </div>
      </li>

      <li className='flex flex-col justify-center items-start w-full my-3 opacity-50 pointer-events-none'>
        <div className='flex justify-start items-center w-full'>
          <span className='flex justify-center items-center w-[25px] h-[25px] lg:w-[30px] lg:h-[30px] border-2 border-[#FFC0CB] rounded-md'>
            <FaRegThumbsUp className='text-[#FF4061] text-[14px] lg:text-[20px]' />
          </span>
          <span className='ml-2 text-[#FF4061BB] font-bold text-[14px] lg:text-[16px]'>
            Availablity
          </span>
        </div>
        <div className='flex flex-col justify-start items-start w-full'>
          <div className='flex justify-start items-center my-2'>
            <input
              type='radio'
              name='profile'
              value=''
              onChange={() => {}}
              id='profileall'
              disabled
            />
            <label
              htmlFor='profileall'
              className='text-[#FF4061BB] font-medium text-[14px] ml-4 text-gray-400'
            >
              All
            </label>
          </div>
          <div className='flex justify-start items-center my-2'>
            <input type='radio' name='profile' id='profilepremium' disabled />
            <label
              htmlFor='profilepremium'
              className='text-[#FF4061BB] font-medium text-[14px] ml-4 text-gray-400'
            >
              Available
            </label>
          </div>
          <div className='flex justify-start items-center my-2'>
            <input
              type='radio'
              name='profile'
              value=''
              onChange={() => {}}
              id='profilefree'
              disabled
            />
            <label
              htmlFor='profilefree'
              className='text-[#FF4061BB] font-medium text-[14px] ml-4 text-gray-400'
            >
              Offline
            </label>
          </div>
        </div>
      </li>

      <li className='flex flex-col justify-center items-start w-full my-3 opacity-50 pointer-events-none'>
        <div className='flex justify-start items-center w-full'>
          <span className='flex justify-center items-center w-[25px] h-[25px] lg:w-[30px] lg:h-[30px] border-2 border-[#FFC0CB] rounded-md'>
            <FaShieldHalved className='text-[#FF4061] text-[14px] lg:text-[20px]' />
          </span>
          <span className='ml-2 text-[#FF4061BB] font-bold text-[14px] lg:text-[16px]'>
            Profile
          </span>
        </div>
        <div className='flex flex-col justify-start items-start w-full'>
          <div className='flex justify-start items-center my-2'>
            <input type='radio' name='profile' id='profileall' disabled />
            <label
              htmlFor='profileall'
              className='text-[#FF4061BB] font-medium text-[14px] ml-4 text-gray-400'
            >
              All
            </label>
          </div>
          <div className='flex justify-start items-center my-2'>
            <input type='radio' name='profile' id='profilepremium' disabled />
            <label
              htmlFor='profilepremium'
              className='text-[#FF4061BB] font-medium text-[14px] ml-4 text-gray-400'
            >
              Premium
            </label>
          </div>
          <div className='flex justify-start items-center my-2'>
            <input type='radio' name='profile' id='profilefree' disabled />
            <label
              htmlFor='profilefree'
              className='text-[#FF4061BB] font-medium text-[14px] ml-4 text-gray-400'
            >
              Free
            </label>
          </div>
        </div>
      </li>
    </ul>
  );
};

export default AsideSortingBox;

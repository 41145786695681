import { useSelector } from 'react-redux';

const UserFamilyData = ({
  father_name,
  mother_name,
  father_status_occupation,
  mother_status_occupation,
  number_of_siblings,
  family_type,
  family_city,
  family_values,
  family_country,
  family_state,
  family_pincode,
  siblings_brother,
  siblings_sister,
  siblings_brother_married,
  siblings_sister_married,
}) => {
  const occupationData = useSelector((state) => state.field.occupation);
  const countryData = useSelector((state) => state.field.country);
  const stateData = useSelector((state) => state.field.states);
  const cityData = useSelector((state) => state.field.city);

  const fatherOccupationById = occupationData?.find(
    (item) => item.id === father_status_occupation * 1
  )?.name;
  const motherOccupationById = occupationData?.find(
    (item) => item.id === mother_status_occupation * 1
  )?.name;
  const cityById = cityData?.find((item) => item.id === family_city * 1)?.name;
  const countryById = countryData?.find(
    (item) => item.id === family_country * 1
  )?.name;
  const stateById = stateData?.find(
    (item) => item.stcode === family_state
  )?.name;

  const familyData = [
    { label: 'Father Name', value: father_name || '----' },
    { label: 'Mother Name', value: mother_name || '----' },
    { label: "Father's Status", value: fatherOccupationById || '----' },
    { label: "Mother's Status", value: motherOccupationById || '----' },
    { label: 'No of Siblings', value: number_of_siblings || '----' },
    {
      label: 'Siblings Brother',
      value: siblings_brother || '----',
    },
    {
      label: 'Siblings Sister',
      value: siblings_sister || '----',
    },
    {
      label: 'Siblings Brother Married',
      value: siblings_brother_married || '----',
    },
    {
      label: 'Siblings Sister Married',
      value: siblings_sister_married || '----',
    },
    { label: 'Family Type', value: family_type || '----' },
    { label: 'City', value: cityById || '----' },
    { label: 'Family Values', value: family_values || '----' },
    { label: 'Country', value: countryById || '----' },
    { label: 'State', value: stateById || '----' },
    { label: 'Pincode', value: family_pincode || '----' },
  ];
  return (
    <div className='flex flex-col justify-center items-center w-full md:w-[49%] lg:w-[48%] bg-white shadow-lg py-6 rounded-lg mb-6'>
      <p className='text-left w-full px-6 text-[#333333] font-bold mb-6 text-[20px]'>
        Family Information
      </p>
      <ul className='w-full'>
        {familyData.map((item, index) => (
          <li
            key={index}
            className='flex justify-between items-start mb-1 bg-[#FFC0CB22] px-6 py-1'
          >
            <span className='font-medium text-[#555555] text-[16px]'>
              {item.label}:
            </span>
            <span className='text-[#616161] font-normal text-[16px]'>
              {item.value}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UserFamilyData;

// const UserFamilyData = () => {
//   return (
//     <div className='flex flex-col justify-center items-center w-full lg:w-[49%]'>
//       <div className='flex flex-col justify-center items-center md:items-start py-10 w-full'>
//         <p className='text-[#333333] font-bold mb-4 text-[20px]'>
//           Family Information
//         </p>
//         <ul className='flex flex-wrap justify-between items-start mt-2 w-full'>
//           <li className='flex justify-start items-start w-full md:w-[49%] my-2'>
//             <span className='font-medium text-[#555555] text-[16px] mx-2 w-[40%]'>
//               Father Name:
//             </span>
//             <span className='text-[#616161] font-normal text-[16px] ml-2 w-[60%]'>
//               Full Name
//             </span>
//           </li>
//           <li className='flex justify-start items-start w-full md:w-[49%] my-2'>
//             <span className='font-medium text-[#555555] text-[16px] mx-2 w-[40%]'>
//               Mother Name:
//             </span>
//             <span className='text-[#616161] font-normal text-[16px] ml-2 w-[60%]'>
//               Full Name
//             </span>
//           </li>
//           <li className='flex justify-start items-start w-full md:w-[49%] my-2'>
//             <span className='font-medium text-[#555555] text-[16px] mx-2 w-[40%]'>
//               Father's Status:
//             </span>
//             <span className='text-[#616161] font-normal text-[16px] ml-2 w-[60%]'>
//               Employed
//             </span>
//           </li>
//           <li className='flex justify-start items-start w-full md:w-[49%] my-2'>
//             <span className='font-medium text-[#555555] text-[16px] mx-2 w-[40%]'>
//               Mother's Status:
//             </span>
//             <span className='text-[#616161] font-normal text-[16px] ml-2 w-[60%]'>
//               Business Owner
//             </span>
//           </li>
//           <li className='flex justify-start items-start w-full md:w-[49%] my-2'>
//             <span className='font-medium text-[#555555] text-[16px] mx-2 w-[40%]'>
//               No of Siblings:
//             </span>
//             <span className='text-[#616161] font-normal text-[16px] ml-2 w-[60%]'>
//               2
//             </span>
//           </li>
//           <li className='flex justify-start items-start w-full md:w-[49%] my-2'>
//             <span className='font-medium text-[#555555] text-[16px] mx-2 w-[40%]'>
//               Family Type:
//             </span>
//             <span className='text-[#616161] font-normal text-[16px] ml-2 w-[60%]'>
//               Joint Family
//             </span>
//           </li>
//           <li className='flex justify-start items-start w-full md:w-[49%] my-2'>
//             <span className='font-medium text-[#555555] text-[16px] mx-2 w-[40%]'>
//               City:
//             </span>
//             <span className='text-[#616161] font-normal text-[16px] ml-2 w-[60%]'>
//               Bhopal
//             </span>
//           </li>
//           <li className='flex justify-start items-start w-full md:w-[49%] my-2'>
//             <span className='font-medium text-[#555555] text-[16px] mx-2 w-[40%]'>
//               Family Values:
//             </span>
//             <span className='text-[#616161] font-normal text-[16px] ml-2 w-[60%]'>
//               Supportive
//             </span>
//           </li>
//           <li className='flex justify-start items-start w-full md:w-[49%] my-2'>
//             <span className='font-medium text-[#555555] text-[16px] mx-2 w-[40%]'>
//               Country:
//             </span>
//             <span className='text-[#616161] font-normal text-[16px] ml-2 w-[60%]'>
//               India
//             </span>
//           </li>
//           <li className='flex justify-start items-start w-full md:w-[49%] my-2'>
//             <span className='font-medium text-[#555555] text-[16px] mx-2 w-[40%]'>
//               State:
//             </span>
//             <span className='text-[#616161] font-normal text-[16px] ml-2 w-[60%]'>
//               Madhya Pradesh
//             </span>
//           </li>
//           <li className='flex justify-start items-start w-full md:w-[49%] my-2'>
//             <span className='font-medium text-[#555555] text-[16px] mx-2 w-[40%]'>
//               Pincode:
//             </span>
//             <span className='text-[#616161] font-normal text-[16px] ml-2 w-[60%]'>
//               462026
//             </span>
//           </li>
//         </ul>
//       </div>
//     </div>
//   );
// };

// export default UserFamilyData;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import Button from '../../../../components/UI/Button/Button';
import EditProfileLayout from './EditProfileLayout';
// import { AiOutlineLoading } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserAboutMoreProfile } from '../../../../redux/features/userSlice';
import { FaCheckCircle } from 'react-icons/fa';
import {
  addressWords,
  dummyAboutFamily,
  dummyAboutMySelf,
  emailWords,
  numberWords,
} from '../../../../constants';

// const dummyAboutMySelf = `I am a [qualification-type] who enjoys living a balanced and meaningful life. I was born in [PlaceofBirth] and believe in staying healthy and active. My hobbies include [Hobbies], which help to relax and express myself.`;

// const dummyAboutFamily = `My family is my biggest support system. We follow [Family_Values] that keep our bond close and connected. We believe in helping each other. We come from a [Family_Type] and value love, respect, and happiness in our home.`;

const replacePlaceholders = (template, values) => {
  return template.replace(
    /\[([^\]]+)\]/g,
    (_, key) => values[key] || `[${key}]`
  );
};

const AboutMore = (props) => {
  const dispatch = useDispatch();

  const qualificationTypeData = useSelector(
    (state) => state.field.qualificationType
  );

  const additionalDetails = useSelector(
    (state) => state.user.additionalDetails
  );

  const {
    first_name,
    last_name,
    father_name,
    mother_name,
    about_myself,
    about_my_family,
    qualification_type,
    place_of_birth,
    hobbies,
    family_values,
    family_type,
  } = additionalDetails || {};

  // console.log(additionalDetails);

  const selectedQualificationType =
    qualificationTypeData && qualificationTypeData.length > 0
      ? qualificationTypeData.find(
          (quaTypObj) => quaTypObj.short_code === qualification_type
        )?.name || ''
      : '';

  let hobbiesArray = hobbies && JSON.parse(hobbies);

  // console.log(hobbiesArray);

  const aboutMyselfTemplate = replacePlaceholders(dummyAboutMySelf, {
    'qualification-type': selectedQualificationType,
    PlaceofBirth: place_of_birth,
    Hobbies: hobbiesArray?.join(', '),
  });

  const aboutMyFamilyTemplate = replacePlaceholders(dummyAboutFamily, {
    Family_Values: family_values,
    Family_Type: family_type,
  });

  // console.log(aboutMyFamilyTemplate);

  // console.log('family_type: ', family_type);
  // console.log('family_values: ', family_values);

  const firstName = first_name?.trim() || null;
  const lastName = last_name?.trim() || null;
  const fatherName = father_name?.trim() || null;
  const motherName = mother_name?.trim() || null;

  const [saveSuccess, setSaveSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // const [formDataLettersCount, setFormDataLettersCount] = useState({
  //   aboutYourselfText: 0,
  //   aboutYourFamilyText: 0,
  // });

  const [formDataLettersCount, setFormDataLettersCount] = useState({
    aboutYourselfText: about_myself
      ? about_myself.length
      : dummyAboutMySelf.length,
    aboutYourFamilyText: about_my_family
      ? about_my_family.length
      : dummyAboutFamily.length,
  });

  const [formData, setFormData] = useState({
    aboutYourselfText: about_myself || aboutMyselfTemplate,
    aboutYourFamilyText: about_my_family || aboutMyFamilyTemplate,
  });

  const [formErrors, setFormErrors] = useState({
    aboutYourselfText: '',
    aboutYourFamilyText: '',
  });

  // useEffect(() => {
  //   console.log(about_myself, about_my_family);
  //   // setFormData((prevData) => ({
  //   //   ...prevData,
  //   //   aboutYourFamilyText: about_my_family,
  //   // }));
  // }, [about_myself, about_my_family]);

  // console.log(formData);

  const [touched, setTouched] = useState({
    aboutYourselfText: false,
    aboutYourFamilyText: false,
  });

  const maxLetters = 250;

  let forbiddenNames = [];
  [firstName, lastName, fatherName, motherName].forEach((name) => {
    if (name) {
      forbiddenNames = forbiddenNames.concat(name.split(' '));
    }
  });
  // console.log(forbiddenNames);

  const createForbiddenWordsRegex = (forbiddenWords) => {
    const pattern = `\\b(${forbiddenWords.join('|')})\\b`;
    return new RegExp(pattern, 'i');
  };

  const numberWordsRegex = createForbiddenWordsRegex(numberWords);
  const emailWordsRegex = createForbiddenWordsRegex(emailWords);
  const addressWordsRegex = createForbiddenWordsRegex(addressWords);
  const forbiddenNamesRegex = createForbiddenWordsRegex(forbiddenNames);

  // useEffect(() => {
  //   if (touched.aboutYourselfText) {
  //     if (
  //       (formData.aboutYourselfText &&
  //         /[^a-zA-Z\s]/.test(formData.aboutYourselfText)) ||
  //       numberWordsRegex.test(formData.aboutYourselfText)
  //     ) {
  //       setFormErrors((prevErrors) => ({
  //         ...prevErrors,
  //         aboutYourselfText:
  //           'Any Types Numbers or special characters are not allowed!',
  //       }));
  //     } else if (
  //       formData.aboutYourselfText &&
  //       emailWordsRegex.test(formData.aboutYourselfText)
  //     ) {
  //       setFormErrors((prevErrors) => ({
  //         ...prevErrors,
  //         aboutYourselfText: 'Email-related words are not allowed!',
  //       }));
  //     } else if (
  //       formData.aboutYourselfText &&
  //       addressWordsRegex.test(formData.aboutYourselfText)
  //     ) {
  //       setFormErrors((prevErrors) => ({
  //         ...prevErrors,
  //         aboutYourselfText: 'Address-related words are not allowed!',
  //       }));
  //     } else if (
  //       formData.aboutYourselfText &&
  //       forbiddenNamesRegex.test(formData.aboutYourselfText)
  //     ) {
  //       setFormErrors((prevErrors) => ({
  //         ...prevErrors,
  //         aboutYourselfText: 'Forbidden names are not allowed!',
  //       }));
  //     } else {
  //       setFormErrors((prevErrors) => ({
  //         ...prevErrors,
  //         aboutYourselfText: '',
  //       }));
  //     }
  //   }
  // }, [formData.aboutYourselfText, touched.aboutYourselfText]);

  useEffect(() => {
    if (touched.aboutYourselfText) {
      const { aboutYourselfText } = formData;

      // Helper function to find the exact word causing the error
      const findInvalidWord = (text, regex) => {
        const match = text.match(regex);
        return match ? match[0] : null;
      };

      // Validate each type of restriction with detailed word-based messages
      if (aboutYourselfText && /[^a-zA-Z\s[],.]/.test(aboutYourselfText)) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          aboutYourselfText: `Special characters are not allowed! (Found: ${findInvalidWord(
            aboutYourselfText,
            /[^a-zA-Z\s,.]/
          )})`,
        }));
      } else if (
        aboutYourselfText &&
        numberWordsRegex.test(aboutYourselfText)
      ) {
        const word = findInvalidWord(aboutYourselfText, numberWordsRegex);
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          aboutYourselfText: `Numbers are not allowed! (Found: ${word})`,
        }));
      } else if (aboutYourselfText && emailWordsRegex.test(aboutYourselfText)) {
        const word = findInvalidWord(aboutYourselfText, emailWordsRegex);
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          aboutYourselfText: `Email-related words are not allowed! (Found: ${word})`,
        }));
      } else if (
        aboutYourselfText &&
        addressWordsRegex.test(aboutYourselfText)
      ) {
        const word = findInvalidWord(aboutYourselfText, addressWordsRegex);
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          aboutYourselfText: `Address-related words are not allowed! (Found: ${word})`,
        }));
      } else if (
        aboutYourselfText &&
        forbiddenNamesRegex.test(aboutYourselfText)
      ) {
        const word = findInvalidWord(aboutYourselfText, forbiddenNamesRegex);
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          aboutYourselfText: `Forbidden names are not allowed! (Found: ${word})`,
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          aboutYourselfText: '',
        }));
      }
    }
  }, [formData.aboutYourselfText, touched.aboutYourselfText]);

  useEffect(() => {
    if (touched.aboutYourFamilyText) {
      const { aboutYourFamilyText } = formData;

      const findInvalidWord = (text, regex) => {
        console.log(text, regex);

        const match = text.match(regex);
        console.log('match: ', match);

        return match ? match[0] : 'No invalid word found';
      };

      if (aboutYourFamilyText && /[^a-zA-Z\s[],.]/.test(aboutYourFamilyText)) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          aboutYourFamilyText: `Special characters are not allowed! (Found: ${findInvalidWord(
            aboutYourFamilyText,
            /[^a-zA-Z\s,.]/
          )})`,
        }));
      } else if (
        aboutYourFamilyText &&
        numberWordsRegex.test(aboutYourFamilyText)
      ) {
        const word = findInvalidWord(aboutYourFamilyText, numberWordsRegex);
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          aboutYourFamilyText: `Numbers are not allowed! (Found: ${word})`,
        }));
      } else if (
        aboutYourFamilyText &&
        emailWordsRegex.test(aboutYourFamilyText)
      ) {
        const word = findInvalidWord(aboutYourFamilyText, emailWordsRegex);
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          aboutYourFamilyText: `Email-related words are not allowed! (Found: ${word})`,
        }));
      } else if (
        aboutYourFamilyText &&
        addressWordsRegex.test(aboutYourFamilyText)
      ) {
        const word = findInvalidWord(aboutYourFamilyText, addressWordsRegex);
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          aboutYourFamilyText: `Address-related words are not allowed! (Found: ${word})`,
        }));
      } else if (
        aboutYourFamilyText &&
        forbiddenNamesRegex.test(aboutYourFamilyText)
      ) {
        const word = findInvalidWord(aboutYourFamilyText, forbiddenNamesRegex);
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          aboutYourFamilyText: `Forbidden names are not allowed! (Found: ${word})`,
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          aboutYourFamilyText: '',
        }));
      }
    }
  }, [formData.aboutYourFamilyText, touched.aboutYourFamilyText]);

  const handleChange = (e) => {
    const { id, value } = e.target;

    const sanitizedValue = value.trim();
    // const sanitizedValue = value.replace(/\[.*?\]/g, '').trim();
    // console.log(sanitizedValue);

    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));

    const letterCount = sanitizedValue.length;

    console.log(letterCount);

    setFormDataLettersCount((prevData) => ({
      ...prevData,
      [id]: letterCount,
    }));
    setTouched((prevTouched) => ({
      ...prevTouched,
      [id]: true,
    }));
  };

  const validateInputs = () => {
    let valid = true;
    const errors = {};

    if (!formData.aboutYourselfText.trim()) {
      errors.aboutYourselfText = 'About Yourself is required.';
      valid = false;
    }

    if (!formData.aboutYourFamilyText.trim()) {
      errors.aboutYourFamilyText = 'About Your Family is required.';
      valid = false;
    }

    setFormErrors(errors);
    console.log(setFormErrors);
    return valid;
  };

  const handleOnSave = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError(null);

    if (!validateInputs()) {
      setIsLoading(false);
      return;
    }

    try {
      const formDataToSubmit = {
        about_myself: formData.aboutYourselfText,
        about_my_family: formData.aboutYourFamilyText,
      };
      console.log(formData);
      await dispatch(updateUserAboutMoreProfile(formDataToSubmit));
      setSaveSuccess(true);
      // props.onShowEditSuccess();
    } catch (err) {
      setError(err.message);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <EditProfileLayout onShowSignUp={props.onShowSignUp} editPage='aboutmore'>
      <div className='flex flex-col justify-center items-center w-full h-full'>
        <div className='flex justify-start items-center w-full font-bold text-[18px] text-[#FF4061]'>
          About More
        </div>
        <div className='flex flex-col md:flex-row justify-center md:justify-between items-center my-2 w-full'>
          <div className='flex flex-col justify-center items-start w-full'>
            <div className='flex justify-between items-center w-full'>
              <label
                htmlFor='aboutYourselfText'
                className='text-[#333333] font-medium mb-1'
              >
                About Yourself:
              </label>
              <span className='text-[14px] text-[#333333]'>
                {formDataLettersCount.aboutYourselfText}/{maxLetters}
              </span>
            </div>
            <textarea
              id='aboutYourselfText'
              placeholder='Write about yourself'
              className='focus:outline-[#66B6FF] text-[#858585] border-[2px] border-[#858585] text-[14px] lg:text-16px px-2 py-2 w-full rounded-md min-h-[120px]'
              // onChange={handleAboutYourselfChange}
              onChange={handleChange}
              maxLength={maxLetters}
              value={formData.aboutYourselfText || ''}
            />
            {formErrors.aboutYourselfText && (
              <p className='text-[14px] text-[#FF4061] font-medium'>
                {formErrors.aboutYourselfText}
              </p>
            )}
          </div>
        </div>
        <div className='flex flex-col md:flex-row justify-center md:justify-between items-center my-2 w-full'>
          <div className='flex flex-col justify-center items-start w-full'>
            <div className='flex justify-between items-center w-full'>
              <label
                htmlFor='aboutYourFamilyText'
                className='text-[#333333] font-medium mb-1'
              >
                About Your Family:
              </label>
              <span className='text-[14px] text-[#333333]'>
                {formDataLettersCount.aboutYourFamilyText}/{maxLetters}
              </span>
            </div>
            <textarea
              id='aboutYourFamilyText'
              placeholder='Write about your family'
              className='focus:outline-[#66B6FF] text-[#858585] border-[2px] border-[#858585] text-[14px] lg:text-16px px-2 py-2 w-full rounded-md min-h-[120px]'
              // onChange={handleAboutYourFamilyChange}
              onChange={handleChange}
              maxLength={maxLetters}
              value={formData.aboutYourFamilyText || ''}
            />
            {formErrors.aboutYourFamilyText && (
              <p className='text-[14px] text-[#FF4061] font-medium'>
                {formErrors.aboutYourFamilyText}
              </p>
            )}
          </div>
        </div>
        <div className='flex justify-start items-center my-5 w-full'>
          <Button
            onButtonClick={handleOnSave}
            text={isLoading ? 'Loading...' : 'Save'}
            className='font-bold w-[130px] h-[50px]'
            disabled={isLoading}
          >
            {isLoading && (
              <div className='flex justify-center items-center bg-white rounded-full ml-2'>
                <svg
                  className='animate-spin h-6 w-6 text-pink-500'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                >
                  <circle
                    className='opacity-25'
                    cx='12'
                    cy='12'
                    r='10'
                    stroke='currentColor'
                    strokeWidth='4'
                  ></circle>
                  <path
                    className='opacity-75'
                    fill='currentColor'
                    d='M4 12a8 8 0 018-8v8H4z'
                  ></path>
                </svg>
              </div>
            )}
          </Button>
          {saveSuccess && (
            <span className='flex justify-center items-center font-semibold text-[#333]'>
              <FaCheckCircle className='mr-2 text-[20px] text-[#4CAF50] ml-2' />
              saved
            </span>
          )}
        </div>
      </div>
    </EditProfileLayout>
  );
};

export default AboutMore;

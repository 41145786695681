import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
// import { useEffect } from 'react';
// import Cookies from 'js-cookie';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const fetchUsers = createAsyncThunk('user/fetchUsers', async () => {
  const response = await axios.get(BACKEND_URL + '');
  return response.data;
});

export const getUserProfile = createAsyncThunk(
  'user/getProfile',
  async (_, { dispatch }) => {
    // console.log('mohit profile');

    try {
      const token = localStorage.getItem('auth_token');
      // console.log('token: ', token);
      //##### changed 26/11 if(token condition)
      // if (token && token !== 'undefined') {
      const userresponse = await axios.get(BACKEND_URL + '/api/profile', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      });
      // console.log('User data user:', userresponse.data.user);
      const user = userresponse.data.user;
      if (user) {
        //redirect to dashboar
      }
      return user;
      // }
      // dispatch(setUser(user));
    } catch (error) {
      // console.error('Error fetching user profile:', error);
      throw error;
    }
  }
);

export const getUserFullDetails = createAsyncThunk(
  'user/getUserFullDetails',
  async (profile_id, { rejectWithValue }) => {
    // console.log('profile_id: ', profile_id);
    try {
      const token = localStorage.getItem('auth_token');
      // console.log('token: ', token);
      if (token && token !== 'undefined') {
        const userFullDetailsResponse = await axios.get(
          BACKEND_URL + `/api/user/profile/${profile_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        );
        // console.log('getUserFullDetails:', userFullDetailsResponse.data);
        // const userFullDetails = userFullDetailsResponse.data;

        // return userFullDetails;
        return userFullDetailsResponse;
      }
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error);
    }
  }
);

export const getCompareUserDetails = createAsyncThunk(
  'user/getCompareUserDetails',
  async (profile_id, { dispatch }) => {
    // console.log('profile_id: ', profile_id);
    try {
      const token = localStorage.getItem('auth_token');
      // console.log('token: ', token);
      if (token && token !== 'undefined') {
        const userFullDetailsResponse = await axios.get(
          BACKEND_URL + `/api/compare-user/${profile_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        );
        // console.log('getCompareUserDetails:', userFullDetailsResponse);
        // const userCompareDetails = userFullDetailsResponse.data;
        // return userCompareDetails;
        return userFullDetailsResponse;
      }
    } catch (error) {
      // console.error('Error fetching user profile:', error);
      throw error;
    }
  }
);

export const loginUser = createAsyncThunk(
  'user/loginUser',
  async (data, { dispatch }) => {
    const csrfRes = await axios.get(BACKEND_URL + '/sanctum/csrf-cookie', {
      withCredentials: true,
    });
    // console.log('csrfRes: ', csrfRes);

    try {
      const response = await axios.post(BACKEND_URL + '/login', data, {
        withCredentials: true,
      });
      // console.logcls('response: ', response);

      const token = response?.data?.token;
      if (token) {
        // console.log('Login response token:', token);
        localStorage.setItem('auth_token', token);
      }
      await dispatch(getUserProfile()).unwrap();
      return response;
    } catch (error) {
      // console.log(error.response.data);
      throw error.response.data;
    }
  }
);

export const forgotPassword = createAsyncThunk(
  'user/loginUser',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        BACKEND_URL + '/forgot-password',
        data,
        {
          withCredentials: true,
        }
      );
      // console.log('response: ', response);

      return response;
    } catch (error) {
      // console.log(error.response.data);
      // throw error.response.data;
      return rejectWithValue(error.response.data);
    }
  }
);

export const resetPassword = createAsyncThunk(
  'user/loginUser',
  async (data, { rejectWithValue }) => {
    console.log(data);

    try {
      const response = await axios.post(BACKEND_URL + '/reset-password', data, {
        withCredentials: true,
      });

      return response;
    } catch (error) {
      console.log(error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);

export const logoutUser = createAsyncThunk(
  'user/logoutUser',
  async (_, { dispatch }) => {
    // console.log('logout slice i working');

    try {
      const token = localStorage.getItem('auth_token');
      // if (token) {
      // if (token && token !== 'undefined') {
      // console.log('Token:', token);
      const logoutResponse = await axios.post(
        BACKEND_URL + '/logout',
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        }
      );
      //###[25 Nov 2024]: we changed this logut functionality logic to remove token from local storage and checks status code for 200 & 419 to handle that error and response.

      const status = logoutResponse.status;
      console.log(status, status === 200);

      if (status === 200) {
        localStorage.removeItem('auth_token');
        // props.onShowSuccessMessage('Successfully logged out.');
        // navigate('/');
        window.location.reload(true);
      }

      // if()

      // console.log('Logout Response:', logoutResponse);
      // console.log('Successfully logged out');
      return logoutResponse;
      // }
    } catch (error) {
      const status = error.response?.status;

      console.error('Logout Error:', error.response?.data || error.message);

      //26/11 Added another status condition 401
      if (status === 419 || status === 401) {
        // Print the auth_token if status is 419
        const authToken = localStorage.getItem('auth_token');
        localStorage.removeItem('auth_token');
        console.log('Auth Token:', authToken);
        window.location.reload(true);
      }
    }
  }
);

export const signupUser = createAsyncThunk(
  'user/signupUser',
  async (userData, { dispatch, rejectWithValue }) => {
    await axios.get(BACKEND_URL + '/sanctum/csrf-cookie', {
      withCredentials: true,
    });

    // const getData = async () => {
    //   const res = await axios.get('https://api.ipify.org/?format=json');
    //   console.log(res.data);
    //   // setIP(res.data.ip);
    // };

    // useEffect(() => {
    //passing getData method to the lifecycle method
    // getData();
    // }, []);

    // let server_p = await getIP();
    // console.log('server_p:', server_p);

    // console.log('userData: ', userData);
    let server_ip = '123.123.123.123';
    // console.log(server_ip);

    userData = { ...userData, server_ip };

    // console.log('userData: ', userData);
    try {
      const regResponse = await axios.post(
        BACKEND_URL + '/register',
        userData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true, // Automatically include cookies
        }
      );

      // console.log(regResponse.data);
      if (regResponse.status === 200) {
        // console.log('Registration successful');
      }
      localStorage.setItem('auth_token', regResponse?.data?.token);
      const userProfile = await dispatch(getUserProfile()).unwrap();
      return userProfile;
    } catch (error) {
      // console.error('Signup error:', error);
      return rejectWithValue(error.response?.data || 'Signup failed');
    }
  }
);

// const getIP = async () => {
//   // console.log("IP URL:", process.env.REACT_APP_IP_URL);
//   const IP_URL = 'https://api.ipify.org?format=json';
//   // const IP_URL = process.env.REACT_APP_IP_URL;
//   try {
//     const response = await axios.get(IP_URL);
//     const data = response.data.ip;
//     console.log('ip: ', data);

//     return data;
//   } catch (error) {
//     console.log(error);
//   }
// };

// ####################################

export const updateUserPersonalProfile = createAsyncThunk(
  'user/updateUserPersonalProfile',
  async (data, { dispatch }) => {
    // console.log(data);

    // await axios.get(BACKEND_URL + '/sanctum/csrf-cookie', {
    //   withCredentials: true,
    // });

    try {
      const token = localStorage.getItem('auth_token');
      if (token && token !== 'undefined') {
        console.log('token: ', token);
        const updateResponse = await axios.post(
          BACKEND_URL + '/update-user-step1',
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        );
        console.log('updated data:', updateResponse.data.updated_fields);
        const updatedData = updateResponse.data.updated_fields;
        return updatedData;
      }
      // dispatch(setUser(user));
    } catch (error) {
      console.error('Error update profile:', error);
      throw error;
    }
  }
);

export const updateUserReligiousProfile = createAsyncThunk(
  'user/updateUserReligiousProfile',
  async (data, { dispatch }) => {
    // console.log(data);

    try {
      const token = localStorage.getItem('auth_token');
      console.log('token: ', token);
      if (token && token !== 'undefined') {
        const updateResponse = await axios.post(
          BACKEND_URL + '/update-user-step2',
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        );
        console.log('updated data:', updateResponse.data.updated_fields);
        const updatedData = updateResponse.data.updated_fields;
        return updatedData;
      }
      // dispatch(setUser(user));
    } catch (error) {
      console.error('Error update profile:', error);
      throw error;
    }
  }
);

export const updateUserFamilyProfile = createAsyncThunk(
  'user/updateUserFamilyProfile',
  async (data, { dispatch }) => {
    // console.log(data);

    try {
      const token = localStorage.getItem('auth_token');
      // console.log('token: ', token);
      if (token && token !== 'undefined') {
        const updateResponse = await axios.post(
          BACKEND_URL + '/update-user-step3',
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        );
        console.log('updated data:', updateResponse.data.updated_fields);
        const updatedData = updateResponse.data.updated_fields;
        return updatedData;
      }
      // dispatch(setUser(user));
    } catch (error) {
      console.error('Error Update profile:', error);
      throw error;
    }
  }
);

export const updateUserEducationProfile = createAsyncThunk(
  'user/updateUserEducationProfile',
  async (data, { dispatch }) => {
    // console.log(data);

    try {
      const token = localStorage.getItem('auth_token');
      // console.log('token: ', token);
      if (token && token !== 'undefined') {
        const updateResponse = await axios.post(
          BACKEND_URL + '/update-user-step4',
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        );
        console.log('updated data:', updateResponse.data.updated_fields);
        const updatedData = updateResponse.data.updated_fields;
        return updatedData;
      }
      // dispatch(setUser(user));
    } catch (error) {
      console.error('Error Update profile:', error);
      throw error;
    }
  }
);

export const updateUserAddressEditProfile = createAsyncThunk(
  'user/updateUserAddressEditProfile',
  async (data, { dispatch }) => {
    // console.log(data);

    try {
      const token = localStorage.getItem('auth_token');
      // console.log('token: ', token);
      if (token && token !== 'undefined') {
        const updateResponse = await axios.post(
          BACKEND_URL + '/update-user-step5',
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        );
        console.log('updated data:', updateResponse.data.updated_fields);
        const updatedData = updateResponse.data.updated_fields;
        return updatedData;
      }
      // dispatch(setUser(user));
    } catch (error) {
      console.error('Error Update profile:', error);
      throw error;
    }
  }
);

export const updateUserHoroscopeEditProfile = createAsyncThunk(
  'user/updateUserHoroscopeEditProfile',
  async (data, { dispatch }) => {
    // console.log(data);

    try {
      const token = localStorage.getItem('auth_token');
      console.log('token: ', token);
      if (token && token !== 'undefined') {
        const updateResponse = await axios.post(
          BACKEND_URL + '/update-user-step6',
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        );
        console.log('updated data:', updateResponse.data);
        // console.log('updated data:', updateResponse.data.updated_fields);
        const updatedData = updateResponse.data.updated_fields;
        return updatedData;
      }
      // dispatch(setUser(user));
    } catch (error) {
      console.error('Error Update profile:', error);
      throw error;
    }
  }
);

export const updateUserAboutMoreProfile = createAsyncThunk(
  'user/updateUserAboutMoreProfile',
  async (data, { dispatch }) => {
    // console.log(data);

    try {
      const token = localStorage.getItem('auth_token');
      // console.log('token: ', token);
      if (token && token !== 'undefined') {
        const updateResponse = await axios.post(
          BACKEND_URL + '/update-user-step7',
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        );
        console.log('updated data:', updateResponse.data.updated_fields);
        const updatedData = updateResponse.data.updated_fields;
        return updatedData;
      }
      // dispatch(setUser(user));
    } catch (error) {
      console.error('Error Update profile:', error);
      throw error;
    }
  }
);

export const updateUserMatchPreferenceProfile = createAsyncThunk(
  'user/updateUserMatchPreferenceProfile',
  async (data, { dispatch }) => {
    // console.log(data);

    try {
      const token = localStorage.getItem('auth_token');
      // console.log('token: ', token);
      if (token && token !== 'undefined') {
        const updateResponse = await axios.post(
          BACKEND_URL + '/update-user-step8',
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        );
        console.log('updated data:', updateResponse.data.updated_fields);
        const updatedData = updateResponse.data.updated_fields;
        return updatedData;
      }
      // dispatch(setUser(user));
    } catch (error) {
      console.error('Error Update profile:', error);
      throw error;
    }
  }
);

// ################################## MATCHING PROFILES

export const getMatchingProfiles = createAsyncThunk(
  'user/getMatchingProfiles',
  async (_, { dispatch }) => {
    try {
      const token = localStorage.getItem('auth_token');
      // console.log('token: ', token);
      if (token && token !== 'undefined') {
        const profileResponse = await axios.get(
          BACKEND_URL + '/api/getMatchingProfiles',
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        );
        // console.log('User data user:', userresponse.data.user);
        const matchingProfiles = profileResponse.data;
        // console.log('matchingProfiles: ', matchingProfiles);

        // const user = userresponse.data.user;
        // if (user) {
        //redirect to dashboar
        // }
        return matchingProfiles;
      }
      // dispatch(setUser(user));
    } catch (error) {
      console.error('Error fetching Matching Profiles:', error);
      throw error;
    }
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState: {
    users: [],
    status: 'idle',
    userInfo: null,
    matchingProfile: [],
    additionalDetails: null,
    userFullDetail: null,
    compareUserDetails: null,
    error: null,
    loading: false,
    loginLoading: false,
    signUpLoading: false,
    getUserDetailError: null,
    loginError: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.userInfo = action.payload;
    },
    logout: (state) => {
      state.userInfo = null;
      state.status = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.users = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      // Handle loginUser
      .addCase(loginUser.pending, (state) => {
        state.loginLoading = true;
        state.status = 'logging in';
      })
      .addCase(loginUser.fulfilled, (state) => {
        state.status = 'logged in';
        // state.userInfo = action.payload; // userInfo with response data

        state.loginLoading = false;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = 'login failed';
        // console.log(action);
        // console.log(action?.error?.message);

        if (action?.error?.message) {
          state.loginError = action.error.message;
        }

        state.loginLoading = false;
      })
      // Handle logoutUser
      .addCase(logoutUser.pending, (state) => {
        state.loading = true;
        state.status = 'logout pending';
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.status = 'logged out';
        state.userInfo = null; // Clear user info on logout
        state.loading = false;
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.status = 'logout failed'; // Status for failed logout
        state.error = action.error.message;
        state.loading = false;
      })
      // Handle getUserProfile
      .addCase(getUserProfile.pending, (state, action) => {
        state.loading = true;
        state.userInfo = action.payload; // Set userInfo with profile data
      })
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.userInfo = action.payload;
        state.additionalDetails = action.payload?.additional_details;
        state.loading = false;
      })
      .addCase(getUserProfile.rejected, (state, action) => {
        state.userInfo = action.payload; // Set userInfo with profile data
        state.loading = false;
      })
      // Handle getUserFullDetails
      .addCase(getUserFullDetails.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getUserFullDetails.fulfilled, (state, action) => {
        state.userFullDetail = action.payload.data.data;
        state.loading = false;
      })
      // Handle getMatchingProfiles
      .addCase(getMatchingProfiles.pending, (state, action) => {
        state.loading = true;
        // state.userInfo = action.payload;
      })
      .addCase(getMatchingProfiles.fulfilled, (state, action) => {
        // console.log(action.payload.matching_profile_dashboard);
        state.matchingProfile = action.payload.matching_profile_dashboard;
        state.loading = false;
      })
      .addCase(getMatchingProfiles.rejected, (state, action) => {
        // state.userInfo = action.payload;
        state.loading = false;
      })
      // Handle getCompareUserDetails
      .addCase(getCompareUserDetails.pending, (state, action) => {
        state.loading = true;
        // state.userInfo = action.payload;
      })
      .addCase(getCompareUserDetails.fulfilled, (state, action) => {
        // console.log(action.payload);
        state.compareUserDetails = action.payload.data.comparison;
        state.loading = false;
      })
      .addCase(getCompareUserDetails.rejected, (state, action) => {
        // state.userInfo = action.payload;
        state.loading = false;
      })
      // Handle signupUser
      .addCase(signupUser.pending, (state) => {
        state.signUpLoading = true;
        state.status = 'signing up';
      })
      .addCase(signupUser.fulfilled, (state, action) => {
        state.status = 'sign up successful';
        state.userInfo = action.payload;
        state.signUpLoading = false;
        // Optional: Handle user info or redirect after sign up
      })
      .addCase(signupUser.rejected, (state, action) => {
        state.status = 'sign up failed';
        state.error = action.payload; // Use rejectWithValue for error
        state.signUpLoading = false;
      })
      // Update User Education Profile
      .addCase(updateUserPersonalProfile.pending, (state) => {
        state.signUpLoading = true;
        state.status = 'profile updating';
      })
      .addCase(updateUserPersonalProfile.fulfilled, (state, action) => {
        state.status = 'profile updated';
        state.additionalDetails = action.payload;
        state.userInfo = { ...state.userInfo, ...action.payload };
        state.signUpLoading = false;
        // Optional: Handle user info or redirect after sign up
      })
      .addCase(updateUserPersonalProfile.rejected, (state, action) => {
        state.status = 'profile update failed';
        state.signUpLoading = false;
      })
      // Update User Religious Profile
      .addCase(updateUserReligiousProfile.pending, (state) => {
        state.signUpLoading = true;
        state.status = 'profile updating';
      })
      .addCase(updateUserReligiousProfile.fulfilled, (state, action) => {
        state.status = 'profile updated';
        state.additionalDetails = action.payload;
        state.signUpLoading = false;
        // Optional: Handle user info or redirect after sign up
      })
      .addCase(updateUserReligiousProfile.rejected, (state, action) => {
        state.status = 'profile update failed';

        state.signUpLoading = false;
      })
      // Update User Family Profile
      .addCase(updateUserFamilyProfile.pending, (state) => {
        state.signUpLoading = true;
        state.status = 'profile updating';
      })
      .addCase(updateUserFamilyProfile.fulfilled, (state, action) => {
        state.status = 'profile updated';
        state.additionalDetails = action.payload;
        // state.userInfo = {
        //   ...state.userInfo,
        //   additional_details: {
        //     ...state.userInfo?.additional_details,
        //     ...action.payload.additional_details, // Merge any nested additional details
        //   },
        // };
        state.signUpLoading = false;
        // Optional: Handle user info or redirect after sign up
      })
      .addCase(updateUserFamilyProfile.rejected, (state, action) => {
        state.status = 'profile update failed';

        state.signUpLoading = false;
      })
      // Update User Education Profile
      .addCase(updateUserEducationProfile.pending, (state) => {
        state.signUpLoading = true;
        state.status = 'profile updating';
      })
      .addCase(updateUserEducationProfile.fulfilled, (state, action) => {
        state.status = 'profile updated';
        state.additionalDetails = action.payload;
        // state.userInfo = {
        //   ...state.userInfo,
        //   additional_details: {
        //     ...state.userInfo?.additional_details,
        //     ...action.payload.additional_details, // Merge any nested additional details
        //   },
        // };
        state.signUpLoading = false;
        console.log(state.userInfo);

        // Optional: Handle user info or redirect after sign up
      })
      .addCase(updateUserEducationProfile.rejected, (state, action) => {
        state.status = 'profile update failed';

        state.signUpLoading = false;
      })
      // Update User Address Profile
      .addCase(updateUserAddressEditProfile.pending, (state) => {
        state.signUpLoading = true;
        state.status = 'profile updating';
      })
      .addCase(updateUserAddressEditProfile.fulfilled, (state, action) => {
        state.status = 'profile updated';
        state.additionalDetails = action.payload;

        // state.userInfo = {
        //   ...state.userInfo,
        //   additional_details: {
        //     ...state.userInfo?.additional_details,
        //     ...action.payload.additional_details, // Merge any nested additional details
        //   },
        // };
        state.signUpLoading = false;
        console.log(state.userInfo);

        // Optional: Handle user info or redirect after sign up
      })
      .addCase(updateUserAddressEditProfile.rejected, (state, action) => {
        state.status = 'profile update failed';
        state.signUpLoading = false;
      })
      // Update User Horoscope Edit Profile
      .addCase(updateUserHoroscopeEditProfile.pending, (state) => {
        state.signUpLoading = true;
        state.status = 'profile updating';
      })
      .addCase(updateUserHoroscopeEditProfile.fulfilled, (state, action) => {
        console.log('Payload:', action.payload);
        console.log('Before Update:', state.userInfo);
        state.status = 'profile updated';
        state.additionalDetails = action.payload;
        state.signUpLoading = false;

        console.log(state.userInfo);
        // Optional: Handle user info or redirect after sign up
      })
      .addCase(updateUserHoroscopeEditProfile.rejected, (state, action) => {
        state.status = 'profile update failed';

        state.signUpLoading = false;
      })
      // Update User Horoscope Edit Profile
      .addCase(updateUserAboutMoreProfile.pending, (state) => {
        state.signUpLoading = true;
        state.status = 'profile updating';
      })
      .addCase(updateUserAboutMoreProfile.fulfilled, (state, action) => {
        state.status = 'profile updated';
        state.additionalDetails = action.payload;

        // state.userInfo = {
        //   ...state.userInfo,
        //   additional_details: {
        //     ...state.userInfo?.additional_details,
        //     ...action.payload.additional_details, // Merge any nested additional details
        //   },
        // };
        state.signUpLoading = false;
        // Optional: Handle user info or redirect after sign up
      })
      .addCase(updateUserAboutMoreProfile.rejected, (state, action) => {
        state.status = 'profile update failed';

        state.signUpLoading = false;
      })
      // Update User Horoscope Edit Profile
      .addCase(updateUserMatchPreferenceProfile.pending, (state) => {
        state.signUpLoading = true;
        state.status = 'profile updating';
      })
      .addCase(updateUserMatchPreferenceProfile.fulfilled, (state, action) => {
        state.status = 'profile updated';
        state.additionalDetails = action.payload;

        // state.userInfo = {
        //   ...state.userInfo,
        //   additional_details: {
        //     ...state.userInfo?.additional_details,
        //     ...action.payload.additional_details, // Merge any nested additional details
        //   },
        // };
        state.signUpLoading = false;
        // Optional: Handle user info or redirect after sign up
      })
      .addCase(updateUserMatchPreferenceProfile.rejected, (state, action) => {
        state.status = 'profile update failed';
        state.signUpLoading = false;
      });
  },
});

export const { setUser, logout } = userSlice.actions;
export default userSlice.reducer;

/* eslint-disable react-hooks/exhaustive-deps */
import Button from '../../../components/UI/Button/Button';
// import Navbar from '../../../components/Navbar/Navbar';
import { useDispatch, useSelector } from 'react-redux';
import UserProfilePictureData from './UserProfilePictureData';
import UserAboutData from './UserAboutData';
import UserContactData from './UserContactData';
import UserGalleryData from './UserGalleryData';
import UserFamilyData from './UserFamilyData';
import UserPersonalData from './UserPersonalData';
import AboutFamily from './AboutFamily';
import UserEducationData from './UserEducationData';
import UserHobbiesData from './UserHobbiesData';
import { useNavigate, useSearchParams } from 'react-router-dom';
// import { getUserFullDetails } from '../../../redux/features/userSlice';
import { useEffect, useState } from 'react';
import {
  fetchHoroscopeAlbumByProfileId,
  fetchPhotoAlbumByProfileId,
  fetchProfilePictureByProfileId,
  fetchProfilePicUrlMatchDetailByProfileId,
} from '../../../redux/features/imageSlice';
import {
  getCompareUserDetails,
  getUserFullDetails,
} from '../../../redux/features/userSlice';
// import { fetchCotactDetailsByProfileId } from '../../../redux/features/profileSlice';
import MatchPreferenceData from '../UserProfile/MatchPreferenceData';
import HoroscopeGalleryData from './HoroscopeGalleryData';
import {
  fetchAnnualIncome,
  fetchCity,
  fetchCommunity,
  fetchCountry,
  fetchDietaryPreferences,
  fetchHighestQualification,
  fetchMotherTounge,
  fetchOccupation,
  fetchPhysicalStatus,
  fetchQualificationType,
  fetchReligion,
  fetchStates,
  fetchSubCommunity,
  fetchWeight,
} from '../../../redux/features/fieldsSlice';

const ViewProfile = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [profilePicture, setProfilePicture] = useState(null);
  // const [contactDetails, setContactDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [isProfileDetailsLoading, setIsProfileDetailsLoading] = useState(false);
  const [photoAlbum, setPhotoAlbum] = useState(null);
  const [horoscopeAlbum, setHoroscopePhotoAlbum] = useState(null);

  const [profilePictureError, setProfilePictureError] = useState('');

  // const [contactLoading, setContactLoading] = useState(false);

  const profile_id = searchParams.get('profile_id');
  // const { profile_id } = useParams();

  useEffect(() => {
    setIsProfileDetailsLoading(true);
    const fetchUserByProfileId = async () => {
      // const profile_id = searchParams.get('profile_id');
      // console.log(profile_id);

      if (profile_id) {
        try {
          await dispatch(getUserFullDetails(profile_id)).unwrap();
        } catch (error) {
          // console.log(error.message);
        } finally {
          setIsProfileDetailsLoading(false);
        }
      }
    };
    fetchUserByProfileId();
  }, []);

  useEffect(() => {
    // console.log(profile_id);

    if (profile_id) {
      const fetchPicture = async () => {
        try {
          const picResponse = await dispatch(
            fetchProfilePictureByProfileId(profile_id)
          );
          if (picResponse?.payload) {
            const status = picResponse?.payload.status;
            // console.log(status, status === 400);
            if (status === 'success') {
              // console.log(picResponse?.payload.status);
              // console.log(picResponse.payload.profile_picture);
              setProfilePicture(picResponse.payload.profile_picture);
            } else {
              // console.log(picResponse?.payload);
              setProfilePictureError(picResponse?.payload?.message);
            }
          }
          if (picResponse?.payload) {
            setProfilePicture(picResponse.payload.profile_picture);
          }
        } catch (error) {}

        const albumResponse = await dispatch(
          fetchPhotoAlbumByProfileId(profile_id)
        );
        const horoscopeAlbumResponse = await dispatch(
          fetchHoroscopeAlbumByProfileId(profile_id)
        );
        // console.log(horoscopeAlbumResponse);

        // console.log('contactDetails Status: ', contactDetails.status);

        //Just Below line is comment down because we need this api call in Match Now page.
        // await dispatch(fetchProfilePicUrlMatchDetailByProfileId(profile_id));

        if (albumResponse?.payload) {
          setPhotoAlbum(albumResponse?.payload?.photo_album);
        }
        if (horoscopeAlbumResponse?.payload) {
          setHoroscopePhotoAlbum(horoscopeAlbumResponse?.payload?.photo_album);
        }
      };
      fetchPicture();
    }
  }, [dispatch, profile_id]);

  //////////////////////////////////
  // Fields

  const countryData = useSelector((state) => state.field.country);
  const statesData = useSelector((state) => state.field.states);
  const cityData = useSelector((state) => state.field.city);
  const religionData = useSelector((state) => state.field.religion);
  const communityData = useSelector((state) => state.field.community);
  const subCommunityData = useSelector((state) => state.field.subCommunity);
  const weightData = useSelector((state) => state.field.weight);
  const motherToungeData = useSelector((state) => state.field.motherTounge);
  const qualificationTypeData = useSelector(
    (state) => state.field.qualificationType
  );
  const highestQualificationData = useSelector(
    (state) => state.field.highestQualification
  );
  const occupationData = useSelector((state) => state.field.occupation);
  const annualIncomeData = useSelector((state) => state.field.annualIncome);
  const physicalStatusData = useSelector((state) => state.field.physicalStatus);
  const dietaryPreferencesData = useSelector(
    (state) => state.field.dietaryPreferences
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (countryData.length === 0) {
          // console.log('countryData Not!!!!');
          await dispatch(fetchCountry());
        }
        if (statesData.length === 0) {
          // console.log('statesData Not!!!!');
          await dispatch(fetchStates());
        }
        if (cityData.length === 0) {
          // console.log('cityData Not!!!!');
          await dispatch(fetchCity());
        }

        if (religionData.length === 0) {
          // console.log('religionData Not!!!!');
          await dispatch(fetchReligion());
        }
        if (communityData.length === 0) {
          // console.log('communityData Not!!!!');
          await dispatch(fetchCommunity());
        }
        if (subCommunityData.length === 0) {
          // console.log('subCommunityData Not!!!!');
          await dispatch(fetchSubCommunity());
        }
        if (weightData.length === 0) {
          // console.log('weightData Not!!!!');
          await dispatch(fetchWeight());
        }
        if (motherToungeData.length === 0) {
          // console.log('motherToungeData Not!!!!');
          await dispatch(fetchMotherTounge());
        }
        if (qualificationTypeData.length === 0) {
          // console.log('qualificationTypeData Not!!!!');
          await dispatch(fetchQualificationType());
        }
        if (highestQualificationData.length === 0) {
          // console.log('highestQualificationData Not!!!!');
          await dispatch(fetchHighestQualification());
        }

        if (occupationData.length === 0) {
          // console.log('occupationData Not!!!!');
          await dispatch(fetchOccupation());
        }
        if (annualIncomeData.length === 0) {
          // console.log('annualIncomeData Not!!!!');
          await dispatch(fetchAnnualIncome());
        }
        if (physicalStatusData.length === 0) {
          // console.log('physicalStatusData Not!!!!');
          await dispatch(fetchPhysicalStatus());
        }
        if (dietaryPreferencesData.length === 0) {
          // console.log('dietaryPreferencesData Not!!!!');
          await dispatch(fetchDietaryPreferences());
        }
      } catch (error) {
        console.error('Error fetching data', error);
      }
    };
    fetchData();
  }, []);

  const onMatchNowBtnHandler = async (profile_id) => {
    setIsLoading(true);
    // console.log(profile_id);
    const fetchedProfile = await dispatch(getCompareUserDetails(profile_id));
    if (fetchedProfile) {
      // console.log('fetchedProfile: ', fetchedProfile?.payload);
      const status = fetchedProfile?.payload?.status;
      // console.log(status, status === 200);
      if (status === 200) {
        navigate(`/view-profile/match-now/?profile_id=${profile_id}`);
        // navigate('/view-profile/match-now');
      }
    }
    setIsLoading(false);
    // navigate('/match-now');
  };

  const userFullDetail = useSelector((state) => state.user.userFullDetail);
  // userFullDetail && console.log('userFullDetailByID: ', userFullDetail);

  // const user = useSelector((state) => state.user.userInfo);
  // const { name, email } = user || {} ;

  // const { email } = user || {};
  const {
    occupation_detail,
    height_ft,
    height_inch,
    dob,
    city,
    first_name,
    last_name,
    about_myself,

    gender,

    // mobile,
    // address,

    father_name,
    mother_name,
    father_status_occupation,
    mother_status_occupation,
    number_of_siblings,
    family_type,
    family_city,
    family_values,
    family_country,
    family_state,
    family_pincode,
    siblings_brother,
    siblings_sister,
    siblings_brother_married,
    siblings_sister_married,

    sub_community,
    weight,
    weight_measure,
    blood_group,
    marital_status,
    mother_tongue,
    religion,
    community,
    gothra,
    physically_status,
    complexion,
    time_of_birth,
    place_of_birth,
    manglic_status,
    raashi,

    about_my_family,

    qualification_type,
    highest_qualification,
    institute_name,
    current_income,
    occupation_type,

    hobbies,

    match_age_from,
    match_age_to,
    match_annual_income,
    match_country,
    match_dietary_preferences,
    match_having_children,
    match_height_from_ft,
    match_height_from_inch,
    match_height_to_ft,
    match_height_to_inch,
    match_highest_qualification,
    match_manglic_status,
    match_marital_status,
    match_occupation,
    match_qualification_type,
    match_state,
  } = userFullDetail || {};

  return (
    <>
      {/* <Navbar onShowSignUp={props.onShowSignUp} /> */}
      <section className='flex justify-center items-center w-full bg-[#FFFFF4] pb-32 pt-6 md:pt-6 lg:pt-10 mb-6 px-2 md:px-6 lg:px-10'>
        {/* <section className='flex justify-center items-center w-full bg-[#FFFFF4] pb-32 pt-6 md:pt-6 lg:pt-10 mb-6 px-2 md:px-6 lg:px-10 mt-20'> */}
        <div className='flex flex-col justify-center items-center w-full p-4 border-2 border-[#FFC0CB] rounded-lg'>
          <div className='flex flex-col justify-center items-center w-full mb-10 bg-white'>
            <div className='flex flex-col justify-center items-start w-full'>
              <button
                onClick={() => {
                  navigate(-1);
                }}
                className='py-2 px-5 mb-2 border text-black hover:bg-[#FFC0CB] font-semibold rounded-md btn btn-secondary'
              >
                Back
              </button>
            </div>
            <p className='font-bold text-[#FF4061] text-[38px] lg:text-[52px] my-2 text-center'>
              Profile
            </p>
            <p className='font-semibold text-[#818181] text-[16px] md:text-[18px] lg:text-[24px] my-2 text-center'>
              Most Trusted by Katiya Community Peoples for Matrimony Services
            </p>
          </div>

          {/* loading Box\\\\\\\\\\\\\\\\\\ */}
          {isProfileDetailsLoading ? (
            <div className='fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-[50000]'>
              <div className='bg-white p-6 rounded-lg shadow-lg flex flex-col items-center space-y-4'>
                <div className='flex items-center space-x-2'>
                  <svg
                    className='animate-spin h-8 w-8 text-pink-500'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                  >
                    <circle
                      className='opacity-25'
                      cx='12'
                      cy='12'
                      r='10'
                      stroke='currentColor'
                      strokeWidth='4'
                    ></circle>
                    <path
                      className='opacity-75'
                      fill='currentColor'
                      d='M4 12a8 8 0 018-8v8H4z'
                    ></path>
                  </svg>
                  <span className='text-lg text-gray-700 font-semibold'>
                    Loading...
                  </span>
                </div>
                {/* <div>
                  <span className='text-lg text-gray-700 font-semibold'>
                    Please Wait...
                  </span>
                </div> */}
                {/* <p className="text-gray-500">
      Please wait while we complete your signup.
    </p> */}
              </div>
            </div>
          ) : (
            <div className='flex flex-col justify-center items-center w-full bg-white'>
              <div className='flex flex-col lg:flex-row justify-between items-start w-[98%]'>
                <UserProfilePictureData
                  profile_id={profile_id}
                  profilePicture={profilePicture}
                  profilePictureError={profilePictureError}
                />
                <UserAboutData
                  occupation_detail={occupation_detail}
                  height_ft={height_ft}
                  height_inch={height_inch}
                  dob={dob}
                  city={city}
                  gender={gender}
                  first_name={first_name}
                  last_name={last_name}
                  about_myself={about_myself}
                  place_of_birth={place_of_birth}
                  qualification_type={qualification_type}
                  hobbies={hobbies}
                  profile_id={profile_id}
                />
              </div>
              <div className='flex flex-col md:flex-row justify-between items-start w-[98%]'>
                <UserContactData
                  // email={null}
                  // mobile={mobile}
                  // address={address}
                  profile_id={profile_id}
                  // contactError={contactDetails?.error}
                  // contactDetails={contactDetails?.data}
                  // contactLoading={contactLoading}
                />

                <div className='flex flex-col justify-start items-start w-[49%]'>
                  <UserGalleryData photoAlbumData={photoAlbum} />
                  <HoroscopeGalleryData horoscopeAlbumData={horoscopeAlbum} />
                </div>
                {/* <HoroscopeImage /> */}
              </div>
              <div className='flex flex-col justify-center items-center w-[98%] border-b-'>
                <div className='flex flex-col md:flex-row justify-between items-start w-full'>
                  <UserFamilyData
                    father_name={father_name}
                    mother_name={mother_name}
                    father_status_occupation={father_status_occupation}
                    mother_status_occupation={mother_status_occupation}
                    number_of_siblings={number_of_siblings}
                    family_type={family_type}
                    family_city={family_city}
                    family_values={family_values}
                    family_country={family_country}
                    family_state={family_state}
                    family_pincode={family_pincode}
                    siblings_brother={siblings_brother}
                    siblings_sister={siblings_sister}
                    siblings_brother_married={siblings_brother_married}
                    siblings_sister_married={siblings_sister_married}
                  />
                  <UserPersonalData
                    first_name={first_name}
                    last_name={last_name}
                    dob={dob}
                    sub_community={sub_community}
                    height_ft={height_ft}
                    height_inch={height_inch}
                    weight={weight}
                    weight_measure={weight_measure}
                    blood_group={blood_group}
                    marital_status={marital_status}
                    mother_tongue={mother_tongue}
                    religion={religion}
                    community={community}
                    gothra={gothra}
                    physically_status={physically_status}
                    complexion={complexion}
                    time_of_birth={time_of_birth}
                    place_of_birth={place_of_birth}
                    manglic_status={manglic_status}
                    raashi={raashi}
                  />
                </div>
                <div className='flex justify-center items-center w-full pb-10'>
                  <AboutFamily aboutText={about_my_family || ''} />
                </div>
              </div>
              {/* <div className='flex flex-col justify-center items-center w-[98%] border-b-2'>
              <div className='flex flex-col md:flex-row justify-between items-start w-full'>
                <UserFamilyData />
                <UserPersonalData />
              </div>
              <div className='flex justify-center items-center w-full pb-10'>
                <AboutFamily aboutText={aboutText} />
              </div>
            </div> */}

              <div className='flex flex-col lg:flex-row justify-between items-start w-[98%]'>
                <UserEducationData
                  highest_qualification={highest_qualification}
                  institute_name={institute_name}
                  current_income={current_income}
                  occupation_type={occupation_type}
                  occupation_detail={occupation_detail}
                />
                <UserHobbiesData hobbies={hobbies} />
              </div>
              <div className='flex flex-col lg:flex-row justify-between items-start w-[98%]'>
                <MatchPreferenceData
                  match_age_from={match_age_from}
                  match_age_to={match_age_to}
                  match_annual_income={match_annual_income}
                  match_country={match_country}
                  match_dietary_preferences={match_dietary_preferences}
                  match_having_children={match_having_children}
                  match_height_from_ft={match_height_from_ft}
                  match_height_from_inch={match_height_from_inch}
                  match_height_to_ft={match_height_to_ft}
                  match_height_to_inch={match_height_to_inch}
                  match_highest_qualification={match_highest_qualification}
                  match_manglic_status={match_manglic_status}
                  match_marital_status={match_marital_status}
                  match_occupation={match_occupation}
                  match_qualification_type={match_qualification_type}
                  match_state={match_state}
                />
              </div>
              <div className='flex justify-center items-center w-[98%]'>
                <Button
                  text='Match Now'
                  className='w-[80%] md:w-[50%] !h-[55px] font-bold'
                  bgBtn='bg-[#333333]'
                  onButtonClick={() => {
                    onMatchNowBtnHandler(profile_id);
                  }}
                >
                  {isLoading && (
                    <div className='flex justify-center items-center bg-white rounded-full ml-2'>
                      <svg
                        className='animate-spin h-6 w-6 text-pink-500'
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                      >
                        <circle
                          className='opacity-25'
                          cx='12'
                          cy='12'
                          r='10'
                          stroke='currentColor'
                          strokeWidth='4'
                        ></circle>
                        <path
                          className='opacity-75'
                          fill='currentColor'
                          d='M4 12a8 8 0 018-8v8H4z'
                        ></path>
                      </svg>
                    </div>
                  )}
                </Button>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default ViewProfile;

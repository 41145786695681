import ProfileCard from '../../components/UI/ProfileCard/ProfileCard';
import { FaBoxOpen } from 'react-icons/fa';
import AllMatchesPaginate from './AllMatchesPaginate';

const AllProfiles = ({
  allMatchingProfileData,
  totalPages,
  currentPage,
  setCurrentPage,
  recordsPerPage,
  setRecordsPerPage,
  className,
}) => {
  // console.log('allMatchingProfileData: ', allMatchingProfileData);
  // console.log('length: ', allMatchingProfileData?.length);
  return (
    // <div className='flex flex-col md:flex-row flex-wrap justify-center md:justify-between items-center w-[90%]'>
    <div className='flex flex-col md:flex-row flex-wrap justify-center md:justify-start gap-8 items-start w-[90%]'>
      {allMatchingProfileData && allMatchingProfileData?.length > 0 ? (
        <>
          {allMatchingProfileData?.map((data, idx) => (
            <ProfileCard key={idx} profileData={data} className={className} />
          ))}
          <div className='flex justify-center items-center w-full'>
            {/* <AllMatchesPaginate
              allMatchingProfileData={allMatchingProfileData}
              /> */}
            <AllMatchesPaginate
              allMatchingProfileData={allMatchingProfileData}
              totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              recordsPerPage={recordsPerPage}
              setRecordsPerPage={setRecordsPerPage}
            />
          </div>
        </>
      ) : (
        <div className='flex flex-col justify-center items-center w-full py-10'>
          <FaBoxOpen className='text-gray-500 opacity-50' size={48} />
          <p className='text-gray-500 text-xl font-semibold'>
            No profile available!
          </p>
        </div>
      )}
    </div>
  );
};
export default AllProfiles;

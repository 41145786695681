import { useSelector } from 'react-redux';

const RefundPolicy = () => {
  let companyInfoArray = useSelector((state) => state.companyMaster.info);
  const companyInfo = companyInfoArray?.length > 0 ? companyInfoArray[0] : null;
  const { email } = companyInfo || {};

  window.scrollTo({ top: 0, behavior: 'smooth' });
  return (
    <section className='bg-gray-50 w-full mb-20'>
      <div className='py-12 bg-[#FF8C9C]'>
        <h1 className='text-white font-bold text-2xl sm:text-3xl lg:text-4xl px-20'>
          Refund Policy
        </h1>
      </div>
      {/* Content Section */}
      <div className='max-w-6xl mx-auto py-10 px-5 sm:px-10 lg:px-20'>
        <div className='bg-white p-6 sm:p-10 rounded-lg shadow-md'>
          {/* Introduction Section */}
          <h2 className='text-xl sm:text-2xl font-bold text-gray-800 mb-4'>
            Refund Policy:
          </h2>
          <p className='text-gray-700 text-base sm:text-lg leading-6 mb-6'>
            We aim to provide an excellent service to all our users. However,
            please note the following terms regarding refunds.
          </p>

          <h3 className='text-lg sm:text-xl font-semibold text-gray-800 mt-8 mb-4'>
            Refund Terms:
          </h3>
          <ul className='list-disc pl-5 text-gray-700 text-base sm:text-lg leading-7 space-y-4 mt-4'>
            <li>
              <p>
                <strong>Non-Refundable:</strong> All payments made for services
                on
                <span className='font-semibold text-[#FF8C9C] mx-1'>
                  KatiyaSamaj.com
                </span>
                are non-refundable under any circumstances.
              </p>
            </li>
            <li>
              <p>
                <strong>Exceptions:</strong> If you believe there is an error or
                issue with a payment, please contact us within 7 days for a
                review. We may review such requests on a case-by-case basis but
                will not guarantee a refund.
              </p>
            </li>
            <li className='text-xl bg-[#FF8C9C22] p-2 rounded'>
              <p className=''>
                <strong>Payment:</strong> We are not taking any payment for
                matrimonial services and it is complete free for Year 2024-2025.
              </p>
            </li>
          </ul>
          <h3 className='text-lg sm:text-xl font-semibold text-gray-800 mt-8 mb-4'>
            Contact Us:
          </h3>

          <p className='text-gray-700 text-base sm:text-lg leading-6 mb-6'>
            For any refund inquiries, please reach out to us via the contact
            form or email at
            <a
              href={`mailto:${email}`}
              className='text-[#FF4061] hover:underline mx-1'
              target='_blank'
              rel='noopener noreferrer'
            >
              {email}
            </a>
          </p>
        </div>
      </div>
    </section>
  );
};

export default RefundPolicy;

// import { useEffect, useState } from 'react';
import { FaPhoneAlt, FaEnvelope, FaHome } from 'react-icons/fa';
// import { IoWarningOutline } from 'react-icons/io5';
// import { RiErrorWarningLine } from 'react-icons/ri';

const UserContactData = ({
  email,
  mobile,
  // address,
  pincode,
}) => {
  // const [error, setError] = useState('');

  // useEffect(() => {
  //   if (contactDetails?.error) {
  //     setError(contactDetails?.error);
  //   }
  // }, [contactDetails]);

  // console.log(contactDetails);
  return (
    <div className='flex flex-col justify-center items-center w-full md:w-[49%] p-6 bg-white shado-lg rounded-lg'>
      <div className='flex flex-col justify-center items-center md:items-start py-10 w-full'>
        <p className='text-[#333333] font-bold mb-4 text-[20px]'>
          Contact Information
        </p>

        <ul className='flex flex-col justify-center items-start mt-2 w-full space-y-4'>
          <li className='flex items-start w-full'>
            <div className='w-[10%]'>
              <FaPhoneAlt className='text-[#333333] w-[24px] h-[24px] mr-4' />
            </div>
            <div className='w-[90%]'>
              <span className='font-bold text-[#818181] text-[16px] md:text-[18px] lg:text-[20px]'>
                Phone:
              </span>
              <span className='text-[#818181] ml-2 font-normal text-[16px] md:text-[18px] lg:text-[20px]'>
                {mobile || ''}
              </span>
            </div>
          </li>
          <li className='flex items-start w-full'>
            <div className='w-[10%]'>
              <FaEnvelope className='text-[#333333] w-[24px] h-[24px] mr-4' />
            </div>
            <div className='w-[90%]'>
              <span className='font-bold text-[#818181] text-[16px] md:text-[18px] lg:text-[20px]'>
                Email:
              </span>
              <span className='text-[#818181] ml-2 font-normal text-[16px] md:text-[18px] lg:text-[20px]'>
                {email || ''}
              </span>
            </div>
          </li>
          <li className='flex items-start w-full'>
            <div className='w-[10%]'>
              <FaHome
                size={24}
                className='text-[#333333] w-[25px] h-[25px] mr-4'
              />
            </div>
            <div className='w-[90%]'>
              <span className='font-bold text-[#818181] text-[16px] md:text-[18px] lg:text-[20px]'>
                Area Pincode:
              </span>
              <span className='text-[#818181] ml-2 font-normal text-[16px] md:text-[18px] lg:text-[20px] text-wrap'>
                {pincode || ''}
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default UserContactData;

// const UserContactData = () => {
//   return (
//     <div className="flex flex-col justify-center items-center w-full md:w-[49%]">
//       <div className="flex flex-col justify-center items-center md:items-start py-10 w-full">
//         <p className="text-[#333333] font-bold mb-4 text-[20px]">
//           Contact Information
//         </p>
//         <ul className="flex flex-col justify-center items-start mt-2 w-full ">
//           <li className="flex justify-start items-center w-full my-2">
//             <span className="w-[40px] h-[40px] md:w-[45px] md:h-[45px] lg:w-[50px] lg:h-[50px] rounded-md border-2 mr-2"></span>
//             <span className="font-bold text-[#818181] mx-2 text-[16px] md:text-[18px] lg:text-[20px]">
//               Phone:
//             </span>
//             <span className="text-[#818181] ml-2 font-normal text-[16px] md:text-[18px] lg:text-[20px]">
//               0000000000
//             </span>
//           </li>
//           <li className="flex justify-start items-center w-full my-2">
//             <span className="w-[40px] h-[40px] md:w-[45px] md:h-[45px] lg:w-[50px] lg:h-[50px] rounded-md border-2 mr-2"></span>
//             <span className="font-bold text-[#818181] mx-2 text-[16px] md:text-[18px] lg:text-[20px]">
//               Email:
//             </span>
//             <span className="text-[#818181] ml-2 font-normal text-[16px] md:text-[18px] lg:text-[20px]">
//               davis@gmail.com
//             </span>
//           </li>
//           <li className="flex justify-start items-center w-full my-2">
//             <span className="w-[40px] h-[40px] md:w-[45px] md:h-[45px] lg:w-[50px] lg:h-[50px] rounded-md border-2 mr-2"></span>
//             <span className="font-bold text-[#818181] mx-2 text-[16px] md:text-[18px] lg:text-[20px]">
//               Address:
//             </span>
//             <span className="text-[#818181] ml-2 font-normal text-[16px] md:text-[18px] lg:text-[20px]">
//               G-20, Arera colony
//             </span>
//           </li>
//         </ul>
//       </div>
//     </div>
//   );
// };
// export default UserContactData;

/* eslint-disable react-hooks/exhaustive-deps */
// import { useSelector } from 'react-redux';
// import AllMatchesPaginate from '../../../AllMatches/AllMatchesPaginate';
import AllProfiles from '../../../AllMatches/AllProfiles';
import SortMatchesHeader from '../../../AllMatches/SortMatchesHeader';
import AsideSortingBox from './AsideSortingBox';
import { useEffect, useState } from 'react';
import { FiFilter } from 'react-icons/fi';
import classes from './FindProfile.module.css';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// const FindProfileMatches = ({ findMatchProfilesData }) => {
const FindProfileMatches = () => {
  const navigate = useNavigate();

  const findMatchProfilesData = useSelector(
    (state) => state.profile.findMatchProfilesData
  );

  const [selectedHeight, setSelectedHeight] = useState('');
  const [selectedAge, setSelectedAge] = useState('');
  const [selectedReligion, setSelectedReligion] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedOccupationType, setSelectedOccupationType] = useState('');
  const [selectedHasProfilePhoto, setSelectedHasProfilePhoto] =
    useState('select');

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);

  // Function to filter profiles
  const filterProfiles = () => {
    if (
      !selectedHeight &&
      !selectedAge &&
      !selectedReligion &&
      !selectedLocation &&
      !selectedState &&
      !selectedOccupationType &&
      !selectedHasProfilePhoto
    ) {
      return findMatchProfilesData;
    }

    // Otherwise, apply filtering logic
    return findMatchProfilesData.filter((profile) => {
      const matchesHeight = selectedHeight
        ? profile.height === selectedHeight
        : true;

      // console.log(
      //   parseInt(selectedAge),
      //   profile.age,
      //   typeof parseInt(selectedAge),
      //   typeof profile.age
      // );

      const matchesAge = selectedAge
        ? profile.age === parseInt(selectedAge)
        : true;
      // console.log(matchesAge, profile.age >= parseInt(selectedAge));

      const matchesReligion = selectedReligion
        ? profile.religion === selectedReligion
        : true;
      const matchesLocation = selectedLocation
        ? profile.location.city === parseInt(selectedLocation, 10)
        : true;

      const matchesState = selectedState
        ? profile.location.state === selectedState
        : true;

      const matchesOccupationType = selectedOccupationType
        ? profile.occupation.occupation_type === selectedOccupationType
        : true;

      const profileHasPhoto = profile.profile_photo ? 'yes' : 'null';
      // console.log('profileHasPhoto: ', profileHasPhoto);

      const matchesProfilePhoto =
        selectedHasProfilePhoto === 'select'
          ? true
          : selectedHasProfilePhoto === profileHasPhoto;
      // console.log('selectedHasProfilePhoto: ', selectedHasProfilePhoto);
      // console.log('profileHasPhoto: ', profileHasPhoto);

      // console.log('matchesHeight: ', matchesHeight);
      // console.log('matchesAge: ', matchesAge);
      // console.log('matchesReligion: ', matchesReligion);
      // console.log('matchesLocation: ', matchesLocation);
      // console.log('matchesOccupationType: ', matchesOccupationType);
      // console.log('matchesProfilePhoto: ', matchesProfilePhoto);

      return (
        matchesHeight &&
        matchesAge &&
        matchesReligion &&
        matchesLocation &&
        matchesState &&
        matchesOccupationType &&
        matchesProfilePhoto
      );
    });
  };

  // Paginate filtered profiles
  const filteredProfiles = filterProfiles();
  // console.log(filteredProfiles);
  // console.log('filteredProfiles: ', filteredProfiles);

  const totalRecords = filteredProfiles.length;
  const totalPages = Math.ceil(totalRecords / recordsPerPage);

  const validCurrentPage = Math.min(currentPage, totalPages);
  const startIndex = (validCurrentPage - 1) * recordsPerPage;
  const endIndex = Math.min(startIndex + recordsPerPage, totalRecords);

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(1); // Reset to the first page
    }
  }, [totalPages]);
  // const startIndex = (currentPage - 1) * recordsPerPage;
  // const endIndex = startIndex + recordsPerPage;

  const paginatedProfiles = filteredProfiles.slice(startIndex, endIndex);

  return (
    <div className='flex flex-col justify-between items-start w-full p-10 mb-20'>
      <div className='flex flex-col justify-center items-start w-full'>
        <button
          onClick={() => {
            navigate(-1);
          }}
          className='py-2 px-5 mb-2 border text-black hover:bg-[#FFC0CB] font-semibold rounded-md btn btn-secondary'
        >
          Back
        </button>
      </div>
      <div className='flex flex-col lg:flex-row justify-between items-start w-full'>
        <label
          htmlFor='filtermenu'
          className='lg:hidden flex justify-start items-center w-full bg-[#FFC0CB] py-2 rounded-lg px-2 mb-4 group'
        >
          <FiFilter
            size={30}
            className='text-[#FF4061] bg-[#FFFFFF] p-1 rounded-md group-active:text-[#FFFFFF] group-active:bg-[#FF4061]'
          />
          <span className='text-[#FFFFFF] font-bold ml-2 text-[18px] group-active:text-[#FF4061]'>
            Filter
          </span>
        </label>
        <input
          id='filtermenu'
          className={`${classes['filtermenu']} hidden`}
          type='checkbox'
        />
        <div className='hidden lg:flex justify-center items-start w-full lg:w-[25%] border-2 border-[#FFC0CB] rounded-lg px-3 lg:px-4 bg-white mb-4 lg:mb-0'>
          <AsideSortingBox
            setSelectedHeight={setSelectedHeight}
            setSelectedAge={setSelectedAge}
            setSelectedReligion={setSelectedReligion}
            setSelectedLocation={setSelectedLocation}
            setSelectedState={setSelectedState}
            setSelectedOccupationType={setSelectedOccupationType}
            setSelectedHasProfilePhoto={setSelectedHasProfilePhoto}
            selectedHeight={selectedHeight}
            selectedAge={selectedAge}
            selectedReligion={selectedReligion}
            selectedLocation={selectedLocation}
            selectedState={selectedState}
            selectedOccupationType={selectedOccupationType}
            selectedHasProfilePhoto={selectedHasProfilePhoto}
          />
        </div>
        <div className='flex flex-col justify-center items-start w-full lg:w-[74%]'>
          <SortMatchesHeader
            totalProfiles={paginatedProfiles?.length}
            className=' w-full px-4 py-2 mb-3'
          />
          <div className='flex flex-col justify-center items-center w-full border-2 border-[#FFC0CB] rounded-lg py-4 bg-white mt-2'>
            <AllProfiles
              allMatchingProfileData={paginatedProfiles}
              // allMatchingProfileData={filterProfiles()}
              totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              recordsPerPage={recordsPerPage}
              setRecordsPerPage={setRecordsPerPage}
              className='w-full md:w-[45%] lg:w-[30%]'
            />
            {/* <AllMatchesPaginate /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindProfileMatches;

import ResetPasswordForm from './ResetPasswordForm';

const ResetPassword = (props) => {
  return (
    <div className='flex flex-col justify-center items-center relative py-3 md:p-4 mb-20 w-full lg:min-w-full'>
      <div className='flex flex-col justify-center items-center w-full py-10'>
        <div className='flex flex-col justify-center items-center w-full'>
          <h2 className='font-extrabold text-[26px] md:text-[32px] text-[#333333] text-center'>
            Reset Password
          </h2>
        </div>
        <ResetPasswordForm onShowSuccesMsgText={props.onShowSuccessMessage} />
      </div>
    </div>
  );
};

export default ResetPassword;

import { useState } from 'react';
import DashboardLayout from '../../DashboardLayout';
import FindProfileMatches from './FindProfileMatches';
import SearchProfile from './SearchProfile';
import { useSelector } from 'react-redux';

const FindProfile = (props) => {
  const [showFindProfileMatches, setShowFindProfileMatches] = useState(false);

  const findMatchProfilesData = useSelector(
    (state) => state.profile.findMatchProfilesData
  );

  const handleSearchSuccess = () => {
    setShowFindProfileMatches(true);
  };

  const handleBackClick = () => {
    setShowFindProfileMatches(false);
  };

  // console.log('findMatchProfilesData: ', findMatchProfilesData);
  // console.log('findMatchProfilesData: ', findMatchProfilesData.length);

  return (
    <DashboardLayout activePage='findprofile' onShowSignUp={props.onShowSignUp}>
      <div className='flex flex-col justify-center items-start w-full'>
        {showFindProfileMatches && (
          <div className='flex flex-col justify-center items-start w-full'>
            <button
              onClick={handleBackClick}
              className='py-2 px-5 mb-2 border text-black hover:bg-[#FFC0CB] font-semibold rounded-md btn btn-secondary'
            >
              Back
            </button>
          </div>
        )}

        {/* <SearchProfile onSearch={handleSearchSuccess} /> */}

        {showFindProfileMatches ? (
          <FindProfileMatches
            findMatchProfilesData={findMatchProfilesData}
            onBack={handleBackClick}
          />
        ) : (
          <SearchProfile onSearch={handleSearchSuccess} />
        )}
      </div>
    </DashboardLayout>
  );
};
export default FindProfile;

/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from 'react-router-dom';
import Button from '../../../../components/UI/Button/Button';
import DashboardLayout from '../../DashboardLayout';
import MatchingSuggestions from './MatchingSuggestions';
import { ImNotification } from 'react-icons/im';
import { GiClick } from 'react-icons/gi';
import { useDispatch, useSelector } from 'react-redux';
import { FaWhatsapp } from 'react-icons/fa';
import {
  fetchAnnualIncome,
  fetchCity,
  fetchCommunity,
  fetchCountry,
  fetchDietaryPreferences,
  fetchHighestQualification,
  fetchMotherTounge,
  fetchOccupation,
  fetchPhysicalStatus,
  fetchQualificationType,
  fetchReligion,
  fetchStates,
  fetchSubCommunity,
  fetchWeight,
} from '../../../../redux/features/fieldsSlice';
import { useEffect } from 'react';

const DashboardPage = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.userInfo);
  let companyInfoArray = useSelector((state) => state.companyMaster.info);
  const companyInfo = companyInfoArray?.length > 0 ? companyInfoArray[0] : null;
  // console.log('companyInfo: ', companyInfo);

  const { email, contact_number_1, whatsapp_number } = companyInfo || {};

  // religion: [],
  // community: [],
  // subCommunity: [],
  // weight: [],
  // motherTounge: [],
  // qualificationType: [],
  // highestQualification: [],
  // occupation: [],
  // annualIncome: [],
  // physicalStatus: [],
  // dietaryPreferences: [],

  const countryData = useSelector((state) => state.field.country);
  const statesData = useSelector((state) => state.field.states);
  const cityData = useSelector((state) => state.field.city);
  const religionData = useSelector((state) => state.field.religion);
  const communityData = useSelector((state) => state.field.community);
  const subCommunityData = useSelector((state) => state.field.subCommunity);
  const weightData = useSelector((state) => state.field.weight);
  const motherToungeData = useSelector((state) => state.field.motherTounge);
  const qualificationTypeData = useSelector(
    (state) => state.field.qualificationType
  );
  const highestQualificationData = useSelector(
    (state) => state.field.highestQualification
  );
  const occupationData = useSelector((state) => state.field.occupation);
  const annualIncomeData = useSelector((state) => state.field.annualIncome);
  const physicalStatusData = useSelector((state) => state.field.physicalStatus);
  const dietaryPreferencesData = useSelector(
    (state) => state.field.dietaryPreferences
  );

  const { profile_id } = user || {};
  // console.log('profile_id: ', profile_id);

  const { gender, first_name, last_name } = user?.additional_details || {};
  const firstName = first_name
    ? first_name?.charAt(0).toUpperCase() + first_name?.slice(1)
    : '';
  const lastName = last_name
    ? last_name?.charAt(0).toUpperCase() + last_name?.slice(1)
    : '';
  // console.log(firstName + ' ' + lastName);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (countryData.length === 0) {
          // console.log('countryData Not!!!!');
          await dispatch(fetchCountry());
        }
        if (statesData.length === 0) {
          // console.log('statesData Not!!!!');
          await dispatch(fetchStates());
        }
        if (cityData.length === 0) {
          // console.log('cityData Not!!!!');
          await dispatch(fetchCity());
        }

        if (religionData.length === 0) {
          // console.log('religionData Not!!!!');
          await dispatch(fetchReligion());
        }
        if (communityData.length === 0) {
          // console.log('communityData Not!!!!');
          await dispatch(fetchCommunity());
        }
        if (subCommunityData.length === 0) {
          // console.log('subCommunityData Not!!!!');
          await dispatch(fetchSubCommunity());
        }
        if (weightData.length === 0) {
          // console.log('weightData Not!!!!');
          await dispatch(fetchWeight());
        }
        if (motherToungeData.length === 0) {
          // console.log('motherToungeData Not!!!!');
          await dispatch(fetchMotherTounge());
        }
        if (qualificationTypeData.length === 0) {
          // console.log('qualificationTypeData Not!!!!');
          await dispatch(fetchQualificationType());
        }
        if (highestQualificationData.length === 0) {
          // console.log('highestQualificationData Not!!!!');
          await dispatch(fetchHighestQualification());
        }

        if (occupationData.length === 0) {
          // console.log('occupationData Not!!!!');
          await dispatch(fetchOccupation());
        }
        if (annualIncomeData.length === 0) {
          // console.log('annualIncomeData Not!!!!');
          await dispatch(fetchAnnualIncome());
        }
        if (physicalStatusData.length === 0) {
          // console.log('physicalStatusData Not!!!!');
          await dispatch(fetchPhysicalStatus());
        }
        if (dietaryPreferencesData.length === 0) {
          // console.log('dietaryPreferencesData Not!!!!');
          await dispatch(fetchDietaryPreferences());
        }
      } catch (error) {
        console.error('Error fetching data', error);
      }
    };
    fetchData();
  }, []);

  // await dispatch(fetchCity());
  // await dispatch(fetchReligion());
  // await dispatch(fetchCommunity());
  // await dispatch(fetchSubCommunity());
  // await dispatch(fetchWeight());
  // await dispatch(fetchMotherTounge());
  // await dispatch(fetchQualificationType());
  // await dispatch(fetchHighestQualification());
  // await dispatch(fetchOccupation());
  // await dispatch(fetchAnnualIncome());
  // await dispatch(fetchPhysicalStatus());
  // await dispatch(fetchDietaryPreferences());

  window.scrollTo({ top: 0, behavior: 'smooth' });
  return (
    <DashboardLayout
      onShowSignUp={props.onShowSignUp}
      onShowSuccessMessage={props.onShowSuccessMessage}
      activePage='dashboard'
    >
      <div className='flex flex-col justify-start items-center w-full'>
        <div className='flex flex-col gap-4 md:gap-0 md:flex-row justify-between items-center w-full md:w-full self-start px-4 py-2'>
          <div className='flex justify-between items-center w-full md:w-[45%]'>
            <div className='flex justify-start items-center w-[50%]'>
              <Link to='/findprofile'>
                <Button
                  text={`Search ${
                    gender
                      ? gender === 'Male'
                        ? 'Bride '
                        : 'Groom'
                      : 'matches'
                  }`}
                  className='font-medium lg:font-semibold text-[14px] lg:text-[16px] w-full md:w-auto'
                />
              </Link>
            </div>
            <div className='flex justify-end items-center w-[50%]'>
              <a href='#matchingsuggestions'>
                <Button
                  text='New Matches'
                  className='font-medium lg:font-semibold text-[14px] lg:text-[16px] w-full md:w-auto'
                />
              </a>
            </div>
          </div>
          <div className='flex justify-center items-center w-full md:w-[45%]'>
            <div className='bg-[#FE617F] flex flex-col md:flex-row justify-between items-center w-full rounded-lg h-[80px] md:h-[45px]'>
              <div className='flex justify-start items-center text-[#FFFFFF] font-medium text-[14px] lg:text-[16px] px-3 w-full md:w-[70%] lg:w-[60%] h-full'>
                <ImNotification className='mr-2 text-[#FF4061] bg-[#FFFFFF] rounded-full text-[16px] h-[20px] w-[20px]' />
                <p className=''>Your profile is incomplete.</p>
              </div>
              <div className='relative flex justify-center items-center w-full md:w-[30%] lg:w-[40%] h-full'>
                <Link to='/edit-profile' className='w-full h-full'>
                  <Button
                    text='Edit Profile'
                    className='font-medium lg:font-semibold text-[14px] lg:text-[16px] rounded-l-none w-full h-full !m-0'
                    bgBtn='bg-[#FFFFFF]'
                    colorBtn='text-[#FF4061] hover:text-[#FFFFFF] shadow-[2px_0_8px_rgba(0,0,0,0.2)]'
                  >
                    <GiClick className='mr-2 text-[#FF4061] bg-[#FFFFFF] rounded-full text-[18px] h-[20px] w-[20px]' />
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className='flex justify-center items-center w-full px-4'>
          <ul className='flex flex-wrap justify-between items-center w-full'>
            <li className='flex flex-col justify-center items-center min-h-[150px] md:min-h-[180px] lg:min-h-[180px] w-full md:w-[45%] lg:w-[45%] bg-[#FFFFF4] p-4 rounded-lg shadow-[0_0_12px_rgba(0,0,0,0.1)] my-4'>
              <h3 className='text-[#FF4061] font-bold text-[20px] my-2'>
                Customer Support
              </h3>
              <p className='text-gray-600 text-sm mb-2 text-center sm:text-left'>
                <strong>Timing:</strong> 12PM to 7PM (Monday to Saturday)
              </p>
              <p className='text-gray-600 text-sm mb-2 text-center sm:text-left'>
                <strong>Whatsapp Number:</strong>
                {/* https://api.whatsapp.com/send/?phone=%2B919907546266&text=I%27m+Anup+Rakse+KWS15731+%22&type=phone_number&app_absent=0 */}
                <a
                  href={`https://wa.me/${whatsapp_number}?text=I'm%20${
                    firstName + ' ' + lastName
                  }%20${profile_id}%20`}
                  // href={`https://wa.me/${whatsapp_number}?text=I'm%20here%20from%20your%20website%20scrmp.in%20`}
                  className='text-[#FFFFFF] flex bg-[#4CAF50] rounded-lg px-2 py-1 shadow-md hover:underline ml-1'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <FaWhatsapp size={20} />
                  {whatsapp_number}
                </a>
              </p>
              <p className='text-gray-600 text-sm mb-2 text-center sm:text-left'>
                <strong>Contact:</strong>
                <a
                  href={`tel:${contact_number_1}`}
                  className='text-[#FF4061] hover:underline ml-1'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {contact_number_1}
                </a>
              </p>
              <p className='text-gray-600 text-sm mb-4 text-center sm:text-left'>
                <strong>Email:</strong>
                <a
                  href={`mailto:${email}`}
                  className='text-[#FF4061] hover:underline ml-1'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {email}
                </a>
              </p>
            </li>

            <li className='flex flex-col justify-center items-center min-h-[150px] md:min-h-[180px] lg:min-h-[180px] w-full md:w-[45%] bg-[#FFFFF4] p-4 rounded-lg shadow-[0_0_12px_rgba(0,0,0,0.1)] my-4'>
              <span className='text-[#FF4061] font-bold text-[20px] my-2'>
                0
              </span>
              <p className='text-[#333333] text-[18px] font-extrabold my-2'>
                Matches
              </p>
            </li>
            <li className='flex flex-col justify-center items-center min-h-[150px] md:min-h-[180px] lg:min-h-[180px] w-full md:w-[45%] bg-[#FFFFF4] p-4 rounded-lg shadow-[0_0_12px_rgba(0,0,0,0.1)] my-4'>
              <Link to='/dashboard/latest-request'>
                <span className='text-[#FF4061] my-2'>
                  <Button text='Check' className='!h-[35px]' />
                </span>
              </Link>
              <p className='text-[#333333] text-[18px] font-extrabold my-2'>
                Letest Request
              </p>
            </li>
            <li className='flex flex-col justify-center items-center min-h-[150px] md:min-h-[180px] lg:min-h-[180px] w-full md:w-[45%] bg-[#FFFFF4] p-4 rounded-lg shadow-[0_0_12px_rgba(0,0,0,0.1)] my-4'>
              <span className='text-[#FF4061] font-bold text-[20px] my-2'>
                0
              </span>
              <p className='text-[#333333] text-[18px] font-extrabold my-2'>
                Click
              </p>
            </li>
          </ul>
        </div>
      </div>
      <MatchingSuggestions />
    </DashboardLayout>
  );
};
export default DashboardPage;
